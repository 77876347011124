import Axios from 'axios';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import CustomButton from './../custom/customButton';
import CustomModal from './../custom/customModal';
import { Checkbox, PageTitle, Select, TextInput, Button } from './../lib';
import AsyncSelect from 'react-select/async';
import { UilQuestionCircle, UilPlus, UilTrashAlt } from '@iconscout/react-unicons';
import { Tooltip } from 'react-tooltip';
import './addtask.scss';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export function AddTask({ onClose, onComplete, onUpdate, setData, id, setId }) {
  const [Name, setName] = useState('');
  const [type, setType] = useState('');
  const [Description, setDescription] = useState('');
  const [AllowTemplates, setAllowTemplates] = useState(false);
  const [TaskAttributes, setTaskAttributes] = useState('');
  const [TemplateAttributes, setTemplateAttributes] = useState([]);
  const [lists, setLists] = useState([]);
  const [selectedList, setSelectedList] = useState('');
  const [macros, setMacros] = useState([]);

  const addNewTask = async () => {
    if (Name.trim() === '')
      return Toast.fire({
        icon: 'error',
        title: 'Task type name cannot be empty.',
      });
    if (Description.trim() === '')
      return Toast.fire({
        icon: 'error',
        title: 'Task type description cannot be empty.',
      });
    if (TaskAttributes === '')
      return Toast.fire({
        icon: 'error',
        title: 'Task type attribute needed.',
      });
    if (AllowTemplates && TemplateAttributes.length === 0)
      return Toast.fire({
        icon: 'error',
        title: 'Task template attribute needed.',
      });
    if (AllowTemplates && TemplateAttributes.length > 0) {
      for (let i = 0; i < TemplateAttributes.length; i++) {
        if (TemplateAttributes[i].attribute.trim() === '')
          return Toast.fire({
            icon: 'error',
            title: 'Task template attribute cannot be empty.',
          });
        if (
          TemplateAttributes[i].type === 'checkboxes' &&
          TemplateAttributes[i].options.length === 0
        )
          return Toast.fire({
            icon: 'error',
            title: 'Task template checkbox options needed.',
          });
        if (
          TemplateAttributes[i].type === 'checkboxes' &&
          TemplateAttributes[i].options.length > 0
        ) {
          let tempOptions = TemplateAttributes[i].options;
          for (let j = 0; j < tempOptions.length; j++) {
            if (tempOptions[j].trim() === '')
              return Toast.fire({
                icon: 'error',
                title: `Task template ${TemplateAttributes[i].type} checkbox option cannot be empty.`,
              });
          }
        }
      }
    }

    try {
      let link = '/api/task-type';
      let method = 'post';
      if (id) {
        link = `/api/task-type/${id._id}`;
        method = 'put';
      }
      const res = await Axios({
        url: link,
        method: method,
        data: {
          name: Name,
          description: Description,
          allow_templates: AllowTemplates,
          task_attributes: TaskAttributes,
          template_attribute: TemplateAttributes,
        },
      });
      Toast.fire({
        icon: 'success',
        title: `Task type ${id ? 'updated' : 'added'} successfully.`,
      });
      setId(res.data.data);
      setData((oldData) => [...oldData, res.data.data]);
      if (id) onUpdate();
      else onComplete();
    } catch (err) {
      if (err.response.data?.message)
        return Toast.fire({
          icon: 'error',
          title: `${err.response.data?.message}`,
        });
      Toast.fire({ icon: 'error', title: `Internal server error.` });
    }
  };

  const addPredefinedTask = async () => {
    try {
      let res = await Axios({
        url: `/api/task-type/${type}`,
        method: "post",
        data: {},
      });
      Toast.fire({
        icon: 'success',
        title: `Task type added successfully.`,
      });
      setData((oldData) => [...oldData, res.data.data]);
      onUpdate()
    } catch (err) {
      if (err.response.data?.message)
        return Toast.fire({
          icon: 'error',
          title: `${err.response.data?.message}`,
        });
      Toast.fire({ icon: 'error', title: `Internal server error.` });
    }
  }

  const addTemplateAttributes = () => {
    let newAttribute = {
      id: `i-${Math.floor(Math.random() * 1000)}`,
      type: 'input',
      attribute: '',
      options: [],
    };
    setTemplateAttributes((oldAttributes) => [...oldAttributes, newAttribute]);
  };

  const deleteTemplateAttributes = (id) =>
    setTemplateAttributes((oldAttributes) =>
      oldAttributes.filter((attribute) => attribute.id !== id)
    );

  const updateTemplateAttributes = (id, key, value) => {
    setTemplateAttributes((oldAttributes) => {
      let newAttributes = oldAttributes.map((attribute) => {
        if (attribute.id === id) {
          attribute[key] = value;
        }
        return attribute;
      });
      return newAttributes;
    });
  };

  useEffect(() => {
    fetchLists('');
  }, []);

  useEffect(() => {
    if (id) {
      setName(id.name);
      setDescription(id.description);
      setAllowTemplates(id.allow_templates);
      setTaskAttributes(id.task_attributes);
      setTemplateAttributes(id.template_attribute);
    }
  }, [id]);

  useEffect(() => {
    if (selectedList === '') return;
    setMacros(generateMacrosForList(selectedList));
  }, [selectedList, lists]);

  const fetchLists = async (inputValue) => {
    try {
      let result = await Axios.get(
        `/api/list?limit=10&search=${inputValue}&integration=true`
      );
      const data = result?.data?.data;
      setLists(data);
      return data;
    } catch (err) {
      console.log('Error in Fetching templates ', err);
      return [];
    }
  };

  const generateMacrosForList = (listID) => {
    let _selectedList = lists.find((list) => list.id === listID);
    // console.log("Generating ", _selectedList)
    let fields = _selectedList?.views[0]?.columns;
    if (!fields) return [];
    let _macros = [];
    for (let field of fields) {
      _macros.push(field);
    }
    return _macros;
  };

  return (
    <CustomModal close={true} onClose={() => onClose()} width={500}>
      <div style={{ padding: 10 }} id="addEditTask">
        <h1
          className="text-2xl text-modal-header"
          style={{ marginTop: '-15px', padding: 0 }}
        >
          {id ? `Edit ${id.name}` : 'Add New Task Type'}
        </h1>
        <p className="text-modal-description" style={{ padding: 0 }}>
          Can be added in Outreach Sequences as a Manual Task
        </p>
        <hr className="mt-3 mb-3" />
        {!id && (
          <Select
            options={[
              { value: 'manually', label: 'Add Manually' },
              { value: 'linkedin_connection', label: 'LinkedIn Connection' },
              { value: 'linkedin_message', label: 'LinkedIn Message' },
              { value: 'imessage', label: 'iMessage' },
              { value: 'sms', label: 'SMS' },
              { value: 'whatsapp_message', label: 'WhatsApp Message' },
              { value: 'telegram_message', label: 'Telegram Message' },
              { value: 'phone_call', label: 'Phone Call' },
            ]}
            value={type}
            onChange={(n, value) => {
              if (value === 'unselected') setType('');
              else setType(value);
            }}
          />
        )}
        {(id || type === 'manually') && (
          <div>
            <div style={{ margin: 6 }}>
              <Label
                title={'Task Type Name'}
                tooltip={
                  'You will select this task name while creating sequence.'
                }
              />
              <p>
                <TextInput
                  value={Name}
                  placeholder="Enter a name for your task type"
                  onChange={(n, value) => setName(value)}
                />
              </p>
            </div>
            <div style={{ margin: 6 }}>
              <Label
                title={'Task Description'}
                tooltip={
                  'This is a description of the task for internal use only.'
                }
              />
              <p>
                <TextInput
                  type="textarea"
                  placeholder="Enter a short description of task, for internal use only."
                  value={Description}
                  onChange={(n, value) => setDescription(value)}
                />
              </p>
            </div>
            <div style={{ margin: 6 }}>
              <div className="flex">
                <Label
                  title={'Required Variable'}
                  tooltip={
                    'You can choose a variable & tasks will only be scheduled if the variable has a value for a particular lead.'
                  }
                />

                {TaskAttributes !== '' && (
                  <button
                    type="button"
                    style={{ marginRight: 5 }}
                    className="bg-gray-300 mt-1 mb-2 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-full inline-flex items-center"
                    disabled={true}
                  >
                    {TaskAttributes.toLowerCase()}
                  </button>
                )}
              </div>
              <p>
                <div
                  style={{
                    display: 'block',
                    border: '1px solid rgb(215, 215, 215)',
                    borderRadius: 5,
                    backgroundColor: 'rgb(249, 249, 249)',
                    marginBottom: '0.5rem',
                  }}
                >
                  <AsyncSelect
                    styles={{
                      control: () => ({
                        padding: 1,
                        borderRadius: 5,
                        display: 'flex',
                        border: '1px solid rgb(241 245 249)',
                      }),
                    }}
                    placeholder="Search for a list"
                    value={(function () {
                      const value = lists.find(
                        (prop) => prop?.id === selectedList
                      );
                      if (value) return value;
                      return null;
                    })()}
                    menuPortalTarget={document.getElementById('addEditTask')}
                    defaultOptions={lists}
                    isClearable
                    loadOptions={fetchLists} // Function to fetch list options asynchronously
                    getOptionValue={(option) => option?.id} // Define how to get the value of each option
                    getOptionLabel={(option) => option?.name} // Define how to get the label of each option
                    onChange={(values, currentValue) => {
                      if (values) setSelectedList(values.id);
                    }}
                    noOptionsMessage={() => 'No Results, Type to Search'}
                  />
                </div>
                {macros?.map((macro, i) => (
                  <button
                    key={i}
                    type="button"
                    onClick={() => setTaskAttributes(macro)}
                    style={{ marginRight: 5 }}
                    className="bg-gray-300 mt-2 mb-2 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded-full inline-flex items-center"
                  >
                    {macro.toLowerCase()}
                  </button>
                ))}
              </p>
            </div>
            <div style={{ margin: 6, marginTop: 15 }}>
              <Checkbox
                name="tasks"
                option="Create Templates for this Task Type"
                onChange={(i, checked, option) => {
                  setAllowTemplates(!AllowTemplates);
                  setTemplateAttributes([]);
                }}
                checked={AllowTemplates}
                tooltip="Check this box if you wish to create reusable templates for this task type."
              />
            </div>
            {AllowTemplates && (
              <div style={{ margin: 6 }}>
                <Label
                  title={'Template Fields'}
                  tooltip={
                    'Add fields in your task template, these will be filled while you are creating template.'
                  }
                />
                <p>
                  {TemplateAttributes?.map((attribute) => (
                    <TemplateAttribute
                      key={attribute.id}
                      onDelete={deleteTemplateAttributes}
                      onChange={updateTemplateAttributes}
                      item={attribute}
                    />
                  ))}
                  <div
                    onClick={addTemplateAttributes}
                    className="csDynamicAttributeAddButton"
                  >
                    <UilPlus size={24} style={{ marginRight: 10 }} />
                    Add Template Field
                  </div>
                </p>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            marginTop: '1rem',
            gap: '2rem',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Button
            variant="primary grey"
            action={() => onClose()}
            text="Cancel"
          />
          <Button
            variant="primary"
            action={() => {
              if (!id && type === '')
                return Toast.fire({
                  icon: 'error',
                  title: 'Select a task type.',
                });
              if (id || type === 'manually') addNewTask();
              else addPredefinedTask();
            }}
            text={`${id ? 'Save' : 'Add'} Task Type`}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export function AddTaskTemplate({ onComplete, onClose, data, id, templateData }) {
  const [attributes, setAttributes] = useState(null);
  const [templateName, setTemplateName] = useState('');

  const addNewTaskTemplate = async () => {
    if (templateName.trim() === '')
      return Toast.fire({ icon: 'error', title: `Template name required.` });
    for (let j = 0; j < attributes.length; j++) {
      if (
        typeof attributes[j].answer === 'string' &&
        attributes[j].answer.trim() === ''
      )
        return Toast.fire({
          icon: 'error',
          title: `Template attribute field cannot be empty.`,
        });
      else if (
        typeof attributes[j].answer === 'object' &&
        attributes[j].answer.length === 0
      )
        return Toast.fire({
          icon: 'error',
          title: `Template checkboxes attribute cannot be empty.`,
        });
    }
    try {
      const res = await Axios({
        url: templateData?`/api/template/${templateData?._id}`:'/api/template',
        method: templateData?'put':'post',
        data: {
          task_type_id: data._id,
          type: 'task',
          structure: attributes,
          name: templateName,
        },
      });
      Toast.fire({ icon: 'success', title: `Template added successfully.` });
      if (onComplete) onComplete();
    } catch (err) {
      if (err.response.data?.message)
        return Toast.fire({
          icon: 'error',
          title: `${err.response.data?.message}`,
        });
      Toast.fire({ icon: 'error', title: `Internal server error.` });
    }
  };

  const updateTemplate = (id, key, value) => {
    setAttributes((oldAttributes) => {
      let newAttributes = oldAttributes.map((attribute) => {
        if (attribute.id === id) {
          if (attribute.type === 'checkboxes') {
            if (attribute[key]?.find((opt) => opt === value))
              attribute[key] = attribute[key].filter((opt) => opt !== value);
            else attribute[key]?.push(value);
          } else attribute[key] = value;
        }
        return attribute;
      });
      return newAttributes;
    });
  };

  useEffect(() => {
    if (data?.allow_templates) {
      let attributesArr = [];
      for (let i = 0; i < data.template_attribute.length; i++) {
        if (data.template_attribute[i].type === 'checkboxes')
          data.template_attribute[i]['answer'] = [];
        else data.template_attribute[i]['answer'] = '';
        attributesArr.push(data.template_attribute[i]);
      }
      setAttributes(attributesArr);
    }
  }, [data]);

  useEffect(() => {
    if(templateData) {
      // console.log('DATA ', templateData)
      setTemplateName(templateData.name);
      setAttributes(templateData.structure)
    }
  }, [templateData])

  return (
    <div style={{ padding: 10 }}>
      <div className="text-center">
        <PageTitle
          title={
            !templateData ? `Add New Task Template for ${data?.name}` : `Edit - ${templateData.name}`
          }
          description="Task Templates are added in sequences to create a Task at the right point of time."
          headerStyle={{ justifyContent: 'center' }}
        />
      </div>

      <div
        style={{
          maxHeight: '70vh',
          overflowY: 'auto',
          borderRadius: 5,
          border: '1px solid #D7D7D7',
          boxShadow: '-2px 2px 4px rgba(0, 0, 0, 0.05)',
          padding: 20,
          margin: 40,
        }}
      >
        {!data && (
          <div style={{ margin: 6 }}>
            <h2 className="text-xl">Select Task Type</h2>
            <p>
              <Select
                options={[{ value: '1', label: 'Task Type 1' }]}
                default="2"
              />
            </p>
          </div>
        )}
        <div className="ml-2">
          <TextInput
            required={true}
            label="Template Name"
            name="name"
            value={templateName}
            onChange={(name, value) => setTemplateName(value)}
            placeholder="Enter Template Name (for internal purposes)"
          />
        </div>
        {attributes?.map((attribute) => {
          if (attribute.type === 'input') {
            return (
              <div style={{ margin: 6, marginTop: 15 }}>
                <p>
                  <TextInput
                    label={attribute.attribute}
                    placeholder={`Enter ${attribute.attribute}`}
                    value={attribute.answer}
                    onChange={(n, value) =>
                      updateTemplate(attribute.id, 'answer', value)
                    }
                  />
                </p>
              </div>
            );
          } else if (attribute.type === 'textarea') {
            return (
              <div style={{ margin: 6, marginTop: 15 }}>
                <p>
                  <TextInput
                    type="textarea"
                    label={attribute.attribute}
                    placeholder={`Enter ${attribute.attribute}`}
                    value={attribute.answer}
                    onChange={(n, value) =>
                      updateTemplate(attribute.id, 'answer', value)
                    }
                  />
                </p>
              </div>
            );
          } else if (attribute.type === 'checkboxes') {
            return (
              <div style={{ margin: 6, marginTop: 15 }}>
                <h2 className="text-xl">{attribute.attribute}</h2>
                <h2 className="text-xl">
                  {attribute.options.map((option) => (
                    <Checkbox
                      name="tasks"
                      onChange={(i, checked, opt) =>
                        updateTemplate(attribute.id, 'answer', option)
                      }
                      checked={attribute?.answer?.find((opt) => opt === option)}
                      option={option}
                    />
                  ))}
                </h2>
              </div>
            );
          }
        })}

        <div style={{ margin: 6, marginTop: 15, display: 'flex',justifyContent:'end' }}>
          {onClose && (
            <button
              className="csGenerateButton"
              style={{
                borderColor: '#E53A40',
                color: '#E53A40',
                padding: '10px',
              }}
              onClick={() => onClose()}
            >
              Discard
            </button>
          )}
          <button
            className="csGenerateButton"
            style={{
              width: 220,
              backgroundColor: '#06F',
              color: '#f9f9f9',
              marginLeft: 10,
              padding: '10px',
            }}
            onClick={() => addNewTaskTemplate()}
          >
            {templateData?"Save":"Add"} Task Template
          </button>
        </div>
      </div>
    </div>
  );
}

export function AddCondition({
  onClose,
  onComplete,
  onUpdate,
  taskID,
  id,
  setData,
}) {
  const [Name, setName] = useState('');
  const [Description, setDescription] = useState('');
  const [CheckImmediately, setCheckImmediately] = useState(false);

  const addNewCondition = async () => {
    if (Name.trim() === '')
      return Toast.fire({
        icon: 'error',
        title: 'Task condition name cannot be empty.',
      });
    if (Description.trim() === '')
      return Toast.fire({
        icon: 'error',
        title: 'Task condition description cannot be empty.',
      });

    try {
      let link = '/api/task-condition/' + taskID._id;
      let method = 'post';
      if (id) {
        link = `/api/task-condition/${id._id}`;
        method = 'put';
      }
      const res = await Axios({
        url: link,
        method: method,
        data: {
          name: Name,
          description: Description,
          check_immediately: CheckImmediately,
        },
      });
      Toast.fire({
        icon: 'success',
        title: `Task condition ${id ? 'updated' : 'added'} successfully.`,
      });
      if (id) onUpdate();
      else onComplete();
      if (!id && setData) setData((oldData) => [...oldData, res.data.data]);
    } catch (err) {
      if (err.response.data?.message)
        return Toast.fire({
          icon: 'error',
          title: `${err.response.data?.message}`,
        });
      Toast.fire({ icon: 'error', title: `Internal server error.` });
    }
  };

  useEffect(() => {
    if (id) {
      setName(id.name);
      setDescription(id.description);
      setCheckImmediately(id.check_immediately);
    }
  }, [id]);

  return (
    <CustomModal close={true} onClose={() => onClose()}>
      <div style={{ padding: 10 }}>
        <h1
          className="text-2xl text-modal-header"
          style={{ marginTop: '-15px', padding: 0 }}
        >
          {id ? `Edit ${id.name}` : 'Add New Condition'}
        </h1>
        <p className="text-modal-description" style={{ padding: 0 }}>
          Conditions can be used in If/Else blocks in sequences to create
          triggers.
        </p>
        <hr className="mt-3 mb-3" />
        <div style={{ maxHeight: 340, overflowY: 'auto' }}>
          {!taskID && (
            <div style={{ margin: 6 }}>
              <h2 className="text-xl">Condition Related To Task</h2>
              <p>
                <Select
                  options={[{ value: '1', label: 'Task 1' }]}
                  default="2"
                />
              </p>
            </div>
          )}
          <div style={{ margin: 6 }}>
            <Label
              title="Condition Name"
              tooltip="You will identify conditions by this name in Sequences."
            />
            <p>
              <TextInput
                value={Name}
                placeholder="Enter a name for your condition."
                onChange={(n, value) => setName(value)}
              />
            </p>
          </div>
          <div style={{ margin: 6 }}>
            <Label
              title="Condition Description"
              tooltip="Enter a description of your condition, for internal purposes only."
            />
            <p>
              <TextInput
                type="textarea"
                placeholder="Condition Description"
                value={Description}
                onChange={(n, value) => setDescription(value)}
              />
            </p>
          </div>
          <div style={{ margin: 6 }}>
            <Checkbox
              name="tasks"
              onChange={(i, checked, opt) =>
                setCheckImmediately(!CheckImmediately)
              }
              checked={CheckImmediately}
              option={'Check condition as soon as task is completed'}
            />
          </div>
          <div style={{ margin: 6 }}>
            <h2 className="text-xl">
              <CustomButton
                style={{ marginLeft: 'auto' }}
                title={`${id ? 'Save' : 'Add'} Condition`}
                onClick={() => addNewCondition()}
                variant="primary"
              />
            </h2>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

function TaskAttribute({ onDelete, onChange, item }) {
  return (
    <div className="csRow">
      <div className="csCustomFieldItemCol mr-2">
        <TextInput
          default={item.attribute}
          placeholder="Enter Task Attribute"
          value={item.attribute}
          onChange={(e, value) => onChange(item.id, 'attribute', value)}
          className="mt-2"
        />
      </div>
      <div
        onClick={() => onDelete(item.id)}
        className="csCustomFieldButton csCustomFieldItemRemove"
      >
        <UilTrashAlt style={{ margin: 'auto' }} size={20} />
      </div>
    </div>
  );
}

function TemplateAttribute({ onDelete, onChange, item }) {
  const [options, setOptions] = useState(['']);

  const deleteItem = (value) => {
    setOptions((oldOptions) => {
      let newOptions = oldOptions.filter((option) => option !== value);

      onChange(item.id, 'options', newOptions);
      return newOptions;
    });
  };

  const updateItem = (value, index) => {
    setOptions((oldOptions) => {
      let newOptions = oldOptions.map((option, i) => {
        if (i === index) option = value;
        return option;
      });
      onChange(item.id, 'options', newOptions);
      return newOptions;
    });
  };

  useEffect(() => {
    if (item.options.length > 0) {
      setOptions(item.options);
    }
  }, [item]);

  return (
    <>
      <div className="csRow">
        <div className="csCustomFieldItemCol mr-2">
          <TextInput
            default={item.attribute}
            placeholder="Name of template field"
            value={item.attribute}
            onChange={(e, value) => onChange(item.id, 'attribute', value)}
            className="mt-2"
          />
        </div>
        <div className="csCustomFieldItemCol">
          <Select
            options={[
              { label: 'Short Text', value: 'input' },
              { label: 'Long Text', value: 'textarea' },
              { label: 'Checkboxes', value: 'checkboxes' },
            ]}
            default={item.type}
            onChange={(e, value) => onChange(item.id, 'type', value)}
          />
        </div>
        <div
          onClick={() => onDelete(item.id)}
          className="csCustomFieldButton csCustomFieldItemRemove"
        >
          <UilTrashAlt style={{ margin: 'auto' }} size={20} />
        </div>
      </div>
      <div className="csRow">
        <div className="csCustomFieldItemCol">
          {(item.type === 'checkboxes' ||
            item.type === 'radio_button' ||
            item.type === 'select_menu') && (
            <>
              {options.map((option, index) => (
                <CustomFieldOption
                  onDelete={deleteItem}
                  index={index}
                  value={option}
                  onChange={updateItem}
                />
              ))}
              <div style={{ marginLeft: 40 }}>
                <div
                  onClick={() =>
                    setOptions((oldOptions) => [...oldOptions, ''])
                  }
                  className="csDynamicAttributeAddButton"
                  style={{ width: '100%' }}
                >
                  <UilPlus size={24} style={{ marginRight: 10 }} />
                  Add another checkbox
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function CustomFieldOption({ value, index, onDelete, onChange }) {
  return (
    <div className="csRow">
      <div
        className="csCustomFieldButton"
        style={{ marginLeft: 40, marginBottom: 20, marginRight: 10,marginTop:0 }}
      >
        <input type="checkbox" defaultChecked={true} disabled={true} />
      </div>
      <div className="csCustomFieldItemCol">
        <TextInput
          value={value}
          onChange={(e, value) => onChange(value, index)}
        />
      </div>
      <div
        onClick={() => onDelete(value)}
        className="csCustomFieldButton csCustomFieldItemRemove"
        style={{ marginBottom: 20 }}
      >
        <UilTrashAlt style={{ margin: 'auto' }} size={20} />
      </div>
    </div>
  );
}


function Label({title,tooltip,toolTipPosition = "right"}){
  const id = title.trim().toLowerCase().replaceAll(" ","-");
  return (
    <div>
    <h2 className="text-xl flex items-center">
      {title}
      {tooltip && <UilQuestionCircle size={22} style={{cursor:"pointer", marginLeft: 5}} id={id} />}
    </h2>
      {tooltip && <Tooltip anchorSelect={`#${id}`} place={toolTipPosition } className="z-10">
                {tooltip}
            </Tooltip>}
    </div>
  )
}
