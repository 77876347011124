import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEnvelope,
  UilFile,
  UilMessage,
  UilTrash,
  UilListUl
} from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import CustomDialog from 'components/custom/customDialog';
import {
  CustomSearch,
  CustomSubNav,
  CustomTable,
  PageTitle,
} from 'components/lib';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Upload from 'views/leads/import/upload';
import { DateTime } from 'luxon';
import './templates.scss';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default function ManageUnsubscribed() {
  const [viewCreate, setViewCreate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [unsubscribes, setUnsubscribes] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [unsubscribeCount, setUnsubscribeCount] = useState(0);
  const [totalPages,setTotalPages] = useState(0);
  let navigator = useNavigate();

  const columns = [
    {
      Header: 'Domain / Email',
      Footer: 'Domain / Email',
      accessor: 'email',
      Cell: ({ row, value }) => {
        return value;
      },
    },
    {
      Header: <span className='csCenter'>Type</span>,
      accessor: 'type',
      Cell: ({ value }) => {
        return <div className='text-center'>
        <span style={{ textTransform: 'uppercase' }} className='csBadge info'>
          {value}
        </span>            
      </div>
      }, // formatting date
    },
    {
      Header: <span className="csCenter">Unsubscribe Date</span>,
      accessor: 'date_created',
      Cell: ({ value }) => {
        const date = DateTime.fromISO(value)

        const day = date.day;
        const suffix =
          day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';
        return (
          <div className="csCenter">
            {date.toFormat(`d`) +
              suffix +
              date.toFormat(` MMM yyyy`)}
          </div>
        );
      },
    },
    {
      Header: <span className='csCenter'>Actions</span>,
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        const { original } = row;
        return <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span className="csBadge dangerOutline" style={{ padding: 4 }}>
            <UilTrash
              size={16}
              color="#EF898C"
              style={{ cursor: 'pointer' }}
              onClick={() => deleteUnsubscribed(original._id)}
            />
          </span>
        </div>;
      },
    },
  ];

  useEffect(()=>{
    if(unsubscribeCount > 0) setTotalPages(Math.ceil(unsubscribeCount/perPage));
  },[unsubscribeCount])

  useEffect(() => {
    fetchUnsubscribed();
  }, [perPage, page, search]);

  async function fetchUnsubscribed() {
    try {
      let url = '/api/unsubscribes';
      let result = await Axios.get(url, {
        headers: {
          limit: perPage,
          skip: page,
          search: search,
        },
      });
      setUnsubscribes(result?.data?.data);
      setUnsubscribeCount(result.data.count);
    } catch (err) {
      console.log('Error in getting unsubscribed ', err);
    }
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) n = n / 1024;

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  async function uploadUnsubscribedList() {
    try {
      var formData = new FormData();
      formData.append('csvFile', selectedFile);

      let result = await Axios({
        method: 'POST',
        url: '/api/unsubscribe',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        icon: 'Success',
        title: 'Emails & Domains added successfully.',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
      fetchUnsubscribed();
      setViewCreate(false);
      setSelectedFile(null)
    } catch (err) {
      console.log('Error in adding unsubscribe list ', err);
      Swal.fire({
        icon: 'error',
        title: 'Error in adding unsubscribe list',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
      setViewCreate(false);
    }
  }

  async function deleteUnsubscribed(id) {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h5>This item will be removed from unsubscribed lists.</h5>',
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: '#E53A40',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Axios.delete(`/api/unsubscribe/${id}`);
          Toast.fire({
            icon: 'success',
            title: 'Item removed from unsubscribed list.',
          });
          fetchUnsubscribed();
        } catch (err) {
          console.log('Error in updating sequence! ', err);
          Toast.fire({ icon: 'error', title: 'Unable to remove item.' });
        }
      }
    });
  }

  return (
    <>
      {viewCreate && (
        <CustomDialog scroll={true}>
          {selectedFile ? (
            <div style={{ textAlign: 'center' }}>
              <h2 style={{ fontSize: 20 }}>{selectedFile?.name}</h2>
              <p>{niceBytes(selectedFile?.size)}</p>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  onClick={() => {
                    setSelectedFile(null);
                  }}
                  className="text-pink-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                >
                  Remove
                </button>
              </div>
            </div>
          ) : (
            <Upload
              onSelected={(acceptedFiles) => {
                if (
                  acceptedFiles[0].type !== 'text/csv' &&
                  acceptedFiles[0].type !== 'application/vnd.ms-excel'
                )
                  return Swal.fire({
                    icon: 'error',
                    title: 'Please select a .csv file!',
                    html: '',
                    showCancelButton: false,
                    confirmButtonText: `Ok`,
                    confirmButtonColor: '#0066ff',
                  });
                setSelectedFile(acceptedFiles[0]);
              }}
              fullWidth={true}
              type="unsubscribed"
            />
          )}

          <div
            style={{
              display: 'flex',
              margin: '0 16px',
              marginTop: 11,
              justifyContent: 'end',
              width: '100%',
            }}
          >
            <CustomButton
              style={{ marginLeft: 'auto', marginRight: 5 }}
              title="Cancel"
              variant="secondary red"
              onClick={() => {
                setViewCreate(false);
                setSelectedFile(null);
              }}
            />

            <CustomButton
              style={{ marginLeft: 15, marginRight: 25 }}
              title="Save"
              variant="primary"
              onClick={() => uploadUnsubscribedList()}
            />
          </div>
        </CustomDialog>
      )}

      <CustomSubNav
        selected={'settings'}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />

      <div style={{ height: 'auto', marginTop: -20 }} className="templatesContaier">
        <Row style={{ display: 'flex' }}>
          <Col md={12}>
            <div
              style={{
                marginBottom: 10,
                display: 'flex'
              }}
            >
              <div style={{ marginTop: 10 }}>
                <PageTitle
                  title={"Manage Unsubscribed/Blocked"}
                  description={"Unsubscribed & blocked leads who will not receive any cold emails from this account."}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                  gap: '1rem',
                }}
              >
                <CustomButton
                  onClick={() => setViewCreate(true)}
                  title="Upload"
                  variant="secondary"
                />
                <CustomSearch
                  style={{ height: 40, marginTop: 10 }}
                  value={search}
                  onChange={(e) => setSearch(e)}
                  placeholder={'Search'}
                />
              </div>
            </div>
            {unsubscribeCount > 0 ? <CustomTable
              hasFooter={false}
              filter={search}
              columns={columns}
              data={unsubscribes}
              count={unsubscribeCount}
              style={{ width: '100%' }}
              perPage={perPage}
              onClick={(row) => {
                console.log('Clicked ', row);
              }}
              setPerPage={setPerPage}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            /> : <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 'auto',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="150" height="150" style={{ margin: 'auto' }} viewBox="0 0 100 100">
                  <circle cx="52" cy="52" r="44" opacity=".35"></circle><circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle><ellipse cx="50.016" cy="50.026" fill="#d9eeff" rx="38.017" ry="38.026"></ellipse><path fill="#70bfff" d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"></path><path fill="#0080FF" d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"></path>
                </svg>
                <div style={{ textAlign: 'center' }}>
                  No unsubscribers
                </div>
                <div style={{ margin: 'auto' }}>
                  <CustomButton
                    title="Upload Manually"
                    variant="primary"
                    onClick={() => setViewCreate(true)}
                  />
                </div>
              </div>
            </div>}
          </Col>
        </Row>
      </div>
    </>
  );
}
