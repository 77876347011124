const tracking_app_urls = [
	'a.clickkk.xyz',
    'b.clickkk.xyz',
    'c.clickkk.xyz',
    'd.clickkk.xyz',
    'e.clickkk.xyz',
    'f.clickkk.xyz',
    'g.clickkk.xyz',
    'h.clickkk.xyz',
    'i.clickkk.xyz',
    'j.clickkk.xyz',
    'k.clickkk.xyz',
    'l.clickkk.xyz',
    'm.clickkk.xyz',
    'n.clickkk.xyz',
    'o.clickkk.xyz',
    'p.clickkk.xyz',
    'q.clickkk.xyz',
    'r.clickkk.xyz',
    's.clickkk.xyz',
    't.clickkk.xyz',
    'u.clickkk.xyz',
    'v.clickkk.xyz',
    'w.clickkk.xyz',
    'x.clickkk.xyz',
    'y.clickkk.xyz',
    'z.clickkk.xyz',
    '1.clickkk.xyz',
    '2.clickkk.xyz',
    '3.clickkk.xyz',
    '4.clickkk.xyz',
    '5.clickkk.xyz',
    '6.clickkk.xyz',
    '7.clickkk.xyz',
    '8.clickkk.xyz',
    '9.clickkk.xyz',
    '10.clickkk.xyz',
    '11.clickkk.xyz',
    '12.clickkk.xyz',
    '13.clickkk.xyz',
    '14.clickkk.xyz',
    '15.clickkk.xyz',
    '16.clickkk.xyz',
    '17.clickkk.xyz',
    '18.clickkk.xyz',
    '19.clickkk.xyz',
    '20.clickkk.xyz',
    '21.clickkk.xyz',
    '22.clickkk.xyz',
    '23.clickkk.xyz',
    '24.clickkk.xyz',
    '25.clickkk.xyz',
    '26.clickkk.xyz',
    '27.clickkk.xyz',
    '28.clickkk.xyz',
    '29.clickkk.xyz',
    '30.clickkk.xyz',
    '31.clickkk.xyz',
    '32.clickkk.xyz',
    '33.clickkk.xyz',
    '34.clickkk.xyz',
    '35.clickkk.xyz',
    '36.clickkk.xyz',
    '37.clickkk.xyz',
    '38.clickkk.xyz',
    '39.clickkk.xyz',
    '40.clickkk.xyz',
    '41.clickkk.xyz',
    '42.clickkk.xyz',
    '43.clickkk.xyz',
    '44.clickkk.xyz',
    '45.clickkk.xyz',
    '46.clickkk.xyz',
    '47.clickkk.xyz',
    '48.clickkk.xyz',
    '49.clickkk.xyz',
    '50.clickkk.xyz'
];

export default tracking_app_urls;