/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {
  ViewContext,
  Button,
  TitleRow,
  useAPI,
  usePermissions,
  Event,
  useNavigate,
  useRouteCheck,
  UpgradePlan,
  Loader,
  CustomTable,
  PageTitle,
  Input,
} from 'components/lib';
import Swal from 'sweetalert2';
import {
  UilEnvelope,
  UilEdit,
  UilTrash,
  UilSearch,
} from '@iconscout/react-unicons';
import CustomButton from 'components/custom/customButton';
import { Tooltip } from 'react-tooltip';

export function Users(props) {
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const data = useAPI('/api/account/users');
  const [users, setUsers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [planUsage, setPlanUsage] = useState(null);
  const [search, setSearch] = useState('');
  // table pagination options
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );

  useEffect(() => {
    getActivePlans();
  }, [users]);

  async function getActivePlans() {
    try {
      let res = await axios.get(`/api/account/plan`);
      setPlanUsage(res.data.data);
    } catch (err) {}
  }

  function invite() {
    context.modal.show(
      {
        title: 'Add User',
        form: {
          email: {
            label: 'Email',
            type: 'text',
            required: true,
          },
          permission: {
            label: 'Permission',
            type: 'select',
            default: 'user',
            options: permissions?.data?.list?.filter(
              (x) => x.value !== 'owner'
            ),
          },
        },
        buttonText: 'Send Invite',
        text: 'To invite more than one user, seperate the emails with a comma',
        url: '/api/invite',
        method: 'POST',
      },
      (form, res) => {
        // add the invited user to the
        if (res.length) {
          const state = [...users];

          res.forEach((invite) => {
            if (!state.find((x) => x.id === invite.id)) {
              state.push({
                id: invite.id,
                name: '',
                email: invite.email,
                date_created: invite.date_sent,
                permission: invite.permission || 'user',
                status: 'Invited',
              });
            }
          });

          Event('invited_user');
          setUsers(state);
        }
      }
    );
  }

  function editUser(data) {
    context.modal.show(
      {
        title: 'Update User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          name: {
            label: 'Name',
            type: 'text',
            required: true,
            value: data.name,
            errorMessage: 'Please enter a name',
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
          },
          permission: {
            label: 'Permission',
            type: data.permission === 'owner' ? null : 'select',
            options: permissions?.data?.list?.filter(
              (x) => x.value !== 'owner'
            ),
            default: data.permission,
          },
        },
        buttonText: 'Save',
        url: '/api/user',
        method: 'PATCH',
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true);
        const [id, email, name, permission] = [
          'id',
          'email',
          'name',
          'permission',
        ].map((field) => res[field].value);
        setUsers((previousUsers) =>
          previousUsers.map((user) =>
            user.id === id
              ? { ...user, ...{ id, email, name, permission } }
              : user
          )
        );
      }
    );
  }

  function deleteUser(data) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete User',
        text: `Are you sure you want to delete ${data.name}?`,
        url: '/api/user',
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(`${data.name} was deleted`, 'success', true);
        setUsers((previousUsers) =>
          previousUsers.filter((user) => user.id !== data.id)
        );
      }
    );
  }

  function deleteInvite(data) {
    context.modal.show(
      {
        title: 'Delete Invite',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete Invite',
        text: `Are you sure you want to delete the invite for ${data.email}?`,
        url: '/api/invite',
        method: 'DELETE',
        destructive: true,
      },
      () => {
        context.notification.show(
          `${data.email}'s invite was deleted`,
          'success',
          true
        );
        setUsers((previousUsers) =>
          previousUsers.filter((user) => user.id !== data.id)
        );
      }
    );
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: '/api/invite',
        method: 'post',
        data: { email: data.email },
      });

      context.notification.show(
        `Invite re-sent to ${data.email}`,
        'success',
        true
      );
    } catch (err) {
      context.handleError(err);
    }
  }

  // format the user list
  useEffect(() => {
    let list = [];

    if (data?.data?.users?.length) {
      list = data.data.users.map((x) => {
        return {
          id: x.id,
          name: x.name,
          email: x.email,
          date_created: x.date_created,
          permission: x.permission,
          status: 'Registered',
        };
      });
    }

    if (data?.data?.invites?.length) {
      data.data.invites.forEach((x) => {
        list.push({
          id: x.id,
          name: '',
          email: x.email,
          date_created: x.date_sent,
          permission: x.permission || 'user',
          status: 'Invited',
        });
      });
    }

    setUsers(list);
    setTotalPages(Math.ceil(list.length / perPage));
  }, [data]);

  // create columns for table
  useEffect(() => {
    if (!users.length) return;

    const headers = ['name', 'email', 'permission', 'status', 'date_created'];
    const cols = [];

    // create columns
    headers.forEach((header, index) => {
      let globalStyleForThisColumn = {};

      switch (header) {
        case 'name': {
          globalStyleForThisColumn = {
            paddingLeft: '10px',
          };
          break;
        }
        case 'permission': {
          globalStyleForThisColumn = {
            textAlign: 'center',
          };
          break;
        }
        case 'status': {
          globalStyleForThisColumn = {
            textAlign: 'center',
            borderRadius: 5,
            paddingInline: '20px',
            maxWidth: 'fit-content',
            minWidth: '125px',
            margin: 'auto',
          };
          break;
        }
        case 'date_created': {
          globalStyleForThisColumn = {
            textAlign: 'center',
          };
          break;
        }
        default:
          break;
      }

      cols.push({
        Header: (
          <div
            style={{ ...globalStyleForThisColumn, textTransform: 'capitalize' }}
          >
            {header.replace(/_/g, ' ').split(' ')[0]}
          </div>
        ),
        // disableSortBy: true,
        accessor: header,
        Cell: ({ row, value }) => {
          const { original } = row;

          switch (header) {
            case 'name': {
              return (
                <div
                  style={{
                    ...globalStyleForThisColumn,
                    textTransform: 'capitalize',
                  }}
                >
                  {original[header]}
                </div>
              );
            }
            case 'permission': {
              return (
                <div
                  style={{
                    ...globalStyleForThisColumn,
                    textTransform: 'capitalize',
                  }}
                >
                  {original[header]}
                </div>
              );
            }
            case 'status': {
              if (original[header] === 'Registered') {
                return (
                  <div
                    style={{
                      ...globalStyleForThisColumn,
                      border: '2px solid #89DCA5',
                      backgroundColor: '#D3EFDC',
                    }}
                  >
                    {original[header]}
                  </div>
                );
              } else if (original[header] === 'Invited') {
                return (
                  <div
                    style={{
                      ...globalStyleForThisColumn,
                      border: '2px solid #FBD355',
                      backgroundColor: '#F3E8C4',
                    }}
                  >
                    {original[header]}
                  </div>
                );
              }

              return (
                <div
                  style={{
                    ...globalStyleForThisColumn,
                    textTransform: 'capitalize',
                  }}
                >
                  {original[header]}
                </div>
              );
            }
            case 'date_created': {
              const [day, month, date, year] = new Date(original[header])
                .toDateString()
                .split(' ');
              return `${date} ${month} ${year}`;
            }
            default:
              return original[header];
          }
        },
      });
    });

    // add actions column
    cols.push({
      Header: <div style={{ textAlign: 'center' }}>Actions</div>,
      accessor: 'Actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { original } = row;

        return (
          <div
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'end',
              maxWidth: '100px',
              margin: 'auto',
            }}
          >
            {original.status === 'Registered' && (
              <div
                style={{
                  color: '#F9C00C',
                  border: '2px solid currentColor',
                  borderRadius: 5,
                  padding: 2,
                  cursor: 'pointer',
                }}
                onClick={() => editUser(original)}
                data-tooltip-content="Edit"
                data-tooltip-show="true"
              >
                <UilEdit size={19} />
              </div>
            )}
            <div
              style={{
                color: '#03A6FF',
                border: '2px solid currentColor',
                borderRadius: 5,
                padding: 2,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (original.status === 'Invited') resendInvite(original);
                else if (original.status === 'Registered')
                  window.open(`mailto:${original.email}`);
              }}
              data-tooltip-content={
                original.status === 'Registered' ? 'Send Mail' : 'Re-invite'
              }
              data-tooltip-show="true"
            >
              <UilEnvelope size={19} />
            </div>
            <div
              style={{
                color: original['permission'] !== "owner" ? '#E53A40' : "#a0a0a0",
                border: '2px solid currentColor',
                borderRadius: 5,
                padding: 2,
                cursor: original['permission'] !== "owner" ? 'pointer' : 'default',
              }}
              onClick={() => {
                if(original['permission'] !== "owner") {
                  if (original.status === 'Registered') deleteUser(original);
                  else if (original.status === 'Invited') deleteInvite(original);
                }
              }}
              data-tooltip-content={`${original['permission'] !== "owner" ? "Delete" : "Owners can not remove themselves."}`}
              data-tooltip-show="true"
            >
              <UilTrash size={19} />
            </div>
          </div>
        );
      },
    });

    setColumns(cols);
  }, [users]);

  // update circular thingy when user or plan updates
  useEffect(() => {
    const circularThingy = document.getElementById('gradient-thingy-wrapper');
    const degrees = planUsage?.userCount?.limit >= 100 ? 135 : (planUsage?.userCount?.used / planUsage?.userCount?.limit) * 180;

    if (circularThingy && !isNaN(degrees))
      circularThingy.style.backgroundImage = `conic-gradient(from -90deg, #3AC569 ${degrees}deg, #f2f2f2 ${degrees}deg)`;
  }, [users, planUsage]);

  // update pagination
  useEffect(() => {
    if (users) {
      const totalPages = Math.ceil(users.length / perPage);
      setTotalPages(totalPages);
      setPage(0);
    }
  }, [perPage]);

  return (
    <Fragment>
      {allowRoute ? (
        <div className="account-users">
          <div className="header">
            <PageTitle
              title="Manage Users"
              description="Add your team members and assign permission levels."
              headerStyle={{ marginTop: 0 }}
            />
            <div className="group">
              <div className="search-wrapper">
                <UilSearch color="#C2C2C2" />
                <input
                  className="search"
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
              </div>
              <CustomButton
                title="Add User"
                variant="primary"
                style={{ marginTop: 0, minWidth: 'max-content' }}
                onClick={() => {
                  if (
                    planUsage &&
                    planUsage.userCount?.limit > planUsage.userCount?.used
                  )
                    invite();
                  else {
                    Swal.fire({
                      icon: 'info',
                      title: 'Upgrade to Access',
                      html: 'Your current plan does not allow you to do this.',
                      showCancelButton: true,
                      confirmButtonText: `Upgrade`,
                      confirmButtonColor: '#0066FF',
                    }).then(async (result) => {
                      if (result.isConfirmed === false) return;
                      navigate('/account/billing');
                    });
                  }
                }}
              />
              {planUsage && (
                <div id="gradient-thingy-wrapper">
                  <div className="plan-usage">{`${
                    planUsage?.userCount?.used ?? '-'
                  }/`}{(function () {
                    if(!planUsage?.userCount?.limit) return "-";
                    
                    if(planUsage.userCount.limit >= 100) return <span className='infinity'>&infin;</span>;
                    return planUsage.userCount.limit;
                  })()}</div>
                </div>
              )}
            </div>
          </div>
          <CustomTable
            hasFooter={false}
            filter={search}
            columns={columns}
            data={users.slice(page * perPage, (page + 1) * perPage)}
            perPage={perPage}
            count={users.length}
            onClick={(row) => {}}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
          />
          <Tooltip anchorSelect='[data-tooltip-show="true"]' place="bottom" />
        </div>
      ) : auth.loading ? (
        <Loader />
      ) : (
        <UpgradePlan />
      )}
    </Fragment>
  );
}
