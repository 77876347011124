import { UilSearch, UilShareAlt, UilEnvelopeCheck, UilEnvelopeOpen, UilEnvelopeReceive  } from '@iconscout/react-unicons';
import axios from 'axios';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Filler
} from 'chart.js';
import CustomModal from 'components/custom/customModal';
import { Animate, Button, Loader, Select, TextInput, WhitelabelContext } from 'components/lib';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { DateRangePicker } from 'react-date-range';
import { Col, Row } from 'react-grid-system';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { Tooltip } from "react-tooltip"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Filler
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
      }
    },
  },
  scale: {
    beginAtZero: true,
    ticks: {
      precision: 0,
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
};

export default function WarmUpChart({ sender, sequence, displayDownloadIcon=true, graphData={} }) {
  const [ChartViewType, setChartViewType] = useState('daily');
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [RangeView, setRangeView] = useState('');
  const [DateDiff, setDateDiff] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const [chartHeader, setChartHeader] = useState(whitelabelDetails?.agencyName || 'SalesBlink');
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!displayDownloadIcon) {
      setChartData(graphData);
      setShowDatePicker(false);
      const containsChartDiv = document.getElementById('containsChart');
      const divToRemove = containsChartDiv.querySelector('div');
      divToRemove.style.display = 'none';
      const waitForCanvas = (retryCount = 0) => {
        const canvas = containsChartDiv.querySelector('canvas');
        if (canvas) {
          canvas.style.minHeight = '290px';
          canvas.style.minWidth = '590px';
        } else if (retryCount < 10) {
          setTimeout(() => waitForCanvas(retryCount + 1), 0);
        } else {
          console.warn("Canvas not found after multiple retries");
        }
      };
      waitForCanvas();
    }
    else {
      setStart(DateTime.now().plus({ days: -7 }).ts);
      setEnd(DateTime.now().ts);
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (loaded) getStatistics('daily');
  }, [loaded]);

  const handleSelect = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);

    setStart(DateTime.fromJSDate(ranges.selection.startDate).ts);
    setEnd(DateTime.fromJSDate(ranges.selection.endDate).ts);

    const startD = DateTime.fromJSDate(ranges.selection.startDate);
    const endD = DateTime.fromJSDate(ranges.selection.endDate);
    const date1 = DateTime.fromISO(endD.toFormat('yyyy-MM-dd'));
    const date2 = DateTime.fromISO(startD.toFormat('yyyy-MM-dd'));
    const diff = date1.diff(date2, ['days']);
    setDateDiff(diff.toObject().days);

    setRangeView(
      `From ${startD.toFormat('dd-MM-yyyy')} To ${endD.toFormat('dd-MM-yyyy')}`
    );
  };

  const submitAlert = () => {
    if (Math.abs(DateDiff) <= 1) {
      Swal.fire({
        icon: 'error',
        title: ``,
        html: `<h5>Please select date range.</h5>`,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });
    } else if (ChartViewType === 'monthly' && Math.abs(DateDiff) > 365) {
      Swal.fire({
        icon: 'info',
        title: ``,
        html: `<h5>Can not show Monthly Chart data for more than 12 Months</h5>`,
        showCancelButton: true,
        confirmButtonText: `Load Chart`,
        confirmButtonColor: '#0066FF',
      }).then((result) => {
        if (result.isConfirmed) {
          getStatistics('monthly');
        }
      });
    } else if (ChartViewType === 'weekly' && Math.abs(DateDiff) > 90) {
      Swal.fire({
        icon: 'info',
        title: ``,
        html: `<h5>Can not show Weekly Chart data for more than 90 days</h5>`,
        showCancelButton: true,
        confirmButtonText: `Okay`,
        confirmButtonColor: '#0066FF',
      }).then((result) => {
        if (result.isConfirmed) {
          // setChartViewType('monthly');
          // getStatistics('monthly');
        }
      });
    } else if (ChartViewType === 'daily' && Math.abs(DateDiff) > 30) {
      Swal.fire({
        icon: 'info',
        title: ``,
        html: `<h5>Can not show Daily Chart data for more than 30 days</h5>`,
        showCancelButton: true,
        confirmButtonText: `Load Chart`,
        confirmButtonColor: '#0066FF',
      }).then((result) => {
        if (result.isConfirmed) {
          setChartViewType('weekly');
          getStatistics('weekly');
        }
      });
    } else if (ChartViewType === 'daily' && Math.abs(DateDiff) <= 30) {
      if (
        Math.abs(DateDiff) === 0 &&
        DateTime.fromJSDate(EndDate).toFormat('yyyy-MM-dd') ===
          DateTime.now().toFormat('yyyy-MM-dd')
      )
        getStatistics('today');
      if (
        Math.abs(DateDiff) === 0 &&
        DateTime.fromJSDate(EndDate).toFormat('yyyy-MM-dd') !==
          DateTime.now().toFormat('yyyy-MM-dd')
      )
        getStatistics('yestarday');
      else getStatistics('daily');
    } else if (
      (ChartViewType === 'weekly' &&
        Math.abs(DateDiff) >= 30 &&
        Math.abs(DateDiff) <= 90) ||
      (ChartViewType === 'monthly' && Math.abs(DateDiff) <= 365)
    ) {
      getStatistics(ChartViewType);
    } else {
      setChartViewType('daily');
      getStatistics('daily');
    }
  };

  function hexToRgba(hex, opacity) {
    hex = hex.replace('#', '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);   
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const getStatistics = async (type) => {
    setShowDatePicker(false);
    setLoading(true)
    try {
      let url = ""
      if(sender) url = `/api/warmuplog/${sender}/chart?type=${type}&start=${start}&end=${end}`
      if(sequence) url = `/api/sequences/${sequence}/chart?type=${type}&start=${start}&end=${end}`
      const res = await axios.get(url);
      const data = res.data.data;
      data.datasets.forEach((dataset) => {
        dataset.backgroundColor = hexToRgba(dataset.borderColor, 0.3);
        dataset.fill = true;
      });
      setChartData(data);
      setLoading(false);
    } catch (err) {setLoading(false)}
  };

  const calculateStats = (label) => {
    const selectedObject = chartData.datasets.find(data => data.label === label);
    const result = selectedObject.data.reduce((curr, sum) => curr + sum, 0);
    return result;
  }

  const handleDownload = async () => {
    setDisabled(true); 
    await downloadChart();
    setDisabled(false);
  }  

  const downloadChart = async () => {
    const captureDiv = document.getElementById('captureThisDiv');
    if (captureDiv) {
      const elementsArray = [...captureDiv.getElementsByClassName('addMargin')];
      elementsArray.forEach(element => element.style.marginBottom = '16px');
      await html2canvas(captureDiv).then(canvas => {
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        const chartName = chartHeader || 'chart' + '.png';
        link.download = chartName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
      elementsArray.forEach(element => element.style.marginBottom = '0px');
    }
  }

  return (
    <>
      {loading && (
        <div
          className="csFormLoader"
          style={{
            background: `rgba(255, 255, 255, 0.5)`,
            marginTop: 45,
            height: '63vh',
            zIndex: 2,
          }}
        >
          <Loader />
        </div>
      )}
      {showDownloadModal && <CustomModal title='Download Chart' width={900} close={true} onClose={() => setShowDownloadModal(false)}>
        <div style={{ paddingInline: 10 }}>
          <div className="flex flex-col">
            <h3 className='mb-1 font-medium'>Chart Header</h3>
            <TextInput
              value={chartHeader}
              placeholder="Enter a name for your chart..."
              onChange={(n, value) => setChartHeader(value)}
              style={{ width: '20rem' }}
            />
            <h3 className='mb-1 font-medium'>Background</h3>
            <div className='flex gap-2 mb-4'>
              <div onClick={() => setBackgroundColor('#ffffff')} 
                style={{ background: '#ffffff' }} 
                className='cursor-pointer min-h-12 min-w-12 p-4 border border-[#d7d7d7] rounded-md'>
              </div>
              <div onClick={() => setBackgroundColor('linear-gradient(to right, violet, orange)')} 
                style={{ background: 'linear-gradient(to right, violet, orange)' }} 
                className='cursor-pointer min-h-12 min-w-12 p-4 border border-[#d7d7d7] rounded-md'>
              </div>
              <div onClick={() => setBackgroundColor('linear-gradient(to right, #2980B9, #6DD5FA)')} 
                style={{ background: 'linear-gradient(to right, #2980B9, #6DD5FA)' }} 
                className='cursor-pointer min-h-12 min-w-12 p-4 border border-[#d7d7d7] rounded-md'>
              </div>
              <div onClick={() => setBackgroundColor('linear-gradient(to right, #654ea3, #eaafc8)')} 
                style={{ background: 'linear-gradient(to right, #654ea3, #eaafc8)' }} 
                className='cursor-pointer min-h-12 min-w-12 p-4 border border-[#d7d7d7] rounded-md'>
              </div>
              <div onClick={() => setBackgroundColor('linear-gradient(to right, #FBD786, #ff9068)')} 
                style={{ background: 'linear-gradient(to right, #FBD786, #ff9068)' }} 
                className='cursor-pointer min-h-12 min-w-12 p-4 border border-[#d7d7d7] rounded-md'>
              </div>
            </div>
            <div id='captureThisDiv' style={{ background: backgroundColor }} className=' flex flex-col gap-2 items-center border border-[#d7d7d7] rounded-md'>
              <h2 className='font-medium text-3xl mt-2 mb-2' style={{ color: backgroundColor === '#ffffff' ? 'black' : 'white' }}>{chartHeader}</h2>
              <div className='flex items-center justify-around p-2 rounded-md' style={{ backgroundColor: '#ffffff', width: 606 }}>
                <div className="flex items-center gap-2">
                  <UilEnvelopeCheck size={28} color={'#3ac569'} />
                  <span className='addMargin'>{calculateStats('Sent')} Sent</span>
                </div>
                <div className="flex items-center gap-2">
                  <UilEnvelopeOpen style={{ display: 'inline-block' }} size={28} color={'#1be1c9'} />
                  <span className='addMargin'>{calculateStats('Opens')} Opens</span>
                </div>
                <div className="flex items-center gap-2">
                  <UilEnvelopeReceive size={28} color={'#fc913a'} />
                  <span className='addMargin'>{calculateStats('Replies')} Replies</span>
                </div>
              </div>
              <div id='containsChart' style={{ minHeight: '300px', background: '#ffffff', padding: 8, borderRadius: 6 }}>
                <WarmUpChart sequence={sequence} displayDownloadIcon={false} graphData={chartData} />
              </div>
              <div className='flex items-center gap-2 text-center relative text-sm p-2 bg-white rounded-md mb-2'><p className='addMargin' style={{ lineHeight: '0.5rem !important' }}>Powered by</p><img className='' style={{ display: 'inline-block !important', verticalAlign: 'middle' }} width={150} height={30} src={whitelabelDetails?.headerLogo} /></div>
            </div>
            <div
              style={{
                display: 'flex',
                marginBlock: '1rem',
                gap: '2rem',
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button
                variant="primary grey"
                action={() => setShowDownloadModal(false)}
                text="Cancel"
              />
              <Button
                id='download-button'
                variant="primary"
                action={handleDownload}
                text={disabled ? 'Downloading..' : 'Download'}
                disabled={disabled}
                style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
              />
              {disabled && <Tooltip anchorSelect='#download-button' place='top'>Downloading..</Tooltip>}
            </div>
          </div>
        </div>
      </CustomModal>}
      <Row>
        <Col>
          <div style={{ display: 'flex' }}>
            <span style={{ width: 90, marginTop: 10 }}>Chart the</span>
            <Select
              label=""
              name="viewType"
              selectStyle={{
                width: 100,
                height: 35,
                padding: '7px 12px',
                marginTop: '-15px',
              }}
              onChange={(name, value) => setChartViewType(value)}
              default={ChartViewType}
              options={[
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                ...(!sender ? [{ value: 'monthly', label: 'Monthly' }] : [])
              ]}
            />
            <span style={{ width: 100, marginTop: 10,marginLeft:5 }}> {sequence ? "stats" : "warmup"} from</span>
            <input
              type="text"
              placeholder={'Date Range'}
              className="rounded"
              style={{
                width: 250,
                height: 35,
                border: '1px solid #e5e5e5',
                padding: '7px 12px',
                borderRadius: 3,
                marginTop: 5,
                marginLeft: 10,
              }}
              value={RangeView}
              onClick={(e) => setShowDatePicker(!showDatePicker)}
            />
            <UilSearch
              style={{ cursor: 'pointer', marginTop: 10, marginLeft: 10 }}
              onClick={(e) => submitAlert()}
            />
            {sequence && displayDownloadIcon && <button
              id="download-chart"
              className="flex justify-center items-center ml-auto px-2 py-1 rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
              style={{ outline: '3px solid #03a6ff', width: 40, height: 40 }}
              type="button"
              onClick={() => setShowDownloadModal(true)}
            >
              <UilShareAlt size={30} color="#03A6FF" />
              <Tooltip anchorSelect='#download-chart' place='top'>Share</Tooltip>
            </button>}
          </div>
          {showDatePicker && (
            <Animate type="slidedown" timeout={50}>
              <div className="dropdownMenu" style={{ zIndex: 9999, marginTop: 2, left: displayDownloadIcon ? 323 : 0, right: 'auto' }}>
                <div
                  style={{
                    position: 'fixed',
                    inset: 0,
                    zIndex: -1,
                  }}
                  onClick={() => setShowDatePicker(false)}
                >
                </div>
                <div className="dropdownMenuBody">
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: StartDate,
                        endDate: EndDate,
                        key: 'selection',
                      },
                    ]}
                    onChange={handleSelect}
                  />
                </div>
              </div>
            </Animate>
          )}
        </Col>
      </Row>
      {chartData && <Line options={options} data={chartData} />}
    </>
  );
}
