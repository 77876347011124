import {
  UilClock,
  UilEnvelope,
  UilHistory,
  UilCheckCircle,
  UilTimesCircle,
  UilUser,
  UilMessage,
} from '@iconscout/react-unicons';
import axios from 'axios';
import Calendar from 'components/fullcalendar/calendar';
import {
  SubSubNav,
  Loader,
  UpgradePlan,
  useAPI,
  useRouteCheck,
  AuthContext,
  useNavigate,
  WhitelabelContext
} from 'components/lib';
import { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import LogsReports from './logsreports';
import { DateTime } from 'luxon';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'react-tooltip';
import './reports.scss'
import Swal from 'sweetalert2';

function renderEventContent(eventInfo) {
  if(eventInfo?.event?._def?.extendedProps?.completed) return <div className='calendarCard success'>
    <div className='successText'>
      <UilCheckCircle size={26} />
    </div>
    <div className='textSection'>
      <div style={{ fontWeight: 500 }}>
        {eventInfo?.event?._def?.extendedProps?.scheduled_time ? DateTime.fromISO(eventInfo?.event?._def?.extendedProps?.scheduled_time).toFormat('hh:mm a') : eventInfo.timeText}
      </div>
      <div className='eventTitle flex'>
        <a
          href={`/contact/${eventInfo?.event?._def?.extendedProps?.contactID}`}
          className="csCustomeLink"
          style={{ color: '#3AC569' }}
          target='_blank'
        >
          <UilUser size={12} style={{ marginTop: 4, marginRight: 2 }} /> {eventInfo.event.title}
        </a>
      </div>
      <div className='eventTitle flex mt-2'>
        <a
          href={`/outreach/sequences/${eventInfo?.event?._def?.extendedProps?.sequence}/stats`}
          className="csCustomeLink"
          style={{ color: '#3AC569' }}
          target='_blank'
        >
          <UilMessage size={12} style={{ marginTop: 4, marginRight: 2 }} /> {eventInfo?.event?._def?.extendedProps?.sequenceName}
        </a>
      </div>
    </div>
  </div>

  if(!eventInfo?.event?._def?.extendedProps?.completed && (eventInfo.isToday || eventInfo.isFuture)) return <div className='calendarCard warning'>
    <div className='warningText'>
      <UilClock size={26} />
    </div>
    <div className='textSection'>
      <div style={{ fontWeight: 500 }}>
        {eventInfo?.event?._def?.extendedProps?.scheduled_time ? DateTime.fromISO(eventInfo?.event?._def?.extendedProps?.scheduled_time).toFormat('hh:mm a') : eventInfo.timeText}
      </div>
      <div className='eventTitle flex'>
        <a
          href={`/contact/${eventInfo?.event?._def?.extendedProps?.contactID}`}
          className="csCustomeLink"
          style={{ color: '#3AC569' }}
          target='_blank'
        >
          <UilUser size={12} style={{ marginTop: 4, marginRight: 2 }} /> {eventInfo.event.title}
        </a>
      </div>
      <div className='eventTitle flex mt-2'>
        <a
          href={`/outreach/sequences/${eventInfo?.event?._def?.extendedProps?.sequence}/stats`}
          className="csCustomeLink"
          style={{ color: '#3AC569' }}
          target='_blank'
        >
          <UilMessage size={12} style={{ marginTop: 4, marginRight: 2 }} /> {eventInfo?.event?._def?.extendedProps?.sequenceName}
        </a>
      </div>
    </div>
  </div>

  if(!eventInfo?.event?._def?.extendedProps?.completed && !eventInfo.isToday && !eventInfo.isFuture) return <div className='calendarCard error'>
    <div className='errorText'>
      <UilTimesCircle size={26} />
    </div>
    <div className='textSection'>
      <div style={{ fontWeight: 500 }}>
        {eventInfo?.event?._def?.extendedProps?.scheduled_time ? DateTime.fromISO(eventInfo?.event?._def?.extendedProps?.scheduled_time).toFormat('hh:mm a') : eventInfo.timeText}
      </div>
      <div className='eventTitle flex'>
        <a
          href={`/contact/${eventInfo?.event?._def?.extendedProps?.contactID}`}
          className="csCustomeLink"
          style={{ color: '#3AC569' }}
          target='_blank'
        >
          <UilUser size={12} style={{ marginTop: 4, marginRight: 2 }} /> {eventInfo.event.title}
        </a>
      </div>
      <div className='eventTitle flex mt-2'>
        <a
          href={`/outreach/sequences/${eventInfo?.event?._def?.extendedProps?.sequence}/stats`}
          className="csCustomeLink"
          style={{ color: '#3AC569' }}
          target='_blank'
        >
          <UilMessage size={12} style={{ marginTop: 4, marginRight: 2 }} /> {eventInfo?.event?._def?.extendedProps?.sequenceName}
        </a>
      </div>
    </div>
  </div>

  return (
    <>
    
    </>
  );
}

function EmailReports() {
  const query = new URLSearchParams(window.location.search);
  const [activeMonth, setActiveMonth] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [reloading, setReloading] = useState(false)
  const [timezone, setTimezone] = useState(null);
  const [ownedBy, setOwnedBy] = useState('');
  const [users, setUsers] = useState([]);
  const [senders, setSenders] = useState([]);
  const [sender, setSender] = useState("");
  const [sequences, setSequences] = useState([]);
  const [sequence, setSequence] = useState(null);
  const [sequenceName, setSequenceName] = useState("");
  const usersApi = useAPI('/api/account/owner-list');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if(query.get('team') && authContext.user.permission === 'owner') setOwnedBy(query.get('team') || authContext.user.id)
    else setOwnedBy(authContext.user.id)
  }, []);

  useEffect(() => {
    if(ownedBy && ownedBy.length>0) {
      reloadSenders('')
      fetchSequences('') 
    }
  }, [ownedBy])

  useEffect(() => {
    if (usersApi?.data?.users) setUsers(usersApi.data.users?.map((userObj) => ({
      label: `${userObj.name} (${userObj.email})`,
      value: userObj?.id,
    })));
  }, [usersApi]);

  useEffect(() => {
    fetchEvents();
  }, [activeMonth, ownedBy, sequence, sender]);

  async function fetchSequences(inputValue) {
    try {
      let seqQuery = query.get('sequence') ? query.get('sequence').split('(','')[0] : ""
      let result = await axios.get(
        `/api/sequences?limit=10&search=${inputValue || seqQuery}&userID=${ownedBy}`
      );
      const data = result?.data?.data;
      // Transform the fetched data into options array
      const options = data.map((item) => ({
        id: item.id,
        name: item.name,
        ...item,
      }));
      setSequences(options);

      return options;
    } catch (err) {
      console.log('Error in getting sequences ', err);
      return [];
    }
  }

  async function reloadSenders(inputValue) {
    try {
        let result;
        result = await axios.get(`/api/senders?limit=10&skip=0&search=${inputValue}&ownedby=${ownedBy}`);
        if (result?.data?.success) {
            let senderList = result.data.data.map((sender) => ({
                key: sender.id,
                label: `${
                    sender.alias ||
                    sender.microsoft_email ||
                    sender.google_email ||
                    sender.smtpImapEmail
                }`,
                value: sender.id,
                ...sender
            }))
            setSenders(senderList);
            return senderList
        }
    
        return []
    } catch (err) {
        return []
    }
}

  const fetchEvents = async () => {
    if(Object.keys(activeMonth).length === 0) return
    try {
      setLoading(true)
      setReloading(true)
      setData([])
      let params = { ...activeMonth}
      if(ownedBy) params['ownedby'] = ownedBy
      if(sequence) params['sequence'] = sequence.id
      if(sender != "") params['sender'] = sender
      let result = await axios.get('/api/email-tasks', {
        params,
      });

      const allData = result.data.data
      if(allData.length > 0) { 
        // Define how many items to set at each interval
        const batchSize = 200;
        let startIndex = 0;
  
        // Set data in small portions
        // const setDataPortion = () => {
        //   const portion = allData.slice(startIndex, startIndex + batchSize);
        //   setData((prevData) => [...prevData, ...portion]);
        //   startIndex += batchSize;
  
        //   // If there's more data to set, continue in the next batch
        //   if (startIndex < allData.length) {
        //     setTimeout(setDataPortion,300); // Add a delay between portions
        //   } 
        //   else setTimeout(() => {
        //     setLoading(false)
        //   }, ((allData.length / batchSize) * 1000));
        // };
  
        // Start setting data in portions
        // setDataPortion();
        setData(allData);
        setLoading(false);

      }
       else setLoading(false)
      setReloading(false)
    } catch (error) { 
      setLoading(false) 
      setReloading(false)
    }
  };

  return (
    <div className="reportCalendar">
      {loading && (
        <div
          className="csFormLoader"
          style={{
            background: `rgba(255, 255, 255, 0.5)`,
            marginTop: 190,
            height: '100vh',
          }}
        >
          <Loader>
            <div style={{ fontWeight: 'bold' }}>
              Loading reports. Please wait... <br />
              This might take a few seconds.
            </div>
          </Loader>
        </div>
      )}
      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
        <UilSync
          size={20}
          className={`${reloading?"rotate":""} ml-auto`}
          id="refresh-email-logs"
          style={{ marginTop: -90 }}
          onClick={() => {
            setReloading(true)
            fetchEvents();
          }}
        />
        <Tooltip anchorSelect="#refresh-email-logs" place="top">
          Refresh
        </Tooltip>
      </div> */}
      <Calendar
        title="Email Sending Schedule"
        description={
          sequence
            ? `Email activity for ${sequenceName} sequence displayed in ${timezone} timezone.`
            : 'All your email activity in your local timezone.'
        }
        timeZone={timezone}
        extraHtml={
          <div className="mt-2" style={{ marginBottom: -20 }}>
            <Row>
              {(authContext.user.permission === 'owner' || authContext.user.permission === 'admin') &&
              <Col md={3}>
                <Select
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #e5e5e5',
                    }),
                    menu: (preSet) => ({
                      ...preSet,
                      zIndex: 9999999,
                    }),
                  }}
                  placeholder="Owned By"
                  value={
                    users?.find((userObj) => userObj.value === ownedBy) || null
                  }
                  isClearable
                  options={users}
                  onChange={(selected) => {
                    if (selected) setOwnedBy(selected.value);
                    else setOwnedBy('');
                  }}
                />
              </Col>}
              <Col md={3}>
                <AsyncSelect
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: '100%',
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #d7d7d7',
                      marginBottom: 10,
                    }),
                    menu: (preSet) => ({
                      ...preSet,
                      zIndex: 9999999,
                    }),
                  }}
                  placeholder="Select Sequence"
                  value={sequence}
                  defaultOptions={sequences}
                  isClearable
                  loadOptions={fetchSequences} // Function to fetch sequence options asynchronously
                  getOptionValue={(option) => option.id} // Define how to get the value of each option
                  getOptionLabel={(option) => option.name} // Define how to get the label of each option
                  onChange={(selected) => {
                    if (selected) {
                      Swal.fire({
                        icon: 'info',
                        html: `<div>
                        All emails in calendar are displayed in ${selected?.timezone} for ${selected?.name} sequence.
                      </div>`,
                        confirmButtonText: `Okay`,
                        confirmButtonColor: '#0066FF',
                      });
                      setSequence(selected);
                    } else setSequence(null);
                    setTimezone(selected?.timezone);
                    setSequenceName(selected?.name);
                  }}
                  noOptionsMessage={() => 'No Results, Type to Search'}
                />
              </Col>
              <Col md={3}>
                <AsyncSelect
                  placeholder="All Email Senders"
                  styles={{
                    control: () => ({
                      padding: 1,
                      width: 250,
                      borderRadius: 5,
                      display: 'flex',
                      border: '1px solid #d7d7d7',
                    }),
                    menu: (preSet) => ({
                      ...preSet,
                      zIndex: 9999999,
                    }),
                  }}
                  isClearable
                  onChange={(selected) => {
                    if (selected) setSender(selected.value);
                    else setSender('');
                  }}
                  loadOptions={reloadSenders}
                  defaultOptions={senders}
                  noOptionsMessage={() => 'Search for an Email Sender'}
                />
              </Col>
            </Row>
          </div>
        }
        events={data}
        onChange={(event) => {
          setLoading(true);
          setData([]);
          setActiveMonth(event);
        }}
        eventContent={renderEventContent}
        sequence={sequence?.name}
        sender={sender}
      />
    </div>
  );
}

function TaskReports() {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <h2 className="float-left mt-2 font-semibold text-lg my-auto">
          Task Reports
        </h2>
      </div>
    </div>
  );
}

export default function Reports(props) {
  const [selectedTab, setSelectedTab] = useState('Sequence Logs');
  const navigate = useNavigate();
  const auth = useAPI('/api/auth');
  const allowRoute = useRouteCheck(
    window.location.pathname,
    auth.data?.plan,
    auth.data?.subscription,
    auth.data?.expired
  );
  const { whitelabelDetails } = useContext(WhitelabelContext);

  useEffect(() => {
    if(props.type === "calendar") setSelectedTab("Email Sending Schedule")
  }, [])

  if (!allowRoute) {
    if (auth.loading) return <Loader />;
    else return <UpgradePlan />;
  }

  return (
    <div>
      <Helmet>
        <title>{`Reports | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      <SubSubNav
        selected={selectedTab}
        onChange={(selectedItem) => {
          setSelectedTab(selectedItem.title);
          if(selectedItem.title === "Email Sending Schedule") navigate('/outreach/reports/calendar')
          else navigate('/outreach/reports/logs')
        }}
        menuitems={[
          {
            icon: 'unicons',
            image: <UilHistory />,
            title: 'Sequence Logs',
          },
          {
            icon: 'unicons',
            image: <UilEnvelope />,
            title: 'Email Sending Schedule',
          },
        ]}
      />

      {selectedTab === 'Sequence Logs' && <LogsReports />}
      {selectedTab === 'Email Sending Schedule' && <EmailReports />}
      {selectedTab === 'Tasks' && (
        <Row>
          <Col>
            <TaskReports />
          </Col>
        </Row>
      )}
    </div>
  );
}
