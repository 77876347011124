import {
  UilCalendarAlt,
  UilClock,
  UilEnvelopeRedo,
  UilExternalLinkAlt,
  UilBell,
  UilQuestionCircle,
  UilPlus,
  UilClipboardBlank,
  UilEdit,
  UilSubject,
  UilSetting,
  UilListUl,
  UilTimes,
  UilCheck,
  UilGoogle,
  UilAngleLeft,
  UilAngleRight,
  UilAngleUp,
  UilAngleDown,
  UilAlignJustify,
  UilTrash,
  UilTrashAlt,
  UilGlobe,
  UilUploadAlt,
  UilPen,
  UilExclamationTriangle,
  UilPlusCircle
} from '@iconscout/react-unicons';
import axios from 'axios';
import BundledEditor from 'components/custom/buldledEditor';
import CustomButton from 'components/custom/customButton';
import CustomModal from 'components/custom/customModal';
import { CustomSelect } from 'components/customselect/customselect';
import { Label, Radio, Select, Button, TextInput, WhitelabelContext } from 'components/lib';
import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { CirclePicker } from 'react-color';
import { Col, Row } from 'react-grid-system';
import { Helmet } from 'react-helmet';
import { Range } from 'react-range';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import DomainEditor from './../engage/DomainEditor';
import AsyncSelect from 'react-select/async';
import { useDropzone } from 'react-dropzone';
import { getImageSize } from 'react-image-size';
import CreatableSelect from 'react-select/creatable';
import SimpleSelect from 'react-select';
import timezones from '../../assets/data/timezone';
import './settings.scss';

const MeetCustomLabel = ({ title, id, info, style, position }) => {
  return (
    <>
      <div
        className="flex mb-1"
        style={{ fontSize: 16, fontWeight: 500, ...style }}
      >
        {title}
        {id && (
          <UilQuestionCircle
            size={18}
            id={id}
            style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
          />
        )}
      </div>
      {info && (
        <Tooltip anchorSelect={`#${id}`} place={position?position:"right"}>
          {info}
        </Tooltip>
      )}
    </>
  );
};

const Badge = ({ text, onClose }) => {
  const [active, setActive] = useState(false);

  return (
    <span
      style={{
        display: 'flex',
        cursor: 'pointer',
        padding: 5,
        border: `#${active ? 'd14343' : '000'} 1px solid`,
        borderRadius: 15,
        color: `#${active ? 'fff' : '000'}`,
        backgroundColor: `#${active ? 'd14343' : 'fff'}`,
        marginLeft: 5,
      }}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => onClose()}
    >
      {text}{' '}
      <UilTimes
        size={16}
        style={{
          color: `#${active ? 'fff' : 'd14343'}`,
          marginTop: 5,
          marginLeft: 5,
        }}
      />
    </span>
  );
};

function SettingsSidebar(props) {
  return (
    <div
      className="csSettingsSidebar"
      style={{
        height: '80vh',
        borderRight: '1px solid #D7D7D7',
        paddingRight: 30,
        borderRadius: 0,
      }}
    >
      {props?.sections.map((item) => (
        <div
          key={item.label}
          onClick={() => {
            item.ref.current.scrollIntoView();
          }}
          className={`csSettingsSidebarItem ${
            props.selected.includes(item.label) && 'active'
          }`}
        >
          {item.icon}
          <div className="csSidebarLabel">{item.label}</div>
        </div>
      ))}
      <div className="flex justify-center">
        <CustomButton
          className=""
          title="Save"
          variant="primary"
          onClick={() => props.saveCalendar()}
          disabled={props.loading}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  );
}

const NumberInput = ({ onChange, value, style, width }) => {
  const decreaseValue = () => {
    onChange(value - 1);
  };

  const increaseValue = () => {
    onChange(value + 1);
  };

  return (
    <div className="number-input" style={{ ...style }}>
      <input
        type="number"
        className="number-input__field"
        value={value}
        style={{ width: width || '100%', textAlign: 'left', paddingLeft: 10 }}
        readOnly
      />
      <button
        className="number-input__btn number-input__btn--left"
        onClick={decreaseValue}
      >
        <UilAngleLeft size={25} />
      </button>
      <button
        className="number-input__btn number-input__btn--right"
        onClick={increaseValue}
      >
        <UilAngleRight size={25} />
      </button>
    </div>
  );
};

function EmailNotification(props) {
  const editorRef = useRef(null);

  function insertText(text) {
    if (!editorRef.current) return;
    editorRef.current.execCommand('InsertText', false, text);
  }

  return (
    <div style={{ marginLeft: 5 }}>
      {/* <p>{props.message}</p> */}
      <br />
      <Label text="Reply To:" style={{ marginTop: -15 }} />
      <TextInput
        value={props.reply}
        onChange={(n, value) => props.setReply(value)}
        style={{ width: 335 }}
        placeholder="Reply to"
      />
      <br />
      <Label text="Subject:" style={{ marginTop: -15 }} />
      <TextInput
        value={props.subject}
        onChange={(n, value) => props.setSubject(value)}
        style={{ width: 335 }}
        placeholder="Subject"
      />
      <MacrosList insertText={insertText} macros={props.completeViewOfSelectedList} />
      <br />
      <Label text="Body" style={{ marginTop: -15 }} />
      <BundledEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={props.body}
        onEditorChange={(editedContent) => {
          props.setBody(editedContent);
        }}
        init={{
          height: 300,
          menubar: false,
          elementpath: false,
          plugins: [
            'advlist',
            'anchor',
            'autolink',
            'image',
            'link',
            'lists',
            'searchreplace',
            'table',
          ],
          toolbar:
            'blocks | ' +
            'bold italic forecolor fontsize | alignleft | aligncenter ' +
            'alignright | alignjustify | bullist | numlist | ' +
            'removeformat | insertImage | unsubButton',
          contextmenu: 'copy paste cut | link ',
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',  
          content_style:
            'body { font-family:Rubik,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  );
}

function DayRange({ onRemove, workingHour, day, onChange, onAdd }) {
  const getTimeLabel = (hour) => {
    if (hour === 0 || hour === 24) return '12:00 AM';
    if (hour === 12) return '12:00 PM';
    if (hour > 12) return `${hour - 12}:00 PM`;
    if (hour < 12) return `${hour}:00 AM`;
  };

  return (
    <div style={{ display: 'flex', marginTop: 40, marginBottom: 55 }}>
      {onRemove ? (
        <div style={{ width: 40 }}></div>
      ) : (
        <input
          type="checkbox"
          className="csCheckbox"
          style={{ marginLeft: -10, marginRight: 5 }}
          checked={workingHour.enabled}
          onChange={(e) => {
            onChange({
              ...workingHour,
              enabled: !workingHour.enabled,
            });
          }}
        />
      )}
      <div
        style={{
          width: 80,
          fontWeight: 600,
          marginTop: 2,
          opacity: workingHour.enabled ? 1 : 0.5,
        }}
      >
        {onRemove ? '' : day}
      </div>
      <Range
        step={1}
        min={0}
        max={24}
        values={[workingHour.start, workingHour.end]}
        onChange={(values) =>
          onChange({
            ...workingHour,
            start: values[0],
            end: values[1],
          })
        }
        renderTrack={({ props, children }) => {
          const startValue = workingHour.start;
          const endValue = workingHour.end;
          const trackWidth = ((endValue - startValue) / 24) * 100;
          // console.log("TEST ", day, workingHour.start, workingHour.end)
          return (
            <div
              {...props}
              style={{
                ...props.style,
                height: '6px',
                width: '100%',
                backgroundColor: '#F2F2F2',
                border: '1px solid #D7D7D7',
                marginTop: 11,
                marginLeft: 12,
                position: 'relative',
                opacity: workingHour.enabled ? 1 : 0.5,
                cursor: workingHour.enabled ? 'pointer' : 'default',
              }}
            >
              {children}

              <div
                className="track-between-thumbs"
                style={{
                  position: 'absolute',
                  left: `${(startValue / 24) * 100}%`,
                  width: `${trackWidth}%`,
                  height: '150%',
                  backgroundColor: '#0999FF',
                }}
              ></div>
            </div>
          );
        }}
        renderThumb={({ props }) => {
          return (
            <div
              {...props}
              style={{
                ...props.style,
                height: 20,
                width: 20,
                padding: '1px 3px',
                backgroundColor: '#0999FF',
                borderRadius: 20,
                cursor: workingHour.enabled ? 'grab' : 'default',
              }}
            >
              <div
                className="rangeSliderTimeLabel"
                style={{
                  backgroundColor: '#EAF1F7',
                  border: '2px solid #AFCFF1',
                  borderRadius: 5,
                  fontSize: 14,
                  padding: '1px 3px',
                }}
              >
                {getTimeLabel(props['aria-valuenow'])}
              </div>
            </div>
          );
        }}
      />
      <div
        style={{
          marginLeft: 15,
          cursor: workingHour.enabled ? 'pointer' : 'default',
          opacity: workingHour.enabled ? 1 : 0.5,
        }}
        onClick={() => {
          if (workingHour.enabled) {
            if (onRemove) onRemove(workingHour.id);
            if (onAdd)
              onAdd({
                id: `i-${Math.floor(Math.random() * 1000)}`,
                day,
                enabled: true,
                start: 9,
                end: 18,
              });
          }
        }}
      >
        {onRemove ? <UilTimes /> : <UilPlus id={"add-slot"} />}
      </div>
      <Tooltip anchorSelect={`#add-slot`}>
        Add another slot.
      </Tooltip>
    </div>
  );
}

function MacrosList(props) {
  return <div>
    <button
      type="button"
      onClick={() => props.insertText("{{booking_time}}")}
      style={{ marginRight: 5 }}
      className="bg-gray-300 mt-2 mb-2 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full inline-flex items-center"
    >
      {"{{booking_time}}"}
    </button>
    <button
      type="button"
      onClick={() => props.insertText("{{meeting_link}}")}
      style={{ marginRight: 5 }}
      className="bg-gray-300 mt-2 mb-2 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full inline-flex items-center"
    >
      {"{{meeting_link}}"}
    </button>
    {props.macros?.map((macro, i) => (
      <button
        key={i}
        type="button"
        onClick={() => props.insertText(`{{${macro.toLowerCase()}}}`)}
        style={{ marginRight: 5 }}
        className="bg-gray-300 mt-2 mb-2 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full inline-flex items-center"
      >
        {`{{${macro.toLowerCase()}}}`}
      </button>
    ))}
  </div>
}

export default function Settings(props) {
  const calendarLinkRef = useRef(null);
  const generalSettingsRef = useRef(null);
  const workingHoursRef = useRef(null);
  const googleCalendarRef = useRef(null);
  const attendeesRef = useRef(null);
  const emailNotificationsRef = useRef(null);
  const confirmationPageRef = useRef(null);
  const emailReminderRef = useRef(null);
  const bookingFormRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [CalendarType, setCalendarType] = useState('');
  const [slugEditable, setSlugEditable] = useState(false);
  const [MeetingSlug, setMeetingSlug] = useState('');
  const [CustomMeetingSlug, setCustomMeetingSlug] = useState('');

  const [useCustomDomain, setUseCustomDomain] = useState(false);
  const [showCreateDomain, setShowCreateDomain] = useState(false);
  const [showCalendarSelect, setShowCalendarSelect] = useState(false);
  const [advancedMeetingSettings, setAdvancedMeetingSettings] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [domains, setDomains] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [editDomain, setEditDomain] = useState(null);

  // General Settings
  // const [CalendarName, setCalendarName] = useState("")
  const [Description, setDescription] = useState('');
  const [EventColor, setEventColor] = useState('#fff');
  const [MyTimezone, setMyTimezone] = useState('');
  const [Location, setLocation] = useState('');
  const [CustomLocation, setCustomLocation] = useState('');
  const [CustomName, setCustomName] = useState('');
  const [CustomProfileImage, setCustomProfileImage] = useState(null);
  const [showCustomProfileImageModal, setShowCustomProfileImageModal] =
    useState(false);

  // Advance Slot Settings
  const [BufferTime, setBufferTime] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [LastMinuteBookingBuffer, setLastMinuteBookingBuffer] = useState({
    value: 3,
    label: 'days',
  });
  const [Slots, setSlots] = useState('');
  const [Holidays, setHolidays] = useState([]);
  const [Holiday, setHoliday] = useState('');
  const [DailyLimit, setDailyLimit] = useState('');
  // const [SlotDuration, setSlotDuration] = useState("")
  // const [AppointmentType, setAppointmentType] = useState("")
  // const [AppointmentApproval, setAppointmentApproval] = useState("")

  // Working Hours
  const [WorkingHours, setWorkingHours] = useState([]);

  // Google Calendar Events
  const [Calendar, setCalendar] = useState('');
  const [CalendarConflict, setCalendarConflict] = useState([]);

  // Attendees List
  const [List, setList] = useState(null);
  const [listData, setListData] = useState([]);
  const selectedList = listData.find(list => list.id === List?.id);
  const completeViewOfSelectedList = selectedList?.views.find(view => view.name === "Complete View").columns || [];

  // Email Notification
  const [NotificationReplyTo, setNotificationReplyTo] = useState('');
  const [NotificationSubject, setNotificationSubject] = useState('New Meeting Booked');
  const [NotificationBody, setNotificationBody] = useState(`
  <p>Hey there,</p>
  <p>Thank you for booking a meeting at {{booking_time}}.</p>
  <p>Here's the meeting link: {{meeting_link}}</p>
  `);

  const [ConfirmationPage, setConfirmationPage] = useState('');
  // const [WebHooks, setWebHooks] = useState([])

  // Email Reminder
  const [EmailReminder, setEmailReminder] = useState(false);
  const [ReminderReplyTo, setReminderReplyTo] = useState('');
  const [ReminderSubject, setReminderSubject] = useState('');
  const [ReminderBody, setReminderBody] = useState('');
  const [ReminderTime, setReminderTime] = useState({
    value: 30,
    label: 'minutes',
  });

  // Booking Form
  const [CustomQuestions, setCustomQuestions] = useState([]);

  const { whitelabelDetails } = useContext(WhitelabelContext);

  function getCurrentUsersTimezone() {
    const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return country
  }

  function jsonToQueryString(json) {
    return Object.keys(json)
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&');
  }

  useEffect(() => {
    if(props.meetData.error){
      Swal.fire({
      icon: 'warning',
      title: `Something went wrong`,
      html: '<h5>You need to reconnect calendar</h5>',
      confirmButtonText: `Reconnect`,
      confirmButtonColor: '#E53A40',
      confirmButtonHtml: <Button text="Reconnect" variant="primary" />,
      }).then(async(result)=>{
        // if click on reconnect it will disconnect the sender
        if(result.isConfirmed) {
          try {
          await props.Axios({
            url: '/api/meet/disconnect',
            method: 'delete',
            data: {},
          });
          let stringifiedParameters;
          if(props.meetData.calendar_type === 'google'){
              stringifiedParameters = jsonToQueryString({
              client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
              redirect_uri: `${window.location.origin}/meet`,
              scope: [
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/calendar.events',
                'https://www.googleapis.com/auth/calendar.readonly',
              ].join(' '),
              response_type: 'code',
              state: 'google-calendar-scope',
              access_type: 'offline',
              prompt: 'consent',
              include_granted_scopes: true,
            });
              let url = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParameters}`;
              window.location.href = url;
          }
          else{
            stringifiedParameters = jsonToQueryString({
                client_id: process.env.REACT_APP_OUTLOOK_CLIENTID,
                redirect_uri: `${window.location.origin}/meet`,
                scope: [
                  'offline_access',
                  'https://graph.microsoft.com/Calendars.Read',
                  'https://graph.microsoft.com/Calendars.ReadWrite',
                  'https://graph.microsoft.com/Calendars.ReadWrite.Shared',
                  'https://graph.microsoft.com/user.read',
                  'https://graph.microsoft.com/IMAP.AccessAsUser.All',
                  'https://graph.microsoft.com/SMTP.Send',
                ].join(' '),
                response_type: 'code',
                response_mode: 'query',
                state: 'outlook-calendar-scope',
             });
            let url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${stringifiedParameters}`;
            window.location.href = url;
          }
          
          props.getMeetDetails();
        } catch (err) {
          if (err?.response?.data?.message)
            props.Toast.fire({
              icon: 'error',
              title: err.response.data.message,
            });
          else
            props.Toast.fire({
              icon: 'error',
              title: 'Internal server error.',
            });
        }
        }})
    }
    if (props.meetData) {
      setCalendarType(props.meetData.calendar_type);
      setMeetingSlug(props.meetData.meeting_slug);

      setUseCustomDomain(props.meetData.use_custom_domain);
      if (props.meetData.custom_domain) {
        setSelectedDomain(
          props.meetData.custom_domain._id || props.meetData.custom_domain
        );
        setCustomMeetingSlug(
          props.meetData.custom_domain.domain ||
            props.meetData.custom_domain_slug
        );
      }

      setDescription(props.meetData.description);
      setEventColor(props.meetData.event_color);
      if (props.meetData.list) setList(props.meetData.list);
      if (props.meetData.my_timezone && props.meetData.my_timezone !== '')
        setMyTimezone(props.meetData.my_timezone);
      else setMyTimezone(getCurrentUsersTimezone());
      if (props.meetData.location !== '') setLocation(props.meetData.location);
      else {
        if (props.meetData.calendar_type === 'google')
          setLocation('Google Meet');
      }
      setCustomLocation(props.meetData.custom_location);
      setCustomName(props.meetData.custom_name);
      setCustomProfileImage(
        props.meetData.custom_profile_image || props.meetData.profile_image
      );

      setBufferTime(props.meetData.buffer_time);
      setLastMinuteBookingBuffer(props.meetData.last_minute_booking_buffer);
      setDateRange(props.meetData.date_range);
      setSlots(props.meetData.slots);
      setHolidays(props.meetData.holidays);
      setDailyLimit(props.meetData.daily_limit);

      setWorkingHours(props.meetData.meeting_working_hours);

      setCalendar(props.meetData.calendar_id);
      setCalendarConflict(props.meetData.calendar_conflicts);
      if (!props.meetData.calendar_id || props.meetData.calendar_id === '')
        setShowCalendarSelect(true);

      setNotificationReplyTo(props.meetData.email_notification.reply_to);

      if (props.meetData.email_notification.subject !== "") setNotificationSubject(props.meetData.email_notification.subject);
      if(props.meetData.email_notification.body !== "") setNotificationBody(props.meetData.email_notification.body);

      setConfirmationPage(props.meetData.confirmation_page);

      setEmailReminder(props.meetData.email_reminder.set_reminder);
      setReminderReplyTo(props.meetData.email_reminder.reply_to);

      setReminderSubject(props.meetData.email_reminder.subject);
      setReminderBody(props.meetData.email_reminder.body);
      setReminderTime(props.meetData.email_reminder.time);

      setCustomQuestions(props.meetData.meeting_custom_questions);
    }
  }, [props.meetData]);

  useEffect(() => {
    fetchTimezoneLists('')
    fetchDomains();
    fetchList('');
  }, []);

  async function fetchList(inputValue) {
    try {
      let result = await axios.get(
        `/api/list?limit=10&search=${inputValue}&integration=true`
      );
      const data = result?.data?.data;
      setListData(data);
      return data;
    } catch (err) {
      return [];
    }
  }

  const updateData = async () => {
    if (Location === 'custom' && CustomLocation.trim() === '')
      return props.Toast.fire({
        icon: 'error',
        title: 'Custom location required.',
      });
    if (useCustomDomain && selectedDomain === '')
      return props.Toast.fire({
        icon: 'error',
        title: 'Custom domain required.',
      });
    const updateCalendarData = {
      meeting_slug: MeetingSlug,
      // calendar_name: CalendarName,
      description: Description,
      event_color: EventColor,
      my_timezone: MyTimezone,
      location: Location,
      custom_location: CustomLocation,
      custom_name: CustomName,
      use_custom_domain: useCustomDomain,
      custom_domain: selectedDomain,
      custom_domain_slug: CustomMeetingSlug,
      buffer_time: BufferTime,
      date_range: dateRange,
      last_minute_booking_buffer: LastMinuteBookingBuffer,
      slots: Slots,
      holidays: JSON.stringify(Holidays), // stringify for the API
      daily_limit: DailyLimit,
      meeting_working_hours: WorkingHours,
      calendar_id: Calendar,
      calendar_conflicts: CalendarConflict,
      confirmation_page: ConfirmationPage,
      email_notification: {
        reply_to: NotificationReplyTo,
        subject: NotificationSubject,
        body: NotificationBody
      },
      email_reminder: {
        set_reminder: EmailReminder,
        reply_to: ReminderReplyTo,
        subject: ReminderSubject,
        body: ReminderBody,
        time: ReminderTime
      },
      list: List,
      meeting_custom_questions: CustomQuestions,
    };

    try {
      setLoading(true);

      await props.Axios({
        url: '/api/meet',
        method: 'put',
        headers: {
          'slug-edited': slugEditable,
        },
        data: updateCalendarData,
      });
      props.Toast.fire({ icon: 'success', title: 'Settings Saved' });

      if (slugEditable) setSlugEditable(false);

      // using non-stringified data for holidays because props.meetData.holidays is an array
      props.setMeetData({
        ...props.meetData,
        ...updateCalendarData,
        holidays: Holidays,
      });
      setShowCalendarSelect(false);
    } catch (err) {
      if (err?.response?.data?.message)
        props.Toast.fire({ icon: 'error', title: err.response.data.message });
      else props.Toast.fire({ icon: 'error', title: 'Internal server error.' });
    } finally {
      setLoading(false);
    }
  };

  const disconnectCalendar = async () => {
    Swal.fire({
      icon: 'warning',
      title: `Disconnect ${CalendarType[0].toUpperCase() + CalendarType.substring(1)} Calendar`,
      html: '<h5>Your meeting link will stop working immediately.</h5>',
      showCancelButton: true,
      confirmButtonText: `Disconnect`,
      confirmButtonColor: '#E53A40',
      confirmButtonHtml: <Button text="Disconnect" variant="primary red" />,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await props.Axios({
            url: '/api/meet/disconnect',
            method: 'delete',
            data: {},
          });
          props.Toast.fire({
            icon: 'success',
            title: 'Meeting calendar disconnected.',
          });
          props.getMeetDetails();
        } catch (err) {
          if (err?.response?.data?.message)
            props.Toast.fire({
              icon: 'error',
              title: err.response.data.message,
            });
          else
            props.Toast.fire({
              icon: 'error',
              title: 'Internal server error.',
            });
        }
      }
    });
  };

  const changeWorkingHours = (item) => {
    setWorkingHours((oldWorkingHours) => {
      let workingHour = oldWorkingHours.find((w) => w.id === item.id);
      if (workingHour) {
        if (item.enabled) {
          let newWorkingHours = oldWorkingHours.map((hour) => {
            if (hour.id === item.id) hour = item;
            return hour;
          });
          return newWorkingHours;
        }
        let newWorkingHours = oldWorkingHours.filter(
          (hour) => hour.day !== workingHour.day
        );
        return [...newWorkingHours, { ...workingHour, enabled: false }];
      }
      return oldWorkingHours;
    });
  };

  const removeWorkingHours = (id) => {
    setWorkingHours((oldWorkingHours) => {
      return oldWorkingHours.filter((w) => w.id !== id);
    });
  };

  const addWorkingHours = (item) => {
    setWorkingHours((oldWorkingHours) => {
      return [...oldWorkingHours, item];
    });
  };

  const handleConflict = (id, name) => {
    if (CalendarConflict.find((item) => item.id === id))
      setCalendarConflict(CalendarConflict.filter((item) => item.id !== id));
    else setCalendarConflict([...CalendarConflict, { id, name }]);
  };

  const fetchDomains = async () => {
    try {
      let result = await props.Axios({
        url: '/api/domains?type=meet-link',
        method: 'get',
      });
      setDomains(result.data.data);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  const showEditDomainDialog = (id) => {
    for (let _domain of domains) {
      if (_domain._id === id) {
        setShowCreateDomain(true);
        return setEditDomain(_domain);
      }
    }
  };

  const deleteDomains = async (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      html: '<h5>Domain will be deleted?</h5>',
      showCancelButton: true,
      confirmButtonText: `Disconnect`,
      confirmButtonColor: '#E53A40',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await props.Axios({
            url: `/api/domains/${id}`,
            method: 'delete',
            data: {},
          });
          fetchDomains();
        } catch (err) {}
      }
    });
  };

  const workingHoursView = (day) => {
    let workingHours = WorkingHours.filter((item) => item.day === day);
    if (workingHours.length === 0) {
      let item = {
        id: `i-${Math.floor(Math.random() * 1000)}`,
        day,
        enabled: true,
        start: 9,
        end: 18,
      };
      addWorkingHours(item);
      return (
        <DayRange
          day={day}
          onChange={changeWorkingHours}
          workingHour={item}
          onAdd={addWorkingHours}
        />
      );
    }
    return workingHours.map((item, i) => {
      if (i === 0)
        return (
          <DayRange
            key={day}
            day={day}
            onChange={changeWorkingHours}
            workingHour={item}
            onAdd={addWorkingHours}
          />
        );
      else
        return (
          <DayRange
            key={day}
            day={day}
            onChange={changeWorkingHours}
            workingHour={item}
            onRemove={removeWorkingHours}
          />
        );
    });
  };

  function updateCurrentCalendar(calendarName) {
    setCalendar(calendarName);

    // remove the new calendar name from check conflict list
    setCalendarConflict((previousState) =>
      previousState.filter((conflict) => conflict.id !== calendarName)
    );
  }

  useEffect(() => {
    const headings = document.getElementsByClassName('csSettingsContentHeader');

    const intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        const name = entry.target.dataset.name;

        if (entry.isIntersecting) {
          // add it's name
          setSidebarSelected((prev) => {
            if (prev.includes(name)) return prev;

            return [...prev, entry.target.dataset.name];
          });
        } else if (!entry.isIntersecting) {
          // removed it's name
          setSidebarSelected((prev) => {
            if (prev.includes(name))
              return prev.filter((item) => item !== name);

            return prev;
          });
        }
      },
      { threshold: 1 }
    );

    for (const heading of headings) intersectionObserver.observe(heading);

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  const [sidebarSelected, setSidebarSelected] = useState([]);

  const fetchTimezoneLists = async (inputValue) => {
    try {
      let result = await axios.get(
        `/api/public/timezone?search=${inputValue}`
      );
      const data = result?.data?.data;
      setTimezoneList(data);
      return data;
    } catch (err) {
      console.log('Error in Fetching lists ', err);
      return [];
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Calendar Settings | ${whitelabelDetails?.agencyName}`}</title>
      </Helmet>
      {showCreateDomain && (
        <DomainEditor
          selected={editDomain}
          onClose={() => {
            setShowCreateDomain(false);
            fetchDomains();
          }}
        />
      )}
      {showCalendarSelect && (
        <CustomModal title="Select A Calendar" height={300}>
          <p className="text-modal-description">
            {`Events with ${whitelabelDetails?.agencyName} scheduler will be saved in this calendar.`}
          </p>
          <Row className="mt-4 ml-3">
            <Col md={12}>
              {CalendarType === 'google' && (
                <Select
                  options={[
                    { id: '', summary: 'Select Google Calendar' },
                  ].concat(props.calenders)}
                  value={Calendar}
                  onChange={(n, value) => setCalendar(value)}
                  valueField="id"
                  labelField="summary"
                />
              )}
              {CalendarType === 'outlook' && (
                <Select
                  options={[{ id: '', name: 'Select Outlook Calendar' }].concat(
                    props.calenders
                  )}
                  value={Calendar}
                  onChange={(n, value) => setCalendar(value)}
                  valueField="id"
                  labelField="name"
                />
              )}
            </Col>
            <Col md={12}>
              <div style={{ width: '100%', marginTop: 20 }}>
                <Button
                  style={{ marginInline: 'auto 3px' }}
                  variant="primary"
                  action={() => {
                    if (Calendar === '')
                      return props.Toast.fire({
                        icon: 'error',
                        title: 'Calendar is required.',
                      });
                    updateData();
                  }}
                  text="Save Calendar"
                />
              </div>
            </Col>
          </Row>
        </CustomModal>
      )}
      <div className="csRow">
        <SettingsSidebar
          sections={[
            {
              label: 'My Meeting Link',
              icon: <UilCalendarAlt size={20} />,
              ref: calendarLinkRef,
            },
            {
              label: 'General Settings',
              icon: <UilSetting size={20} />,
              ref: generalSettingsRef,
            },
            {
              label: 'Working Hours',
              icon: <UilClock size={20} />,
              ref: workingHoursRef,
            },
            {
              label: 'Attendees List',
              icon: <UilListUl size={20} />,
              ref: attendeesRef,
            },
            {
              label: 'Booking Form',
              icon: <UilSubject size={20} />,
              ref: bookingFormRef,
            },
            {
              label: 'Email Notification',
              icon: <UilBell size={20} />,
              ref: emailNotificationsRef,
            },
            {
              label: 'Email Reminder',
              icon: <UilEnvelopeRedo size={20} />,
              ref: emailReminderRef,
            },
            {
              label: 'Confirmation Redirect',
              icon: <UilExternalLinkAlt size={20} />,
              ref: confirmationPageRef,
            },
            {
              label: `${CalendarType === 'google' ? 'Google' : ''}${
                CalendarType === 'outlook' ? 'Outlook' : ''
              } Calendar`,
              icon:
                CalendarType === 'google' ? (
                  <UilGoogle size={20} />
                ) : (
                  <UilCalendarAlt size={20} />
                ),
              ref: googleCalendarRef,
            },
          ]}
          saveCalendar={updateData}
          disconnectCalendar={disconnectCalendar}
          loading={loading}
          selected={sidebarSelected}
        />
        <div className="csSettingsContentArea">
          <div className="flex">
            <UilCalendarAlt size={30} style={{ marginTop: 2 }} />
            <h2
              ref={calendarLinkRef}
              className="csSettingsContentHeader ml-2"
              data-name="My Meeting Link"
            >
              My Meeting Link
            </h2>
            <UilQuestionCircle
              size={18}
              id={'your-meeting-scheduler'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#your-meeting-scheduler`} place={"right"}>
            Share your meeting link with anyone to help them book meetings with you.
            </Tooltip>
          </div>

          <div
            className="csSettingsContentSection meetingScheduler"
            data-name="My Meeting Link"
            style={{ width: '99%' }}
          >
            <div className="flex">
              <div style={{ fontSize: 16, fontWeight: 500 }}>
                My Meeting Link {slugEditable ? 'will be' : 'is'} live at
                this link:
              </div>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <button
                  style={{
                    border: `3px solid ${slugEditable ? '#3AC569' : '#F9C00C'}`,
                    padding: 5,
                    borderRadius: 5,
                    marginTop: '-10px',
                  }}
                  onClick={() => {
                    if (slugEditable) updateData();
                    setSlugEditable(!slugEditable);
                  }}
                  id="change-meeting-link"
                >
                  {slugEditable ? (
                    <UilCheck size={20} color="#3AC569" />
                  ) : (
                    <UilEdit size={20} color="#F9C00C" />
                  )}
                </button>
                <Tooltip anchorSelect={`#change-meeting-link`} place={"top"}>
                  {slugEditable ? "Save Updated Link" : "Edit Meeting Link"}
                </Tooltip>
              </div>
            </div>
            <div className="mt-3 mb-3">
              {!slugEditable ? (
                <h1 style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <div style={{ fontSize: 24, color: '#3a3a3a' }}>
                    https://meet.salesblink.io/{MeetingSlug}
                  </div>
                  <div
                    onClick={() => {
                      window.navigator.clipboard.writeText(
                        `https://meet.salesblink.io/${MeetingSlug}`
                      );
                      props.Toast.fire({
                        icon: 'success',
                        title: 'Meeting link copied successfully',
                      });
                    }}
                    id="copy-meeting-link"
                  >
                    <UilClipboardBlank
                      style={{
                        border: '1px solid #03A6FF',
                        color: '#03A6FF',
                        borderRadius: 5,
                        width: 22,
                        height: 22,
                        cursor: 'pointer',
                        padding: 2,
                      }}
                    />
                  </div>
                  <Tooltip anchorSelect={`#copy-meeting-link`} place={"top"}>
                    Copy Meeting Link
                </Tooltip>
                </h1>
              ) : (
                <h1 style={{ fontSize: 24, color: '#3a3a3a' }}>
                  <a
                    href={`#${MeetingSlug}`}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span style={{ margin: 'auto 0', marginRight: 10 }}>
                      https://meet.salesblink.io/
                    </span>
                    <TextInput
                      value={MeetingSlug}
                      style={{ height: 35 }}
                      onChange={(n, value) =>
                        setMeetingSlug(
                          value
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[-]+/g, '-')
                            .replace(/[^\w-]+/g, '')
                        )
                      }
                      autoFocus
                    />
                  </a>
                </h1>
              )}
            </div>

            <div style={{ display: 'flex' }}>
              <div className="flex" style={{ paddingTop: 15 }}>
                <input
                  type="checkbox"
                  style={{ marginRight: 10 }}
                  checked={useCustomDomain}
                  onChange={(e) => setUseCustomDomain(!useCustomDomain)}
                />
                <label style={{ fontSize: 14, marginTop: 2, display: 'flex' }}>
                  Use your domain/subdomain 
                  <UilQuestionCircle
                    size={18}
                    id={"use-your-domain"}
                    style={{ marginLeft: 5, marginTop: 3, cursor: 'pointer' }}
                  />
                </label>
                <Tooltip anchorSelect={`#use-your-domain`} place={"right"}>
                  Connect your domain/subdomain & use it as meeting scheduler link.
                </Tooltip>
              </div>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                {useCustomDomain && (
                  <CustomSelect
                    edit={(id) => {
                      showEditDomainDialog(id);
                    }}
                    new={() => {
                      setShowCreateDomain(true);
                    }}
                    onChange={(selectedD) => {
                      setSelectedDomain(selectedD.value);
                      setCustomMeetingSlug(selectedD.label);
                    }}
                    customClearAction={() => {
                      setSelectedDomain('');
                      setCustomMeetingSlug('');
                    }}
                    onDelete={(itemName) => {
                      deleteDomains(itemName);
                    }}
                    default={{ label: 'Select Domain', value: '' }}
                    options={domains.map((_domain) => ({
                      label: _domain.domain,
                      value: _domain._id,
                    }))}
                    style={{ width: '100%', marginLeft: 'auto' }}
                    selected={
                      selectedDomain
                        ? domains
                            .map((_domain) => ({
                              label: _domain.domain,
                              value: _domain._id,
                            }))
                            .find((sel) => sel.value === selectedDomain)
                        : { label: 'Select Domain', value: '' }
                    }
                    icon={<UilGlobe />}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex">
            <UilSetting size={30} style={{ marginTop: 2 }} />
            <h2
              ref={generalSettingsRef}
              className="csSettingsContentHeader ml-2"
              data-name="General Settings"
            >
              General Settings
            </h2>
            <UilQuestionCircle
              size={18}
              id={'general-settings'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#general-settings`} place={"right"}>
              Customize how your meeting scheduler looks.
            </Tooltip>
          </div>
          <div
            className="csSettingsContentSection"
            style={{ paddingLeft: 5 }}
            data-name="General Settings"
          >
            <div className="csRow mb-5">
              <div className="csCol">
                <MeetCustomLabel
                  title="Meeting Description"
                  id="meeting-description"
                  info="Appears on your calendar link."
                />
                <TextInput
                  type="textarea"
                  value={Description}
                  onChange={(n, value) => setDescription(value)}
                />

                <MeetCustomLabel title="Meeting Location" />
                {CalendarType === 'google' ? (
                  <Select
                    options={[
                      { value: 'Google Meet', label: 'Google Meet' },
                      { value: 'custom', label: 'Custom Location' },
                    ]}
                    value={Location}
                    onChange={(n, value) => {
                      setCustomLocation('');
                      setLocation(value);
                    }}
                  />
                ) : null}
                {CalendarType === 'outlook' ? (
                  <Select
                    options={[
                      { value: 'Microsoft Teams', label: 'Microsoft Teams' },
                      { value: 'custom', label: 'Custom Location' },
                    ]}
                    value={Location}
                    onChange={(n, value) => {
                      setCustomLocation('');
                      setLocation(value);
                    }}
                  />
                ) : null}
                {Location === 'custom' ? (
                  <TextInput
                    type="text"
                    value={CustomLocation}
                    onChange={(n, value) => setCustomLocation(value)}
                    placeholder="Custom Location"
                  />
                ) : null}

                {/* custom meeting name */}
                <MeetCustomLabel
                  title="Custom Meeting Name"
                  info="Your calender will appear with this name."
                  id="custom-name"
                  position="top"
                />
                <TextInput
                  value={CustomName}
                  onChange={(n, value) => setCustomName(value)}
                  style={{ width: 335 }}
                  placeholder="Custom Name"
                />
              </div>
              <div className="csCol">
                {CalendarType === 'google' && (
                  <MeetCustomLabel
                    title="Color of Meeting"
                    id="color-of-meeting"
                    info="New Events in Calendar are saved in this color."
                  />
                )}
                {CalendarType === 'google' && (
                  <CirclePicker
                    colors={[
                      '#a4bdfc',
                      '#7ae7bf',
                      '#dbadff',
                      '#ff887c',
                      '#fbd75b',
                      '#ffb878',
                      '#46d6db',
                      '#e1e1e1',
                      '#5484ed',
                      '#51b749',
                      '#dc2127',
                      '#ff8a5c',
                    ]}
                    color={EventColor}
                    onChange={(color, event) => setEventColor(color.hex)}
                  />
                )}
                <br />

                <MeetCustomLabel
                  title="My TimeZone"
                  id="your-timezone"
                  info="Your current TimeZone."
                />
                <div style={{ marginTop: 5 }}>
                  <AsyncSelect
                    styles={{
                      control: () => ({
                        padding: '5px 1px',
                        minWidth: 350,
                        width: "100%",
                        maxWidth: 500,
                        borderRadius: 5,
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        border: '1px solid #D7D7D7',
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                      }),
                    }}
                    className="bg-gray-100 hover:bg-gray-200 mb-2 mr-2 text-gray-800 rounded-full inline-flex"
                    placeholder="Select a timezone"
                    defaultOptions={timezoneList}
                    value={(function () {
                      if(!MyTimezone) return null;
                      if(MyTimezone.includes('/')) return { label: MyTimezone, value: MyTimezone }
                      let timezoneInfo = timezones.find(t => t.id === MyTimezone)
                      if(!timezoneInfo) return null;
                      return { label: timezoneInfo.utc[0], value: timezoneInfo.utc[0] }
                    })()}
                    menuPortalTarget={document.body}
                    loadOptions={fetchTimezoneLists}
                    onChange={(values, currentValue) => setMyTimezone(values.value)}
                    noOptionsMessage={() => 'No Results, Type to Search'}
                  />
                </div>

                <MeetCustomLabel
                  title="Custom Meeting Avatar"
                  info="Your calender will appear with this image."
                  id="custom-image"
                />

                <div className="custom-profile-image-dashboard">
                  <img
                    className="custom-profile-image-dashboard-image"
                    src={CustomProfileImage}
                  />
                  <span
                    className="custom-profile-image-dashboard-image-edit"
                    onClick={() => {
                      setShowCustomProfileImageModal(true);
                    }}
                  >
                    <UilPen />
                  </span>
                  {showCustomProfileImageModal && (
                    <CustomModal
                      close={true}
                      onClose={() => {
                        setShowCustomProfileImageModal(false);
                        setCustomProfileImage(
                          props.meetData.custom_profile_image ||
                            props.meetData.profile_image
                        );
                      }}
                      width={500}
                      title="Upload a custom image"
                    >
                      {CustomProfileImage && (
                        <div className="attachmentItem">
                          <div className="attachmentItemContent">
                            <div>
                              <img
                                id="custom-profile-image"
                                height={200}
                                width={200}
                                src={(() => {
                                  if (CustomProfileImage instanceof File) {
                                    return URL.createObjectURL(
                                      CustomProfileImage
                                    );
                                  }

                                  return CustomProfileImage;
                                })()}
                                style={{
                                  margin: 'auto',
                                  backgroundPosition: 'center center',
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <button className='flex justify-center items-center' onClick={() => setCustomProfileImage(null)}>
                              <UilTimes />
                            </button>
                          </div>
                        </div>
                      )}
                      <MyDropzone
                        items={CustomProfileImage}
                        setItems={setCustomProfileImage}
                      />
                      <CustomButton
                        title="Save"
                        variant="primary"
                        style={{ margin: '1rem 3px 3px auto' }}
                        onClick={async function saveCustomImage() {
                          if (CustomProfileImage instanceof File) {
                            const formData = new FormData();
                            formData.append('profileImage', CustomProfileImage);

                            try {
                              const response = await axios.post(
                                '/api/meet/custom-image',
                                formData
                              );
                              props.Toast.fire({
                                icon: 'success',
                                title: 'Image updated.',
                              });

                              setCustomProfileImage(response.data.url);
                              props.setMeetData({
                                ...props.meetData,
                                custom_profile_image: response.data.url,
                              });
                            } catch (error) {
                              props.Toast.fire({
                                icon: 'error',
                                title: "Coudn't save image.",
                              });
                              setCustomProfileImage(
                                props.meetData.custom_profile_image ||
                                  props.meetData.profile_image
                              );
                            }
                          } else {
                            // set the old image as profile
                            setCustomProfileImage(
                              props.meetData.custom_profile_image ||
                                props.meetData.profile_image
                            );
                          }
                          // close the modal
                          setShowCustomProfileImageModal(false);
                        }}
                      />
                    </CustomModal>
                  )}
                </div>
              </div>
            </div>

            <div style={{ paddingLeft: 20 }}>
              <h3
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  cursor: 'pointer',
                  display: 'flex',
                }}
                onClick={() =>
                  setAdvancedMeetingSettings(!advancedMeetingSettings)
                }
              >
                Advanced Meeting Settings
                {advancedMeetingSettings ? (
                  <UilAngleUp style={{ marginLeft: 10 }} />
                ) : (
                  <UilAngleDown
                    style={{ marginLeft: 10 }}
                  />
                )}
              </h3>
              <br />

              {advancedMeetingSettings && (
                <Row>
                  <Col md={4}>
                    <MeetCustomLabel
                      title="Buffer After Scheduled Meetings"
                      id="buffer-time"
                      info="Gives you time between meetings that are scheduled already."
                      position="top"
                    />
                    <Select
                      options={[
                        { value: '15', label: '15 minutes' },
                        { value: '30', label: '30 Minutes' },
                        ,
                        { value: '60', label: '60 Minutes' },
                      ]}
                      value={BufferTime}
                      onChange={(n, value) => setBufferTime(value)}
                    />
                  </Col>
                  <Col md={4}>
                    <MeetCustomLabel title="Date Range" />
                    <div className="flex" style={{ marginTop: 8 }}>
                      <NumberInput
                        value={dateRange}
                        style={{ width: 220 }}
                        width={155}
                        onChange={(value) => {
                          if (value <= 1) return setDateRange(1);
                          if (value >= 100) return setDateRange(100);
                          setDateRange(value);
                        }}
                      />
                      <span
                        style={{
                          marginLeft: 10,
                          borderRadius: 5,
                          border: '1px solid #D7D7D7',
                          background: '#F9F9F9',
                          padding: '12px 24px',
                        }}
                      >
                        Days
                      </span>
                    </div>
                  </Col>
                  <Col md={4}>
                    <MeetCustomLabel
                      title="Daily Booking Frequency Limit"
                      id="daily-limit"
                      info="Max no. of meetings that can be booked in a day."
                    />
                    <NumberInput
                      value={DailyLimit}
                      style={{ marginTop: 9 }}
                      onChange={(value) => {
                        if (value <= 1) return setDailyLimit(0);
                        setDailyLimit(value);
                      }}
                    />
                  </Col>
                  <Col md={4} className="mt-4">
                    <MeetCustomLabel
                      title="Minimum Notice Before Meeting"
                      id="last-buffer"
                      info="People won't be able to book a meeting within the minimum notice."
                      position="top"
                    />
                    <div style={{ marginBottom: 15, display: 'flex' }}>
                      <div style={{ marginTop: 10 }}>
                        <NumberInput
                          style={{ width: 148 }}
                          value={LastMinuteBookingBuffer?.value}
                          onChange={(value) => {
                            if (value >= 1)
                              return setLastMinuteBookingBuffer({
                                ...LastMinuteBookingBuffer,
                                value,
                              });
                          }}
                        />
                      </div>
                      <div style={{ marginLeft: 13 }}>
                        <Select
                          options={[
                            { value: 'minutes', label: 'Minutes' },
                            { value: 'hours', label: 'Hours' },
                            { value: 'days', label: 'Days' },
                          ]}
                          value={LastMinuteBookingBuffer?.label}
                          onChange={(n, value) =>
                            setLastMinuteBookingBuffer({
                              ...LastMinuteBookingBuffer,
                              label: value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md={4} className="mt-4">
                    <MeetCustomLabel
                      title="Time Slot Duration"
                      id="slots"
                      info="This is how long your users will be able to schedule the meetings for."
                      position="top"
                    />
                    <Select
                      options={[
                        { value: '15', label: '15 minutes' },
                        { value: '30', label: '30 Minutes' },
                        { value: '60', label: '60 minutes' },
                        { value: '90', label: '90 minutes' },
                        { value: '120', label: '120 minutes' },
                      ]}
                      value={Slots}
                      onChange={(e, value) => setSlots(value)}
                    />
                  </Col>
                  <Col md={4} className="mt-4">
                    <MeetCustomLabel
                      title="Holidays"
                      id="holidays"
                      info="Select Days on which you are unavailable"
                    />
                    {Holidays.length > 0 && (
                      <Row
                        style={{
                          paddingLeft: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        {Holidays.map((item, i) => (
                          <Badge
                            text={item}
                            onClose={(e) =>
                              setHolidays(Holidays.filter((h) => h !== item))
                            }
                          />
                        ))}
                      </Row>
                    )}
                    <Row>
                      <Col md={10}>
                        <TextInput
                          type="date"
                          value={Holiday}
                          onChange={(n, value) => setHoliday(value)}
                        />
                      </Col>
                      <Col md={2}>
                        <CustomButton
                          title="Add"
                          variant="secondary"
                          style={{ marginLeft: '-20px' }}
                          onClick={() => {
                            if (Holiday.trim() === '')
                              return props.Toast.fire({
                                icon: 'error',
                                title: 'Holiday exists.',
                              });
                            setHolidays([...Holidays, Holiday]);
                            setHoliday('');
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </div>

          <div className="flex">
            <UilClock size={30} style={{ marginTop: 2 }} />
            <h2
              ref={workingHoursRef}
              className="csSettingsContentHeader ml-2"
              data-name="Working Hours"
            >
              Working Hours
            </h2>
            <UilQuestionCircle
              size={18}
              id={'working-hours'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#working-hours`} place={"right"}>
            Your meeting page will show these timings for bookings.
            </Tooltip>
          </div>
          <div className="csSettingsContentSection" data-name="Working Hours">
            <div className="csRow">
              <div className="csCol">
                {workingHoursView('Monday')}
                {workingHoursView('Tuesday')}
                {workingHoursView('Wednesday')}
                {workingHoursView('Thursday')}
                {workingHoursView('Friday')}
                {workingHoursView('Saturday')}
                {workingHoursView('Sunday')}
              </div>
            </div>
          </div>

          <div className="flex">
            <UilListUl size={30} style={{ marginTop: 2 }} />
            <h2
              ref={attendeesRef}
              className="csSettingsContentHeader ml-2"
              data-name="Attendees List"
            >
              Attendees List
            </h2>
            <UilQuestionCircle
              size={18}
              id={'attendees-list'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#attendees-list`} place={"right"}>
              {`Save meeting attendees in a ${whitelabelDetails?.agencyName} List and use the list in a sequence.`}
            </Tooltip>
          </div>

          <div
            className="csSettingsContentSection redirectUrl"
            data-name="Attendees List"
            style={{ width: "99%"}}
          >
            {/* <p>
              This is the URL your invitees will be automatically redirected to
              upon scheduling an event.
            </p> */}
            <div className='flex mb-2'>
              <div className='font-semibold' style={{ paddingTop: 20 }}>
                <label>Select List to save Attendees</label>
              </div>
              <div className="ml-auto">
                <CustomButton
                  title="New List"
                  icon={<UilPlusCircle />}
                  className="layout-reverse"
                  style={{ marginRight: 5 }}
                  variant="secondary"
                  onClick={() => window.open('/import-list/new', '_blank')}
                />
              </div>
            </div>
            <AsyncSelect
              styles={{
                control: () => ({
                  padding: 1,
                  width: 335,
                  borderRadius: 5,
                  backgroundColor: '#f9f9f9',
                  display: 'flex',
                  border: '1px solid #D7D7D7',
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999, // Adjust this value to be higher than the modal's zIndex
                }),
              }}
              className="bg-gray-100 hover:bg-gray-200 mt-2 mb-2 text-gray-800 rounded-full inline-flex"
              placeholder="Search for a list"
              value={List}
              defaultOptions={listData}
              isClearable
              menuPortalTarget={document.body}
              loadOptions={fetchList} // Function to fetch list options asynchronously
              getOptionValue={(option) => option?.id} // Define how to get the value of each option
              getOptionLabel={(option) => option?.name} // Define how to get the label of each option
              onChange={(values, currentValue) => {
                let newValue = null;
                if (values)
                  newValue = {
                    id: values.id,
                    name: values.name,
                  };
                setList(newValue);
              }}
              noOptionsMessage={() => 'No Results, Type to Search'}
            />
          </div>

          <div className="flex">
            <UilSubject size={30} style={{ marginTop: 2 }} />
            <h2
              ref={bookingFormRef}
              className="csSettingsContentHeader ml-2"
              data-name="Booking Form"
            >
              Booking Form Questions
            </h2>
            <UilQuestionCircle
              size={18}
              id={'booking-form-questions'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#booking-form-questions`} place={"right"}>
            Questions to ask before anyone can book a time with you.
            </Tooltip>
          </div>

          <div className="csSettingsContentSection" data-name="Booking Form">
            {/* <div className="csRow">
              You can customize the way your customer fill their information.
            </div> */}
            <div>
              <CustomFields
                fields={CustomQuestions}
                setFields={setCustomQuestions}
                completeViewOfSelectedList={completeViewOfSelectedList}
                list={List}
              />
            </div>
          </div>

          <div className="flex">
            <UilBell size={30} style={{ marginTop: 2 }} />
            <h2
              ref={emailNotificationsRef}
              className="csSettingsContentHeader ml-2"
              data-name="Email Notification"
            >
              Email Notification
            </h2>
            <UilQuestionCircle
              size={18}
              id={'email-notification'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#email-notification`} place={"right"}>
            This email is sent as soon as someone books a time with you.
            </Tooltip>
          </div>

          <div
            className="csSettingsContentSection"
            data-name="Email Notification"
          >
            <EmailNotification
              message={
                'Your invitees will receive an email notification whenever they schedule an appointment.'
              }
              reply={NotificationReplyTo}
              setReply={setNotificationReplyTo}
              subject={NotificationSubject}
              setSubject={setNotificationSubject}
              body={NotificationBody}
              setBody={setNotificationBody}
              completeViewOfSelectedList={completeViewOfSelectedList}
            />
          </div>

          <div className="flex">
            <UilEnvelopeRedo size={30} style={{ marginTop: 2 }} />
            <h2
              ref={emailReminderRef}
              className="csSettingsContentHeader ml-2"
              data-name="Email Reminder"
            >
              Email Reminder for Meeting
            </h2>
            <UilQuestionCircle
              size={18}
              id={'email-reminder-for-meeting'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#email-reminder-for-meeting`} place={"right"}>
                Reminder email for the person who has booked the meeting with you.
            </Tooltip>
          </div>

          <div className="csSettingsContentSection" data-name="Email Reminder">
            {/* Your attendee will receive an email reminder whenever your meeting
            is about to happen */}
            <Label text="Turn reminder:" />
            <div className="csRow">
              <Radio
                option={{ value: 'on', label: 'On' }}
                name="email-reminder"
                checked={EmailReminder}
                onChange={(i, checked, value) => setEmailReminder(true)}
              />
              <Radio
                option={{ value: 'off', label: 'Off' }}
                style={{ marginLeft: 20 }}
                name="email-reminder"
                checked={!EmailReminder}
                onChange={(i, checked, value) => setEmailReminder(false)}
              />
              {/* <Switch
                default={EmailReminder}
                onChange={(name, value) => setEmailReminder(value)}
              /> */}
            </div>
            {EmailReminder && (
              <div style={{ display: 'flex' }}>
                <div style={{ marginTop: 10 }}>
                  <NumberInput
                    style={{ width: 114 }}
                    value={ReminderTime?.value}
                    onChange={(value) => {
                      if (value >= 1)
                        setReminderTime({ ...ReminderTime, value });
                    }}
                  />
                </div>
                <div style={{ marginLeft: 15 }}>
                  <Select
                    options={[
                      { value: 'minutes', label: 'Minutes' },
                      { value: 'hours', label: 'Hours' },
                      { value: 'days', label: 'Days' },
                    ]}
                    value={ReminderTime?.label}
                    onChange={(n, value) =>
                      setReminderTime({ ...ReminderTime, label: value })
                    }
                    selectStyle={{ width: 168 }}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <span className='ml-3 font-semibold'>ago</span>
                </div>
              </div>
            )}
            {EmailReminder && (
              <EmailNotification
                message={
                  'Your attendee will receive an email reminder whenever your meeting is about to happen.'
                }
                reply={ReminderReplyTo}
                setReply={setReminderReplyTo}
                subject={ReminderSubject}
                setSubject={setReminderSubject}
                body={ReminderBody}
                setBody={setReminderBody}
                completeViewOfSelectedList={completeViewOfSelectedList}
              />
            )}
          </div>

          <div className="flex">
            <UilExternalLinkAlt size={30} style={{ marginTop: 2 }} />
            <h2
              ref={confirmationPageRef}
              className="csSettingsContentHeader ml-2"
              data-name="Confirmation Redirect"
            >
              Confirmation Redirect
            </h2>
            <UilQuestionCircle
              size={18}
              id={'confirmation-redirect'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#confirmation-redirect`} place={"right"}>
            After meeting is booked, the person will be redirected to this link.
            </Tooltip>
          </div>

          <div
            className="csSettingsContentSection redirectUrl"
            data-name="Confirmation Redirect"
            style={{ width: "99%"}}
          >
            {/* <p>
              This is the URL your invitees will be automatically redirected to
              upon scheduling an event.
            </p> */}
            <TextInput
              label="Redirect URL"
              placeholder="Your Redirect URL"
              value={ConfirmationPage}
              onChange={(n, value) => setConfirmationPage(value)}
              style={{ width: 335 }}
            />
          </div>

          <div className="flex">
            {CalendarType === 'google' ? (
              <UilGoogle size={30} style={{ marginTop: 2 }} />
            ) : (
              <UilCalendarAlt size={30} style={{ marginTop: 2 }} />
            )}
            <h2
              ref={googleCalendarRef}
              className="csSettingsContentHeader ml-2"
              data-name={`${CalendarType === 'google' ? 'Google' : ''}${
                CalendarType === 'outlook' ? 'Outlook' : ''
              } Calendar`}
            >
              {CalendarType === 'google' && 'Google'}{' '}
              {CalendarType === 'outlook' && 'Outlook'} Calendar
            </h2>
            <UilQuestionCircle
              size={18}
              id={'google-outlook-calendar'}
              style={{ marginLeft: 6, marginTop: 8, cursor: 'pointer' }}
            />
            <Tooltip anchorSelect={`#google-outlook-calendar`} place={"right"}>
            Manage your meeting link connection with connected Calendar.
            </Tooltip>
          </div>

          <div
            className="csSettingsContentSection calendarConflict"
            data-name={`${CalendarType === 'google' ? 'Google' : ''}${
              CalendarType === 'outlook' ? 'Outlook' : ''
            } Calendar`}
            style={{ width: "99%"}}
          >
            <div className="csRow" style={{ marginLeft: -10 }}>
              <div className="csCol">
                <MeetCustomLabel
                  title="Check for Conflicts"
                  id="check-conflict"
                  info="Will ensure you are not double booked."
                />
                <div
                  className="mt-3"
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    gap: 10,
                  }}
                >
                  {/* {CalendarType === 'google' && (
                    <img
                      alt="Google Icon"
                      src={googleIcon}
                      style={{ height: 24, width: 24 }}
                    />
                  )}
                  {CalendarType === 'outlook' && (
                    <img
                      alt="Outlook Icon"
                      src={outlookIcon}
                      style={{ height: 24, width: 24 }}
                    />
                  )} */}
                  <div style={{ fontSize: 14 }}>
                    Currently checking for conflicts in{' '}
                    <span style={{ color: '#0080FF' }}>
                      {/* current calendar, google | outlook */}
                      {CalendarType === 'google' &&
                        props.calenders.find((c) => c.id === Calendar)?.summary}
                      {CalendarType === 'outlook' &&
                        props.calenders.find((c) => c.id === Calendar)?.name}

                      {/* rest of the calendars for conflicts */}
                      {(function () {
                        if (CalendarConflict.length) {
                          return CalendarConflict.map((item, i, arr) => {
                            // if (arr.length === 1) return item.name;
                            if (i === arr.length - 1)
                              return ` and ${item.name}`;
                            else return `, ${item.name}`;
                          });
                        }
                      })()}
                    </span>
                    .
                  </div>

                  <CustomButton
                    title="Update"
                    variant="secondary"
                    onClick={() => setShowModal(true)}
                  />

                  {showModal && (
                    <CustomModal
                      title="Check for Conflicts in the following calendars"
                      close={true}
                      onClose={() => setShowModal(false)}
                      width={300}
                    >
                      <div style={{ paddingLeft: 20 }}>
                        <Row className="mt-4 ml-3">
                          {props.calenders.map((cal) => (
                            <Col md={12} key={cal.id}>
                              <div style={{ marginBottom: 5 }}>
                                <input
                                  type="checkbox"
                                  className="csCheckbox"
                                  style={{ marginRight: 15 }}
                                  checked={
                                    CalendarConflict.find(
                                      (item) => item.id === cal.id
                                    ) || Calendar === cal.id
                                  }
                                  disabled={Calendar === cal.id}
                                  onChange={() =>
                                    handleConflict(
                                      cal.id,
                                      cal.summary || cal.name
                                    )
                                  }
                                />{' '}
                                {CalendarType === 'google' ? cal.summary : ''}
                                {CalendarType === 'outlook' ? cal.name : ''}
                              </div>
                            </Col>
                          ))}
                          {/*  */}

                          <Col>
                            <div style={{ fontSize: 14, marginTop: 10 }}>
                              Currently checking for conflicts in{' '}
                              <span style={{ color: '#0080FF' }}>
                                {/* current calendar, google | outlook */}
                                {CalendarType === 'google' &&
                                  props.calenders.find((c) => c.id === Calendar)
                                    ?.summary}
                                {CalendarType === 'outlook' &&
                                  props.calenders.find((c) => c.id === Calendar)
                                    ?.name}
                                {/* rest of the calendars for conflicts */}
                                {(function () {
                                  if (CalendarConflict.length) {
                                    return CalendarConflict.map(
                                      (item, i, arr) =>
                                        i === arr.length - 1
                                          ? ` and ${item.name}`
                                          : `, ${item.name}`
                                    );
                                  }
                                })()}
                              </span>
                              .
                            </div>
                            <Button
                              text="Done"
                              variant="primary"
                              style={{ margin: '1rem 3px 3px auto' }}
                              action={() => setShowModal(false)}
                            />
                          </Col>
                        </Row>
                      </div>
                    </CustomModal>
                  )}
                </div>

                <br />
                <MeetCustomLabel
                  title="Save new meetings to Calendar"
                  id="save-new-meetings-calendar"
                  info="New meetings will be created on this calendar."
                  style={{ marginTop: -15 }}
                />
                {CalendarType === 'google' && (
                  <div className="csRow" style={{ marginTop: -5 }}>
                    <Select
                      options={[
                        { id: '', summary: 'Select Google Calendar' },
                      ].concat(props.calenders)}
                      selectStyle={{ width: 335 }}
                      value={Calendar}
                      onChange={(n, value) => updateCurrentCalendar(value)}
                      valueField="id"
                      labelField="summary"
                    />
                  </div>
                )}
                {CalendarType === 'outlook' && (
                  <div className="csRow" style={{ marginLeft: -5 }}>
                    <div className="csCol">
                      <Select
                        options={[
                          { id: '', name: 'Select Outlook Calendar' },
                        ].concat(props.calenders)}
                        value={Calendar}
                        onChange={(n, value) => setCalendar(value)}
                        valueField="id"
                        labelField="name"
                      />
                    </div>
                    <div className="csCol"></div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* danger zone */}
          <div className="flex">
            <UilExclamationTriangle size={30} style={{ marginTop: 2 }} />
            <h2 className="csSettingsContentHeader ml-2">Danger Zone</h2>
          </div>

          <div
            className="csSettingsContentSection"
            style={{ borderColor: 'red', marginBottom: 100 }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                <h2 style={{fontWeight: 500, fontSize: 16}}>Disconnect <span style={{textTransform: "capitalize"}}>{CalendarType}</span> {`Calendar From ${whitelabelDetails?.agencyName}.`}</h2>
                <p style={{fontSize: 14}}>Your meeting link will stop working immediately.</p>
              </div>
              <CustomButton
                title="Disconnect"
                variant="primary red"
                onClick={disconnectCalendar}
                disabled={loading}
                style={{margin: 0}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function CustomFields(props) {
  const { whitelabelDetails } = useContext(WhitelabelContext);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [newField, setNewField] = useState({
    id: `i-${Math.floor(Math.random() * 1000)}`,
    type: 'short_text',
    field: '',
    question: '',
    required: 'optional',
  });

  const options = [
    { label: 'Short Text', value: 'short_text' },
    { label: 'Long Text', value: 'long_text' },
    { label: 'Number', value: 'number' },
    { label: 'Phone Number', value: 'phone_number' },
  ]

  function addCustomField() {
    props.setFields((oldFields) => [...oldFields, newField]);
    setNewField({
      id: `i-${Math.floor(Math.random() * 1000)}`,
      type: 'short_text',
      field: '',
      question: '',
      required: 'optional',
    });
  }

  function deleteItem(id) {
    props.setFields((oldFields) =>
      oldFields.filter((field) => field.id !== id)
    );
  }

  function updateField(id, key, value) {
    props.setFields((oldFields) => {
      let newFields = oldFields.map((field) => {
        if (field.id === id) {
          field[key] = value;
        }
        return field;
      });
      return newFields;
    });
  }

  function handleSort() {
    let _fields = [...props.fields];
    const draggedItemContent = _fields.splice(dragItem.current, 1)[0];
    _fields.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    props.setFields(_fields);
  }

  function onDragEnd(e, index) {
    handleSort();
  }

  return (
    <div className="csCustomFieldsContainer">
      <div style={{ display: 'flex', marginTop: 10 }}>
        <div style={{ marginTop: 25, marginRight: 15, width: 20 }}></div>
        <div style={{ marginTop: 10, width: 340 }} className='font-bold'>
          Question
        </div>
        <div style={{ marginTop: 10, width: 90 }} className='font-bold'>
          Required
        </div>
        <div style={{ marginTop: 10, width: 150 }} className='font-bold'>
          Type
        </div>
        {props.list && <div style={{ marginTop: 10, width: 180 }} className='font-bold'>
          {`Map ${whitelabelDetails?.agencyName} Variables`}
        </div>}
      </div>
      {props.fields?.map((field, index) => (
        <CustomFieldItem
          key={index}
          dragStart={(e) => (dragItem.current = index)}
          dragEnter={(e) => (dragOverItem.current = index)}
          dragEnd={(e) => onDragEnd(e, index)}
          onChange={updateField}
          id={field.id}
          type={field.type}
          required={field.required}
          question={field.question}
          options={options}
          field={field.field}
          list={props.list}
          onDelete={(id) => deleteItem(id)}
          completeViewOfSelectedList={props.completeViewOfSelectedList}
        />
      ))}

      <div style={{ display: 'flex', marginTop: 10 }}>
        <div
          className="csCustomFieldItemDrag"
          style={{ marginTop: 25, marginRight: 15 }}
        >
          <UilAlignJustify size={20} style={{ margin: 'auto' }} />
        </div>
        <div style={{ marginTop: 10, width: 330 }}>
          <TextInput
            value={newField?.question}
            style={{ width: '100%' }}
            onChange={(e, value) => {
              setNewField({ ...newField, question: value });
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, width: 80 }}>
          <input
            type="checkbox"
            className="csCheckbox"
            style={{ marginLeft: 10 }}
            checked={newField?.required === 'required'}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setNewField((oldChecked) => {
                if (isChecked) return {...oldChecked, required: "required" };
                return {...oldChecked, required: "optional" }
              });
            }}
          />
        </div>
        <div style={{ marginLeft: 15, width: 140 }}>
          <SimpleSelect
            styles={{
              control: () => ({
                padding: 0,
                width: '100%',
                height: "50px",
                marginTop: 10,
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #d7d7d7',
                fontSize: 14
              }),
            }}
            options={options}
            value={options.find(opt => opt.value === newField?.type)}
            onChange={(option) => setNewField({ ...newField, type: option.value })}
          />
        </div>
        {props.list && <div style={{ marginLeft: 15, width: 160 }}>
          <CreatableSelect
            styles={{
              control: () => ({
                padding: 0,
                width: '100%',
                height: "50px",
                marginTop: 10,
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #d7d7d7',
                // border: `1px solid ${
                //   props.mapError !== '' && props.mapError === columns[index]
                //     ? '#d14343'
                //     : '#D7D7D7'
                // }`,
                fontSize: 14
              }),
            }}
            placeholder="Start Typing"
            onChange={function (val) {
              setNewField({ ...newField, field: val.value })
            }}
            options={props.completeViewOfSelectedList.map(item => ({ value: item, label: item.replaceAll('_',' ') }))}
            value={(function () {
              // if there's some value then find it's corresponding label and value in fieldNames
              if (newField.field !== "") return { label: newField.field?.replaceAll('_',' '), value: newField.field };
              // returning null shows the placeholder in the select
              return null;
            })()}
          />
        </div>}
        <div>
          <CustomButton
            title="Add"
            variant="secondary"
            id="add-meeting-questions"
            onClick={() => addCustomField()}
            style={{ marginTop: 15, marginLeft: 10 }}
          />
        </div>
        <Tooltip anchorSelect={`#add-meeting-questions`}>
          Add Another
        </Tooltip>
      </div>
    </div>
  );
}

function CustomFieldOption(props) {
  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDragStart={props.dragStart}
      onDragEnter={props.dragEnter}
      onDragEnd={props.dragEnd}
      draggable
      className="csRow"
    >
      <div className="csCustomFieldButton csCustomFieldItemDrag">
        <UilAlignJustify size={20} style={{ margin: 'auto' }} />
      </div>
      <TextInput value={props.value} />
      <div
        onClick={() => props.onDelete(props.index)}
        className="csCustomFieldButton csCustomFieldItemRemove"
      >
        <UilTrashAlt  style={{ margin: 'auto' }} size={20}  />
      </div>
    </div>
  );
}

function CustomFieldItem(props) {
  const [options, setOptions] = useState(['']);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [requiredSelect, setRequiredSelect] = useState(['']);
  const listVariables = props.completeViewOfSelectedList.map(variable => ({label: variable.replaceAll("_", " "), value: variable}));

  useEffect(() => {
    setRequiredSelect(props.required);
  }, [props.required]);

  function addOption() {
    setOptions((oldOptions) => {
      let newOptions = [...oldOptions];
      newOptions.push('');
      return newOptions;
    });
  }

  function handleSort() {
    let _options = [...options];
    const draggedItemContent = _options.splice(dragItem.current, 1)[0];
    _options.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    setOptions(_options);
  }

  function onDragEnd(e, index) {
    handleSort();
  }

  function deleteItem(index) {
    let _options = [...options];
    _options = _options.splice(index, 1);
    setOptions(_options);
  }

  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDragStart={props.dragStart}
      onDragEnter={props.dragEnter}
      onDragEnd={props.dragEnd}
      draggable
    >
      <div style={{ display: 'flex', marginTop: 10 }}>
        <div
          className="csCustomFieldItemDrag"
          style={{ marginTop: 25, marginRight: 15 }}
        >
          <UilAlignJustify size={20} style={{ margin: 'auto' }} />
        </div>
        <div style={{ marginTop: 10 }}>
          <TextInput
            value={props.question}
            style={{
              width: 330,
              cursor: `${
                props.id === 'i-1' || props.id === 'i-2'
                  ? 'not-allowed' : "default"
              }`,
            }}
            id={props.id === 'i-1' || props.id === 'i-2' ? "non-editable-variables" : ""}
            disabled={props.id === 'i-1' || props.id === 'i-2'}
            onChange={(e, value) => {
              if(props.id !== 'i-1' && props.id !== 'i-2') props.onChange(props.id, 'question', value);
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, width: 80 }}>
          <input
            type="checkbox"
            className="csCheckbox"
            style={{ 
              marginLeft: 10,
              cursor: `${
                props.id === 'i-1' || props.id === 'i-2'
                  ? 'not-allowed' : "default"
              }`,
            }}
            checked={requiredSelect === 'required'}
            disabled={props.id === 'i-1' || props.id === 'i-2'}
            id={props.id === 'i-1' || props.id === 'i-2' ? "non-editable-variables" : ""}
            onChange={(e) => {
              if(props.id !== 'i-1' && props.id !== 'i-2') {
                const isChecked = e.target.checked;
                if(isChecked) props.onChange(props.id, 'required', 'required')
                else props.onChange(props.id, 'required', 'optional')
              }
            }}
          />
        </div>
        <div style={{ marginLeft: 15, width: 140 }}>
          <SimpleSelect
            styles={{
              control: () => ({
                padding: 0,
                width: '100%',
                height: "50px",
                marginTop: 10,
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #d7d7d7',
                fontSize: 14,
                cursor: `${
                  props.id === 'i-1' || props.id === 'i-2'
                    ? 'not-allowed' : "default"
                }`,
              }),
            }}
            id={props.id === 'i-1' || props.id === 'i-2' ? "non-editable-variables" : ""}
            options={props.options}
            value={props.options.find(opt => opt.value === props.type)}
            isDisabled={props.id === 'i-1' || props.id === 'i-2'}
            onChange={(option) => {
              if(props.id !== 'i-1' && props.id !== 'i-2') props.onChange(props.id, 'type', option.value)
            }}
          />
        </div>
        {props.list && <div style={{ marginLeft: 15, width: 160 }}>
          <CreatableSelect
            styles={{
              control: () => ({
                padding: 0,
                width: '100%',
                height: "50px",
                marginTop: 10,
                borderRadius: 5,
                display: 'flex',
                border: '1px solid #d7d7d7',
                cursor: `${
                  props.id === 'i-1' || props.id === 'i-2'
                    ? 'not-allowed' : "default"
                }`,
                fontSize: 14
              }),
            }}
            placeholder="Start Typing"
            isDisabled={props.id === 'i-1' || props.id === 'i-2'}
            onChange={function (val) {
              console.log(val)
              if(props.id !== 'i-1' && props.id !== 'i-2') props.onChange(props.id, 'field', val.value)
            }}
            options={listVariables}
            value={(function () {
              if(props.id === 'i-1') return { label: "First Name", value: 'First_Name' }
              if(props.id === 'i-2') return { label: "Email", value: 'Email' }
              // if there's some value then find it's corresponding label and value in fieldNames
              if (props.field && props.field !== "") return { label: props.field?.replaceAll('_',' '), value: props.field };
              // returning null shows the placeholder in the select
              return null;
            })()}
          />
        </div>}
        <div>
          {props.id !== 'i-1' && props.id !== 'i-2' && (
            <button
              onClick={() => props.onDelete(props.id)}
              className="customButton secondary red"
              id="delete-meeting-questions"
              style={{
                marginLeft: 20,
                marginTop: 12,
                padding: 11,
              }}
            >
              <UilTrash size={22} style={{ margin: 'auto' }} />
            </button>
          )}
          <Tooltip anchorSelect={`#delete-meeting-questions`}>
            Delete Question
          </Tooltip>
        </div>
      </div>

      <Tooltip anchorSelect={`#non-editable-variables`}>
        Default values can not be edited.
      </Tooltip>

      <div className="csCIOptionsContainer">
        {(props.type === 'checkboxes' ||
          props.type === 'radio_button' ||
          props.type === 'select_menu') && (
          <>
            {options.map((option, index) => (
              <CustomFieldOption
                index={index}
                onDelete={deleteItem}
                dragStart={(e) => (dragItem.current = index)}
                dragEnter={(e) => (dragOverItem.current = index)}
                dragEnd={(e) => onDragEnd(e, index)}
                value={option}
              />
            ))}
            <div
              onClick={() => addOption()}
              className="csCustomFieldsAddButton"
            >
              <UilPlus size={24} style={{ marginRight: 10 }} />
              Add an Option
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function MyDropzone({ setItems, maxSize, maxDimensions }) {
  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles[0].size > (maxSize || 400 * 1024))
      return Swal.fire({
        icon: 'error',
        title: `Oops! File should be smaller than ${maxSize ? 100 : 400} KB`,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });

    let MAX_WIDTH = 0;
    let MAX_HEIGHT = 0;
    
    if (maxDimensions) {
      MAX_WIDTH = maxDimensions.width;
      MAX_HEIGHT = maxDimensions.height;
    } else {
      MAX_WIDTH = 450;
      MAX_HEIGHT = 450;
    }

    const { width, height } = await getImageSize(
      URL.createObjectURL(acceptedFiles[0])
    );

    if (width > MAX_WIDTH || height > MAX_HEIGHT)
      return Swal.fire({
        icon: 'error',
        title: `Oops! Max file dimensions should be ${MAX_WIDTH} x ${MAX_HEIGHT}`,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });

    if (maxDimensions && maxDimensions?.width === 180 && height >= width)
      return Swal.fire({
        icon: 'error',
        title: `Oops! Width must be greater than height`,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066FF',
      });

    setItems(acceptedFiles[0]);
  }, []);

  let acceptedFileTypes = {};
  
  if (maxDimensions?.width === 64) {
    acceptedFileTypes = {
      'image/png': ['.png'],
      'image/x-icon': ['.ico'],
    }
  } else {
    acceptedFileTypes = {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
    }
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFileTypes,
  });

  return (
    <div className="attachmentDropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div>
          <div
            className="attachmentsFileDropper"
            style={{
              height: 170,
              border: '1px solid #D7D7D7',
              cursor: 'pointer',
              borderRadius: 5,
              textAlign: 'center',
              padding: '10px 25px',
              backgroundColor: '#f2f2f2',
              position: 'relative',
            }}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ display: 'block', margin: 'auto' }}>
                <UilUploadAlt size={28} />
              </span>
            </div>
            <p style={{ marginTop: 25, fontSize: 16, fontWeight: 600 }}>
              Drop the file here
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              border: '1px solid #D7D7D7',
              display: 'flex',
              cursor: 'pointer',
              borderRadius: 5,
              textAlign: 'center',
              padding: '10px 20px',
              backgroundColor: '#f2f2f2',
              height: 170,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ display: 'block', margin: 'auto' }}>
                <UilUploadAlt size={28} />
              </span>
            </div>
            <div>
              <p style={{ marginLeft: 10, fontSize: 16, fontWeight: 600 }}>
                Drag 'n' Drop an image.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
