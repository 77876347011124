import './index.scss'
import axios from 'axios';
import Swal from 'sweetalert2';
import {
    UilEdit,
    UilTrash,
    UilBuilding,
    UilToggleOn,
    UilToggleOff,
} from '@iconscout/react-unicons';
import { Col, Row } from 'react-grid-system';
import { Button, CustomTable, Dropdown, Header, TextInput } from 'components/lib';
import { useContext, useEffect, useState } from "react";
import CustomDialog from 'components/custom/customDialog';
import { Animate, AuthContext, Form} from "components/lib";
import { Tooltip } from 'react-tooltip';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

export function Workspace() {
    const [isOpen, setIsOpen] = useState(false);
    const [workspaceName, setWorkspaceName] = useState('');
    const [selectedAccountID, setSelectedAccountID] = useState('');
    const [isEditingOpen, setIsEditingOpen] = useState(false);
    const authContext = useContext(AuthContext);

    const editWorkspaceName = async () => {
        try {
            if(workspaceName.trim().length < 4) Toast.fire({ icon: 'error', title: 'The workspace name must be at least four characters long.' });
            await axios.put('/api/account/workspaces', { newWorkspaceName: workspaceName, accountID: selectedAccountID });
            window.location.reload();
        } catch (error) {
            return Toast.fire({ icon: 'error', title: error?.response?.data?.message });
        } finally {
            setIsEditingOpen(false);
            setWorkspaceName('');
            setSelectedAccountID('');
        }
    }

    const deleteWorkspace = async () => {
        try {
            const params = { accountID: selectedAccountID };
            const response = await axios.get('/api/account', { params });
            if(response?.data?.data?.parentAccount === null) {
                Toast.fire({ icon: 'error', title: 'Cannot delete the default workspace' });
                setIsOpen(false);
                return;
            }
            await axios.delete('/api/user/delete', { params });
            const account_id = JSON.parse(localStorage.getItem('user')).accounts[0].id;
            if(selectedAccountID === authContext.user.account_id) authContext.switchAccount(account_id);
            else window.location.reload();
        } catch (error) {
            return Toast.fire({ icon: 'error', title: error?.response?.data?.message });
        } finally {
            setIsOpen(false);
            setSelectedAccountID('');
        }
    }

    return (
        <div>
            <Animate>
                <div class="account-workspace">
                    <h1 className="heading">Workspace Settings</h1>
                    {isEditingOpen && (
                        <CustomDialog width={200}>
                            <h2 style={{ paddingLeft: 0 }} className="text-2xl text-modal-header">
                                Edit your Workspace Name
                            </h2>
                            <p style={{ paddingLeft: 0 }}>
                                Enter the name for the workspace
                            </p>
                            <TextInput 
                                value={workspaceName} 
                                style={{ width: '100%' }}
                                placeholder="Enter the name for the workspace..."
                                onChange={(name, value) => { setWorkspaceName(value) }}
                            />
                            <Row style={{ marginLeft: '-8px', marginRight: '-13px', justifyContent: 'space-between'}}>
                                <Col md={6}>
                                    <Button
                                        style={{ marginLeft: 'auto', marginRight: 5, width: '100%' }}
                                        text="Cancel"
                                        variant="secondary"
                                        action={() => {
                                            setWorkspaceName('');
                                            setIsEditingOpen(false);
                                        }}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Button
                                        style={{ marginLeft: 15, width: '100%' }}
                                        color="blue"
                                        variant="primary blue"
                                        text="Edit Workspace Name"
                                        action={editWorkspaceName}
                                    />
                                </Col>
                            </Row>
                        </CustomDialog>
                    )}
                    {isOpen && (
                        <CustomDialog width={200}>
                            <h2 className="text-2xl text-modal-header">
                                Delete your Workspace
                            </h2>
                            <p style={{ paddingLeft: 15 }}>
                                Are you sure you want to delete your workspace? You will lose all of
                                your data and this can not be undone.
                            </p>

                            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                                <Col md={6}>
                                    <Button
                                        style={{ marginLeft: 15, width: '100%' }}
                                        color="red"
                                        variant="primary red"
                                        text="Delete Workspace"
                                        action={deleteWorkspace}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Button
                                        style={{ marginLeft: 'auto', marginRight: 5, width: '100%' }}
                                        text="Cancel"
                                        variant="primary"
                                        action={() => {
                                            setIsOpen(false);
                                            setWorkspaceName('');
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CustomDialog>
                    )}
                    <CustomTable 
                        columns={[
                            {   Header: 'Name',
                                accessor: 'name',
                                Cell: ({ row }) => {
                                    const { original } = row;

                                    return <p className='capitalize'>{original.name}</p>
                                }
                            },
                            {
                                Header: 'Permission',
                                accessor: 'permission',
                                Cell: ({ row }) => {
                                    const { original } = row;

                                    return <p className='capitalize'>{original.permission}</p>
                                }
                            },
                            {
                                Header: 'Actions',
                                accessor: 'id',
                                disableSortBy: true,
                                Cell: ({ row }) => {
                                    const { original } = row;
                                    
                                    return (
                                        <div className='flex gap-4 items-center'>
                                            <div>
                                                { original.id === JSON.parse(localStorage.getItem('user')).account_id ? 
                                                    <div id='toggleOn'>
                                                        <UilToggleOn style={{ fill: '#10B981' }} size={32} />
                                                        <Tooltip anchorSelect="#toggleOn" place="top">Already on this Workspace</Tooltip>
                                                    </div> : 
                                                    <div id='toggleOff'>
                                                        <UilToggleOff onClick={() => { authContext.switchAccount(original.id) }} style={{ cursor: 'pointer', fill: '#E53A40'}} size={32} />
                                                        <Tooltip anchorSelect="#toggleOff" place="top">Switch to this Workspace</Tooltip>
                                                    </div>
                                                }
                                            </div>
                                            <div
                                                id='editWorkspace'
                                                style={{
                                                    color: original['permission'] === "owner" || original['permission'] === "admin" ? '#F9C00C' : "#a0a0a0",
                                                    border: '2px solid currentColor',
                                                    borderRadius: 5,
                                                    padding: 2,
                                                    cursor: original['permission'] === "owner" || original['permission'] === "admin" ? 'pointer' : 'default',
                                                }}
                                                onClick={() => {
                                                    if(original['permission'] === "owner" || original['permission'] === "admin") {
                                                        setIsEditingOpen(true);
                                                        setSelectedAccountID(original.id);
                                                        setWorkspaceName(original.name);
                                                    }
                                                }}
                                            >
                                                <UilEdit size={19} />
                                                {(original['permission'] === "owner" || original['permission'] === "admin") && <Tooltip anchorSelect="#editWorkspace" place="top">Edit workspace name</Tooltip>}
                                            </div>
                                            <div 
                                                id='deleteWorkspace'
                                                style={{
                                                    color: original['permission'] === "owner" ? '#E53A40' : "#a0a0a0",
                                                    border: '2px solid currentColor',
                                                    borderRadius: 5,
                                                    padding: 2,
                                                    cursor: original['permission'] === "owner" ? 'pointer' : 'default',
                                                }}
                                                onClick={() => {
                                                    if(original['permission'] === "owner") {
                                                        setIsOpen(true);
                                                        setSelectedAccountID(original.id);
                                                    }
                                                }}
                                            >
                                                <UilTrash size={19} />
                                                {original['permission'] === "owner" && <Tooltip anchorSelect="#deleteWorkspace" place="top">Delete workspace</Tooltip>}
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        ]}
                        data={JSON.parse(localStorage.getItem('user')).accounts}
                    />
                </div>
            </Animate>
        </div>
    )
}