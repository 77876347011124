import {
  UilArrowCircleRight,
  UilBoltAlt,
  UilChannel,
  UilCheckCircle,
  UilEnvelopeAlt,
  UilFilter,
  UilHourglass,
  UilMinusCircle,
  UilPauseCircle,
  UilTimesCircle,
  UilUserArrows,
  UilUserCheck,
  UilUserPlus,
} from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomModal from 'components/custom/customModal';
import {
  AddTaskTemplate,
  PageTitle,
  Radio,
  Button,
  Loader,
  WhitelabelContext,
} from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import { useFlowchartData } from './FlowchartContext';
import CreateTemplate from './createTemplate';
import CustomizeNode from './customizeNode';
import './editSequence.scss';

function CreateTemplateDialog(props) {
  return (
    <div className="csCreateTemplateDialog">
      <CreateTemplate
        {...props}
        // width="80%"
        marginTop={'-60px'}
        position="absolute"
      />
    </div>
  );
}

function CreateTaskTemplateDialog(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    // console.log("DATAT ", props.taskID)
    getTaskType();
  }, []);

  const getTaskType = async () => {
    try {
      const res = await Axios({
        url: '/api/task-type/' + props.taskID,
        method: 'get',
      });
      // console.log("TEST ", res.data.data);
      setData(res.data.data);
    } catch (err) {
      console.log('ERROR ', err);
    }
  };

  return (
    <CustomModal
      close={true}
      onClose={() => {
        props.onClose();
      }}
    >
      {data ? (
        <AddTaskTemplate
          onComplete={() => {
            props.onClose();
          }}
          data={data}
        />
      ) : <Loader />}
    </CustomModal>
  );
}

const NodeItem = (props) => {
  function borderColor() {
    if (props.node.group === 'outreach') return 'purple';
    if (props.node.group === 'action') return 'warning';
    if (props.node.group === 'condition') return 'primary';
    if (props.node.group === 'source') return 'pink';
    return '';
  }

  return (
    <div onClick={() => props.onClick()} className="dialogNodeItem">
      <div>
        {props.icon ? (
          <div className={`csSequenceIcon ${borderColor()}`}>
            {props.icon === 'unicons' ? (
              props.node?.image
            ) : (
              <></>
            )}
          </div>
        ) : null}
      </div>
      <div style={{ marginLeft: 10 }}>
        <h2 style={{ fontSize: 20, fontWeight: 500 }}>{props.label}</h2>
        <p style={{ fontSize: 14 }}>{props.desc}</p>
      </div>
    </div>
  );
};

const AddNodeDialog = ({ id, type }) => {
  const [sourceNodeGroup, setSourceNodeGroup] = useState(type);
  const [nodes, setNodes] = useState([]);
  const flowchartData = useFlowchartData();
  const [nodeTypeDialog, setNodeTypeDialog] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [taskItem, setTaskItem] = useState(null);
  const [templateItem, setTemplateItem] = useState(null);
  const [dataChanged, setDataChanged] = useState(0);
  const { whitelabelDetails } = useContext(WhitelabelContext);

  useEffect(() => {
    // console.log("SELECTED NODE ", flowchartData?.selectedNode)
    if (
      !flowchartData?.selectedNode ||
      flowchartData?.selectedNode?.data?.addType === 'source'
    ) {
      setSourceNodeGroup('entry');
    } else {
      if(flowchartData?.selectedNode?.parentNodeType === "source_connect") setSourceNodeGroup("source")
      else setSourceNodeGroup(flowchartData?.selectedNode?.parentNodeGroup);
    }
  }, []);

  useEffect(() => {
    if (flowchartData?.editTemplateID) getTemplate();
  }, [flowchartData?.editTemplateID]);

  const sourceNodes = [
    {
      name: 'Sources',
      items: [
        {
          key: 1,
          type: 'source_entry_list',
          group: 'source',
          label: 'Leads from List(s)',
          fields: ['lists'],
          desc: 'Connect multiple lists as source for this sequence.',
          icon: 'unicons',
          image: <UilUserPlus size={38} />,
        },
        // {
        //     key: 2,
        //     type: "source_entry_crmlist",
        //     group: "source",
        //     label: "Contact is added to CRM List",
        //     fields: ["integrations", "lists", "conditionType"],
        //     desc: "Connects to CRM Integration, When the selected List has a new contact, add it to campaign.",
        //     icon: "list"
        // },
        {
          key: 3,
          type: 'source_entry_condition',
          group: 'source',
          label: 'Segment by Events',
          fields: ['lists', 'conditions'],
          desc: 'Create a segment of leads who have engaged with emails previously.',
          icon: 'unicons',
          image: <UilUserCheck size={38} />,
        },
        {
          key: 4,
          type: 'source_entry_attribute',
          group: 'source',
          label: 'Segment of List',
          fields: ['lists', 'attribute'],
          desc: `Create a segment of leads which match ${whitelabelDetails?.agencyName} Variables.`,
          icon: 'unicons',
          image: <UilUserArrows size={38} />,
        },
        {
          key: 5,
          type: 'source_entry_integration',
          group: 'source',
          label: 'Lead from CRM Integration',
          fields: ['integrations'],
          desc: 'Pulls leads from your CRM integrations.',
          icon: 'unicons',
          image: <UilBoltAlt size={38} />,
        },
      ],
    },
  ];

  const outreachNodes = [
    {
      name: 'Outreach',
      items: [
        {
          key: 1,
          type: 'email',
          group: 'outreach',
          label: 'Cold Email',
          fields: ['email_templates', 'send_as'],
          desc: 'Send an email to a lead.',
          icon: 'unicons',
          image: <UilEnvelopeAlt size={38} />,
        },
        {
          key: 2,
          type: 'task',
          group: 'outreach',
          label: 'Task',
          fields: ['tasktype'],
          desc: 'Schedule a manual task.',
          icon: 'unicons',
          image: <UilCheckCircle size={38} />,
        },
      ],
    },
  ];

  const conditionNodes = [
    {
      name: 'Conditions',
      items: [
        {
          key: 1,
          type: 'delay',
          group: 'condition',
          label: 'Wait',
          fields: ['delay', 'delay_unit'],
          desc: 'Add a delay between blocks.',
          icon: 'unicons',
          image: <UilHourglass size={38} />,
        },
        {
          key: 2,
          type: 'ifelse',
          group: 'condition',
          label: 'If/Else (Rules)',
          desc: 'Route leads through the sequence based on events.',
          fields: ['conditions', 'delay', 'delay_unit'], // ['action_conditions', 'delay', 'delay_unit'],
          icon: 'unicons',
          image: <UilFilter size={38} />,
          handles: ['left', 'right'],
        },
        {
          key: 3,
          type: 'absplit',
          group: 'condition',
          label: 'A/B Split',
          desc: 'Equally split contacts into two separate flows.',
          icon: 'unicons',
          image: <UilChannel size={38} />,
          handles: ['left', 'right'],
        },
      ],
    },
  ];

  const actionNodes = [
    {
      name: 'Actions',
      items: [
        // {
        //     key: 1,
        //     type: "update_attribute",
        //     label: "Update Attribute",
        //     fields: ["lists","attribute"],
        //     group: "action",
        //     desc: "Update attributes based on conditions.",
        //     icon: "unicons",
        //     image: <UilUserCircle size={38} />,
        // },
        {
          key: 2,
          type: 'move_contact_to_list',
          label: 'Move Lead to List',
          fields: ['lists'],
          group: 'action',
          desc: 'Moves Lead to another list.',
          icon: 'unicons',
          image: <UilArrowCircleRight size={38} />,
        },
        {
          key: 3,
          type: 'remove_contact',
          label: 'Archive Lead',
          group: 'action',
          desc: 'Archives the lead in the same list.',
          icon: 'unicons',
          image: <UilTimesCircle size={38} />,
        },
        {
          key: 4,
          type: 'pause_campaign',
          label: 'End Sequence',
          group: 'action',
          desc: 'End sequence for a lead.',
          icon: 'unicons',
          image: <UilPauseCircle size={38} />,
        },
        {
          key: 5,
          type: 'unsubscribe_contact',
          label: 'Unsubscribe Lead',
          group: 'action',
          desc: 'Saves the lead to unsubscribe list, remove from this & other sequences.',
          icon: 'unicons',
          image: <UilMinusCircle size={38} />,
        },
      ],
    },
  ];

  const restrictBlock = (parentNodeType) => {
    let childNodeType = '';
    let childNodeGroup = '';
    if (flowchartData?.selectedNode?.type) {
      childNodeType = 'add';
      childNodeGroup = 'add';
    } else if (flowchartData?.selectedNode?.data?.edgeAdd) {
      let node = flowchartData?.nodes.find(
        (node) => node.id === flowchartData?.selectedNode?.data?.target
      );
      if (node) {
        childNodeType = node.type;
        childNodeGroup = node.group;
      }
    }
    // console.log("CHILD ", childNodeType, childNodeGroup)
    let outreachN = outreachNodes;
    let conditionN = conditionNodes;
    let actionN = actionNodes;
    if (
      parentNodeType === 'delay' ||
      parentNodeType === 'absplit' ||
      parentNodeType === 'ifelse'
    ) {
      if (
        childNodeGroup === 'add' &&
        childNodeType === 'add' &&
        parentNodeType !== 'delay'
      )
        return setNodes([...outreachN, ...actionN]);
      else if(parentNodeType === 'delay') return setNodes([...outreachN, { name: conditionN[0].name, items: [conditionN[0].items[1], conditionN[0].items[2]] } ])
      return setNodes([...outreachN]);
    } else if (childNodeGroup === 'condition') return setNodes([...outreachN]);
    if (childNodeGroup === 'add' && childNodeType === 'add')
      return setNodes([...outreachN, ...conditionN, ...actionN]);
    return setNodes([...outreachN, ...conditionN]);
  };

  useEffect(() => {
    // console.log(sourceNodeGroup)
    switch (sourceNodeGroup) {
      case 'entry':
        setNodes(sourceNodes);
        break;
      case 'source':
        setNodes(outreachNodes);
        break;
      case 'outreach':
        restrictBlock(flowchartData?.selectedNode?.parentNodeType);
        // setNodes([...outreachNodes, ...conditionNodes, ...actionNodes]);
        break;
      case 'condition':
        restrictBlock(flowchartData?.selectedNode?.parentNodeType);
        // setNodes([...outreachNodes, ...conditionNodes, ...actionNodes]);
        break;
    }
  }, [sourceNodeGroup]);

  const getEditableNode = (nodeType) => {
    const allTypes = [
      ...sourceNodes[0].items,
      ...outreachNodes[0].items,
      ...conditionNodes[0].items,
    ];
    let currentNode = allTypes.find((_node) => _node.type === nodeType);
    // console.log("TAKEOF ", currentNode)
    return currentNode;
  };

  useEffect(() => {
    if (!flowchartData.editNode) return;
    let currentNode = getEditableNode(flowchartData?.editNode?.type);
    setSelectedNode(currentNode);
    // console.log("CURR ", currentNode)
    setNodeTypeDialog(currentNode.group);
  }, [flowchartData.editNode]);

  function addCustomNode(condition, data, group) {
    if (group === 'source')
      flowchartData.addSourceNode(
        selectedNode?.type,
        selectedNode?.group,
        condition,
        data
      );
    else
      flowchartData.addNode(
        selectedNode?.type,
        selectedNode?.handles,
        false,
        selectedNode?.group,
        condition,
        data
      );
    setSelectedNode(null);
    setNodeTypeDialog(false);
  }

  function addEdgeCustomNode(condition, data, edgeData) {
    flowchartData.addEdgeNode(
      selectedNode?.type,
      selectedNode?.handles,
      selectedNode?.group,
      condition,
      data,
      edgeData
    );
    setSelectedNode(null);
    setNodeTypeDialog(false);
  }

  const getTemplate = async () => {
    if (!flowchartData?.editTemplateID) return false;
    try {
      const res = await Axios({
        url: '/api/template/' + flowchartData?.editTemplateID,
        method: 'get',
      });
      // console.log("RESP ", res.data.data)
      setTemplateItem(res.data.data);
    } catch (err) {
      setTemplateItem(null);
    }
  };

  return (
    <>
      {nodeTypeDialog && (
        <CustomModal
          close={true}
          onClose={() => {
            flowchartData.setEditNode(null);
            setSelectedNode(null);
            flowchartData.setDialogShowing(false);
          }}
        >
          <CustomizeNode
            dataChanged={dataChanged}
            selectedNode={selectedNode}
            onSave={(data, group, editNode) => {
              // console.log("CUST NOD ", data, group, editNode)
              if (!editNode) {
                if (flowchartData?.selectedNode?.data?.edgeAdd)
                  addEdgeCustomNode(
                    data.condition,
                    data,
                    flowchartData?.selectedNode?.data
                  );
                else addCustomNode(data.condition, data, group);
              } else {
                flowchartData.updateNode(editNode.id, data);
                flowchartData.setEditNode(null);
              }
              setSelectedNode(null);
              flowchartData.setDialogShowing(false);
            }}
          />
        </CustomModal>
      )}
      {!nodeTypeDialog && (
        <CustomModal
          close={true}
          onClose={() => flowchartData.setDialogShowing(false)}
          width={900}
        >
          <div style={{ padding: 10 }}>
            <div style={{ marginLeft: 15 }}>
              <PageTitle
                title={sourceNodeGroup === "entry" ? "Add a Source Block" : "Add Blocks"}
                description={sourceNodeGroup === "entry" ? "Pick a block & configure, any new leads that match rules will be added to sequence automatically." : "Click on a block to configure and add it in sequence."}
                tooltip={sourceNodeGroup === "entry" ? "Pick a block & configure, any new leads that match rules will be added to sequence automatically." : "Click on a block to configure and add it in sequence."}
              />
            </div>

            <hr className="mt-3 mb-3" />
            <div style={{ height: 500, overflowY: 'auto' }}>
              {nodes.map((nodeGroup, i) => (
                <div key={i}>
                  <h3
                    style={{
                      fontSize: 20,
                      fontWeight: 500,
                      marginLeft: 11,
                      cursor: 'default',
                    }}
                  >
                    {nodeGroup?.name}
                  </h3>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gridGap: 10,
                    }}
                  >
                    {nodeGroup?.items?.map((node) => (
                      <NodeItem
                        key={node.key}
                        node={node}
                        label={node.label}
                        icon={node.icon}
                        desc={node.desc}
                        onClick={() => {
                          setSelectedNode(node);
                          setNodeTypeDialog(true);
                          // flowchartData.addNode(node.type, node.handles, node.group === "source" ? true : false, node.group)
                        }}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CustomModal>
      )}
      {flowchartData.showCreateTemplate && (
        <CreateTemplateDialog
          selected={templateItem}
          onClose={() => {
            flowchartData.setShowCreateTemplate(false);
            flowchartData?.setEditTemplateID(null);
            setTemplateItem(null);
            setDataChanged((oldData) => oldData + 1);
          }}
        />
      )}
      {flowchartData?.showCreateTaskTemplate && (
        <CreateTaskTemplateDialog
          selectedNode={selectedNode}
          taskID={flowchartData?.taskType}
          onClose={() => {
            flowchartData.setShowCreateTaskTemplate(false);
          }}
        />
      )}
    </>
  );
};

export function RemoveNodeDialog() {
  const flowchartData = useFlowchartData();
  const [type, setType] = useState('yes');

  const onRemove = () => {
    console.log('REMOVE ', type, flowchartData.showRemoveDialog);
    if (type === 'both')
      flowchartData.removeBothNode(flowchartData.showRemoveDialog.id);
    else flowchartData.removeNode(flowchartData.showRemoveDialog.id, type);
    flowchartData.setShowRemoveDialog(null);
  };

  return (
    <>
      <CustomModal
        close={true}
        onClose={() => flowchartData.setShowRemoveDialog(null)}
      >
        <div style={{ padding: 10 }}>
          <h1 className="text-2xl text-modal-header">Confirm Remove</h1>
          <p className="text-modal-description">
            You may remove only one path or both.
          </p>
          <div style={{ paddingLeft: 10 }}>
            <Radio
              option={{ value: 'yes', label: `${flowchartData.showRemoveDialog?.type === "absplit" ? 'Remove "A/Left" path' : 'Remove the "Yes" path'}` }}
              name="deleteType"
              checked={type === 'yes'}
              onChange={(i, checked, value) => setType(value)}
            />
            <Radio
              option={{ value: 'no', label: `${flowchartData.showRemoveDialog?.type === "absplit" ? 'Remove "B/Right" path' : 'Remove the "No" path'}` }}
              name="deleteType"
              checked={type === 'no'}
              onChange={(i, checked, value) => setType(value)}
            />
            <Radio
              option={{ value: 'both', label: `Remove both paths` }}
              name="deleteType"
              checked={type === 'both'}
              onChange={(i, checked, value) => setType(value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: "2rem",
              justifyContent: 'end',
              width: '100%',
              gap: "2rem"
            }}
          >
            <Button
              variant="primary grey"
              action={() => flowchartData.setShowRemoveDialog(null)}
              text="Cancel"
            />
            <Button
              variant="primary red"
              action={() => onRemove()}
              text="Remove"
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default AddNodeDialog;
