import {
  UilAnalysis,
  UilCheck,
  UilCog,
  UilEdit,
  UilEnvelope,
  UilEnvelopeCheck,
  UilEnvelopeTimes,
  UilEnvelopeReceive,
  UilExternalLinkAlt,
  UilLink,
  UilFile,
  UilMessage,
  UilEnvelopeOpen,
  UilUsersAlt,
  UilListUl,
  UilClock,
  UilSync,
  UilHistory,
  UilAnalytics
} from '@iconscout/react-unicons';
import Axios from 'axios';
import CustomModal from 'components/custom/customModal';
import { Animate, CustomSubNav, PageTitle } from 'components/lib';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlowchartData } from './FlowchartContext';
import Flowchart from './flowchart';
import { Row } from 'react-grid-system';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import "./flowchartReport.scss";
import Percentage from 'components/percentage/Percentage';

export default function FlowChartReport() {
  const flowchartData = useFlowchartData();
  let { id } = useParams();
  const [sequence, setSequence] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState('');
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [RangeView, setRangeView] = useState('');
  let navigator = useNavigate();

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        fetchSequence();
        fetchSequenceContacts();
      }, 500)
    }
  }, [id]);

  useEffect(() => {
    let today = DateTime.now();
    setStartDate(today.plus({ days: -7 }).ts);
    setEndDate(today.ts);
  }, []);

  async function fetchSequence() {
    try {
      let result = await Axios.get(`/api/sequences/${id}`);
      flowchartData.setIsReportMode(true);
      let _sequence = result?.data?.data;
      // console.log("DATA ", flowchartData.sequenceData);
      flowchartData.setSequenceData({
        ...flowchartData.sequenceData,
        id,
        flowchartState: _sequence.flowchartState || {},
      });
      setSequence(_sequence);
      if (_sequence?.flowchart?.nodes) {
        let filteredNodes = _sequence?.flowchart?.nodes.filter((_node) => _node?.type !== 'add' && _node.type !== "add_source_block")
        filteredNodes = filteredNodes.map(_n => {
          if(_n.draggable) _n.draggable = false;
          return _n;
        })
        flowchartData.setNodes(filteredNodes);
      }
      if (_sequence?.flowchart?.edges)
        flowchartData.setEdges(
          _sequence?.flowchart?.edges.map((_edge) => {
            _edge.type = 'default';
            return _edge;
          })
        );
    } catch (err) {
      console.log('Error in fetching sequence ', err);
    }
  }

  async function fetchSequenceContacts() {
    try {
      let result = await Axios.get(`/api/sequence-contacts/${id}`);
      flowchartData.setSequenceData({
        ...flowchartData.sequenceData,
        total: result.data.data,
      });
    } catch (err) {
      console.log('Error in fetching sequence contacts ', err);
    }
  }

  const handleSelect = (ranges) => {
    console.log(ranges.selection);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);

    let start = DateTime.fromJSDate(ranges.selection.startDate).ts;
    let end = DateTime.fromJSDate(ranges.selection.endDate).ts;

    const startD = DateTime.fromJSDate(ranges.selection.startDate);
    const endD = DateTime.fromJSDate(ranges.selection.endDate);

    setRangeView(
      `From ${startD.toFormat('MM-dd-yyyy')} To ${endD.toFormat('MM-dd-yyyy')}`
    );
    flowchartData.setSequenceData({
      ...flowchartData.sequenceData,
      start,
      end,
    });
  };

  return (
    <div>
      <CustomSubNav
        selected={'sequences'}
        onChange={(selectedItem) => {
          let selectedPath = selectedItem.title.replace(' ', '-');
          navigator(`/outreach/${selectedPath}`);
        }}
        menuitems={[
          {
            icon: <UilMessage />,
            title: 'sequences',
          },
          {
            icon: <UilEnvelope />,
            title: 'outbox',
          },
          {
            icon: <UilListUl />,
            title: 'lists',
          },
          {
            icon: <UilCheck />,
            title: 'tasks',
          },
          {
            icon: <UilAnalysis />,
            title: 'reports',
          },
          {
            icon: <UilFile />,
            title: 'templates',
          },
          {
            icon: <UilEnvelope />,
            title: 'email senders',
          },
          {
            icon: <UilCog />,
            title: 'settings',
          },
        ]}
      />

      {flowchartData.dialogShowing && (
        <CustomModal
          close={true}
          width={300}
          title={flowchartData.editNode?.label || ""}
          onClose={() => {
            flowchartData.setEditNode(null);
            flowchartData.setDialogShowing(false);
          }}
        >
          {flowchartData.editNode && (
            <ReportModalContent 
              data={flowchartData.editNode}
              sequenceData={flowchartData.sequenceData}
              sequenceID={id}
              sequence={sequence}
            />
          )}
        </CustomModal>
      )}

      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 15 }}>
          <PageTitle
            title={<span style={{fontWeight: 400}}>Stats for <span style={{fontWeight: 500}}>{sequence?.name}</span></span>}
            description="Review step by step analytics for your sequence."
            tooltip="Review step by step analytics for your sequence."
          />
        </div>
        <div className="flex" style={{ marginLeft: 'auto' }}>
        <button
            id="stats"
            className="inline-block flex float-right mr-5 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
            type="button"
            style={{
              outline: '3px solid #97d8fb',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px 7px',
              outlineOffset: '-3px',
              backgroundColor: '#03A6FF',
	            // border: '2px solid #97d8fb',
            }}
            onClick={() => {
              // take user to the reports page and with this sequences pre-selected
              navigator(`/outreach/sequences/${id}/stats`)
            }}
          >
            <UilAnalytics
              size={25}
              style={{ marginTop: 3 }}
            />
          </button>
          <Tooltip anchorSelect='#stats' content='View Stats'/>

          <button
            id="reports"
            className="inline-block flex float-right mr-5 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
            type="button"
            style={{
              outline: '3px solid #ED317F',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px 7px',
              outlineOffset: '-3px',
            }}
            onClick={() => {
              // take user to the reports page and with this sequences pre-selected
              navigator(`/outreach/reports?sequence=${sequence?.id}&team=${sequence?.user_id}`)
            }}
          >
            <UilHistory
              size={25}
              style={{ marginTop: 3 }}
              fill="#ED317F"
            />
          </button>
          <Tooltip anchorSelect='#reports' content='View Sequence Logs'/>
          
          <button
            id="edit-sequence"
            className="inline-block flex float-right mr-5 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
            type="button"
            style={{
              outline: '3px solid #F9C00C',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px 7px',
              outlineOffset: '-3px',
            }}
            onClick={() => {
              // navigator(`/outreach/sequences/${id}`);
              window.location.href = `/outreach/sequences/${id}`;
            }}
          >
            <UilEdit
              size={25}
              style={{ marginTop: 3 }}
              fill="#F9C00C"
            />
          </button>
          <Tooltip anchorSelect='#edit-sequence' content='Edit Sequence' />

          <div style={{ width: 350 }}>
            <input
              type="text"
              placeholder={'Date Range'}
              className="rounded"
              style={{
                width: '100%',
                border: '1px solid #e5e5e5',
                padding: '7px 12px',
                borderRadius: 3,
              }}
              value={RangeView}
              onClick={(e) => setShowDatePicker(!showDatePicker)}
              onChange={(e) => {}}
            />
            {showDatePicker && (
              <Animate type="slidedown" timeout={50}>
                <div className="dropdownMenu" style={{ zIndex: 9999 }}>
                  <div
                    style={{
                      position: 'fixed',
                      inset: 0,
                      zIndex: -1,
                    }}
                    onClick={() => setShowDatePicker(false)}
                  ></div>
                  <div className="dropdownMenuBody">
                    <DateRangePicker
                      ranges={[
                        {
                          startDate: StartDate,
                          endDate: EndDate,
                          key: 'selection',
                        },
                      ]}
                      onChange={handleSelect}
                    />
                  </div>
                </div>
              </Animate>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          height: '72vh',
          border: '1px solid #e6e6e6',
          marginTop: 19,
          borderRadius: 6,
        }}
      >
        <Flowchart />
      </div>
    </div>
  );
}

const ReportModalContent = (props) => {
  const [opens, setOpens] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [replies, setReplies] = useState(0);
  const [bounces, setBounces] = useState(0);
  const [unsubscribed, setUnsubscribed] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(props.data) getData()
  }, [props.data])

  async function fetchLogsCount(type, start, end, nodeID, message) {
    try {
      let dateStr = '';
      let msg = '';
      if (start && end) dateStr = `&date=${start}-${end}`;
      if (message) msg = `&message=${message}`;
      const res = await Axios({
        url: `/api/logs?type=${type}&sequence=${props.sequenceID}&node=${
          nodeID + dateStr + msg
        }`,
        method: 'get',
      });
      return res?.data?.count;
    } catch (error) {
      console.log('ERROR: ', error);
      return 0;
    }
  }

  const getData = async () => {
    setLoading(true)
    try {
      let data = await Promise.all([
        await fetchLogsCount('open', props.sequenceData?.start, props.sequenceData?.end, props.data?.node),
        await fetchLogsCount('click', props.sequenceData?.start, props.sequenceData?.end, props.data?.node),
        await fetchLogsCount('reply', props.sequenceData?.start, props.sequenceData?.end, props.data?.node),
        await fetchLogsCount('bounce', props.sequenceData?.start, props.sequenceData?.end, props.data?.node),
        await fetchLogsCount('unsubscribed', props.sequenceData?.start, props.sequenceData?.end, props.data?.node)
      ])
      setOpens(data[0])
      setClicks(data[1])
      setReplies(data[2])
      setBounces(data[3])
      setUnsubscribed(data[4])
      setLoading(false)
    } catch (error) {setLoading(false)}
  }

  function putIcons(keyName) {
    const iconStyle = {
      border: '2px solid',
      padding: 5,
      borderRadius: 5,
    };

    switch (keyName) {
      case 'Leads':
        return (
          <UilUsersAlt
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#9EC7F1',
              backgroundColor: '#E8F0F8',
              color: '#0066ff',
            }}
          />
        );
      case 'Sent':
        return (
          <UilEnvelopeCheck
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#89DCA5',
              backgroundColor: '#D3EFDC',
              color: '#3AC569',
            }}
          />
        );
      case 'Scheduled':
        return (
          <UilClock
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#FBD355',
              backgroundColor: '#F3E8C4',
              color: '#F9C00C',
            }}
          />
        );
      case 'Outbox':
        return (
          <UilEnvelope
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#93CCF3',
              backgroundColor: '#EAF1F7',
              color: '#03A6FF',
            }}
          />
        );
      case 'Opens':
        return (
          <UilEnvelopeOpen
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#51E3D1',
              backgroundColor: '#E3F6F4',
              color: '#1BE1C9',
            }}
          />
        );
      case 'Clicks':
        return (
          <UilLink
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#B67AEC',
              backgroundColor: '#E3CCF8',
              color: '#8929E0',
            }}
          />
        );
      case 'Replies':
        return (
          <UilEnvelopeReceive
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#FDBD89',
              backgroundColor: '#FED3B0',
              color: '#FC913A',
            }}
          />
        );
      case 'Bounces':
        return (
          <UilEnvelopeTimes
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#F484B2',
              backgroundColor: '#FBD6E5',
              color: '#ED317F',
            }}
          />
        );
      case 'Unsubscribed':
        return (
          <UilEnvelopeTimes
            size={50}
            style={{
              ...iconStyle,
              borderColor: '#EF898C',
              backgroundColor: '#F5D3D4',
              color: '#E53A40',
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Row
      className="p-2"
      style={{
        gap: 16,
        justifyContent: 'center',
        marginInline: 'auto',
      }}
    >
      {Object.entries(props.data).map(([key, value]) => {
        if (key !== 'node' && key !== "label")
          return (
            <div className="report-modal-content-item-wrapper">
              <div>{putIcons(key)}</div>
              <div className="key-value">
                <span className="value">{value}</span>
                <span className='external-link'>
                  {['Sent', 'Scheduled', 'Outbox', 'Leads'].includes(key) && 
                  (function () {
                    if (key === 'Leads')
                      return <span style={{cursor: "default", fontWeight: 500}}>{key}</span>;
                    if (key === 'Outbox')
                      return (
                        <>
                          <Link
                            to={`/outreach/outbox?sequenceID=${props?.sequenceID}&nodeID=${props.data?.node}`}
                            target="_blank"
                            className="key csLink"
                          >
                            {key}
                          </Link>
                          <UilExternalLinkAlt />
                        </>
                      );

                    return (
                      <>
                        <Link
                          to={`/outreach/reports?sequence=${props.sequence?.id}&event=outreach&team=${props.sequence?.user_id}`}
                          target="_blank"
                          className="key csLink"
                        >
                          {key}
                        </Link>
                        <UilExternalLinkAlt />
                      </>
                    );
                  })()
                    }
                </span>
              </div>
            </div>
          );
      })}
      {/* clicks */}
      <div className="report-modal-content-item-wrapper">
        <div>{putIcons('Clicks')}</div>
        <div className="key-value">
          <span className="value">{loading ? <UilSync className={`rotate`} /> :  
          <>
          {clicks} <Percentage className={"percent"} value={(clicks / props.data.Sent) * 100}/>
          </>
          }</span>
          <span class="external-link">
            <Link to={`/outreach/reports?sequence=${props.sequence?.id}&event=click&team=${props.sequence?.user_id}`} target='_blank' className='key csLink'>Clicks</Link>
            <UilExternalLinkAlt />
          </span>
        </div>
      </div>
      {/* opens */}
      <div className="report-modal-content-item-wrapper">
        <div>{putIcons('Opens')}</div>
        <div className="key-value">
          <span className="value">{loading ? <UilSync className={`rotate`} /> : 
          (<>
            {opens} <Percentage className={"percent"} value={(opens / props.data.Sent) * 100}/>
          </>
          ) }</span>
          <span class="external-link">
            <Link to={`/outreach/reports?sequence=${props.sequence?.id}&event=open&team=${props.sequence?.user_id}`} target='_blank' className='key csLink'>Opens</Link>
            <UilExternalLinkAlt />
          </span>
        </div>
      </div>
      {/* replies */}
      <div className="report-modal-content-item-wrapper">
        <div>{putIcons('Replies')}</div>
        <div className="key-value">
          <span className="value">{loading ? <UilSync className={`rotate`} /> : 
            (<>
            {replies} <Percentage className={"percent"} value={(replies / props.data.Sent) * 100}/>
          </>
          )}</span>
          <span class="external-link">
            <Link to={`/outreach/reports?sequence=${props.sequence?.id}&event=reply&team=${props.sequence?.user_id}`} target='_blank' className='key csLink'>Replies</Link>
            <UilExternalLinkAlt />
          </span>
        </div>
      </div>
      {/* bounces */}
      <div className="report-modal-content-item-wrapper">
        <div>{putIcons('Bounces')}</div>
        <div className="key-value">
          <span className="value">{loading ? <UilSync className={`rotate`} /> : 
          (<>
            {bounces} <Percentage className={"percent"} value={(bounces / props.data.Sent) * 100}/>
          </>
          )}</span>
          <span class="external-link">
            <Link to={`/outreach/reports?sequence=${props.sequence?.id}&event=bounce&team=${props.sequence?.user_id}`} target='_blank' className='key csLink'>Bounces</Link>
            <UilExternalLinkAlt />
          </span>
        </div>
      </div>
      {/* bounces */}
      <div className="report-modal-content-item-wrapper">
        <div>{putIcons('Unsubscribed')}</div>
        <div className="key-value">
          <span className="value">{loading ? <UilSync className={`rotate`} /> : 
          (<>
            {unsubscribed} <Percentage className={"percent"} value={(unsubscribed / props.data.Sent) * 100}/>
          </>
          )}</span>
          <span class="external-link">
            <Link to={`/outreach/reports?sequence=${props.sequence?.id}&event=unsubscribed&team=${props.sequence?.user_id}`} target='_blank' className='key csLink'>Unsubscribed</Link>
            <UilExternalLinkAlt />
          </span>
        </div>
      </div>
    </Row>
  );
}