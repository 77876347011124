import {
  UilCheck,
  UilClock,
  UilTimes,
  UilDownloadAlt,
  UilEdit,
  UilEye,
  UilStar,
  UilArchive,
  UilPlusCircle,
  UilAngleDown,
  UilArrowLeft,
  UilAngleUp,
  UilRefresh,
  UilUserExclamation,
  UilPen,
  UilExternalLinkAlt,
  UilSync,
  UilGoogleDriveAlt
} from '@iconscout/react-unicons';
import { UisStar } from '@iconscout/react-unicons-solid';
import Axios from 'axios';
import CustomButton from 'components/custom/customButton';
import CustomDialog from 'components/custom/customDialog';
import CustomToggle from 'components/custom/customToggle';
import { CustomSelect } from 'components/customselect/customselect';
import {
  Animate,
  CustomDropdown,
  CustomSearch,
  CustomTable,
  Loader,
  TextInput,
  useNavigate,
  WhitelabelContext,
} from 'components/lib';
import { useContext, useEffect, useState } from 'react';
import { Col } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import './list.scss';
import ViewsEditor from './viewseditor';
import { DateTime } from 'luxon';
import axios from 'axios';

export default function List(props) {
  const [search, setSearch] = useState('');
  const [list, setList] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [showStarred, setShowStarred] = useState(false);
  const [contactsCount, setContactsCount] = useState(0);
  const [selectedView, setSelectedView] = useState('Complete View');
  const [columns, setColumns] = useState([]);
  const [checkedContacts, setCheckedContacts] = useState([]);
  const [editingView, setEditingView] = useState(null);
  const [listName, setListName] = useState('');
  const [editName, setEditName] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isSorted, setIsSorted] = useState('');
  const [isSortedDesc, setIsSortedDesc] = useState(false);
  const [loading, setLoading] = useState(false);
  const { whitelabelDetails } = useContext(WhitelabelContext);

  let { listID } = useParams();
  let navigator = useNavigate();

  useEffect(() => {
    let searchParams = new URLSearchParams();
    if (showArchived) searchParams.append('archived', true);
    if (showStarred) searchParams.append('starred', true);
    searchParams.append('sortBy', isSorted);
    searchParams.append('sortType', isSortedDesc ? 'DESC' : 'ASC');
    searchParams.append('search', search);
    searchParams.append('limit', perPage);
    searchParams.append('page', page);
    fetchContacts(searchParams.toString()); // eslint-disable-next-line
  }, [
    showArchived,
    showStarred,
    page,
    perPage,
    isSorted,
    isSortedDesc,
    search,
  ]);

  useEffect(() => {
    if (!listID) return;
    fetchList();
    // fetchContacts()
  }, [listID]);

  const archiveContact = (id, value) => {
    updateContact(id, { archived: value });
    setContacts((oldContacts) => {
      let newData = oldContacts.filter((oldContactsItem) => {
        if (id === oldContactsItem.id) return false;
        return oldContactsItem;
      });
      return newData;
    });
  };

  useEffect(() => {
    if (!list) return;

    let cols = [
      {
        Header: (
          <CustomDropdown
            type="select"
            checked={checkedContacts.length === contacts.length}
            indeterminate={
              checkedContacts.length > 0 &&
              checkedContacts.length < contacts.length
            }
            selectAction={() => {
              if (checkedContacts.length === contacts.length)
                setCheckedContacts([]);
              else if (
                checkedContacts.length > 0 &&
                checkedContacts.length < contacts.length
              )
                setCheckedContacts([]);
              else {
                let selectArr = [];
                for (let i = 0; i < contacts.length; i++) {
                  selectArr.push(contacts[i].id);
                }
                setCheckedContacts(selectArr);
              }
            }}
            items={[
              {
                title: !showStarred ? 'Star' : 'Unstar',
                icon: !showStarred ? (
                  <UisStar size={18} style={{ marginTop: 2, marginRight: 5 }} />
                ) : (
                  <UilStar size={18} style={{ marginTop: 2, marginRight: 5 }} />
                ),
                action: () => {
                  updateBatchContact(!showStarred ? 'star' : 'unstar');
                },
              },
              {
                title: !showArchived ? 'Archive' : 'Un-Archive',
                icon: showArchived ? (
                  <UilRefresh
                    size={18}
                    style={{ marginTop: 2, marginRight: 5 }}
                  />
                ) : (
                  <UilArchive
                    size={18}
                    style={{ marginTop: 2, marginRight: 5 }}
                  />
                ),
                action: () => {
                  updateBatchContact(!showArchived ? 'archive' : 'unarchive');
                },
              },
            ]}
            align="left"
          />
        ),
        Footer: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <input
              type="checkbox"
              className="csCheckbox"
              style={{ marginLeft: 10 }}
              checked={checkedContacts.includes(value)}
              onChange={(e) => {
                const isChecked = e.target.checked;
                setCheckedContacts((oldChecked) => {
                  if (isChecked) return [...oldChecked, value];
                  else return oldChecked.filter((item) => item !== value);
                });
              }}
            />
          );
        },
      },
      {
        Header: '',
        Footer: '',
        accessor: 'starred',
        disableSortBy: true, // disable sorting for the column
        Cell: ({ row, value }) => {
          const { original } = row;
          if (value)
            return (
              <UisStar
                size={18}
                style={{ cursor: 'pointer', color: '#F9C00C' }}
                onClick={() => starOrUnstar(original.id, 'unstar')}
              />
            );
          else
            return (
              <UilStar
                size={18}
                style={{ cursor: 'pointer', color: '#F9C00C' }}
                onClick={(e) => starOrUnstar(original.id, 'star')}
              />
            );
        }, // formatting date
      },
    ];

    let currentView = list?.views?.find(
      (viewItem) => viewItem.name === selectedView
    );

    currentView?.columns?.forEach((currentViewItem) => {
      cols.push({
        Header: (
          <div
            style={{ cursor: 'pointer', display: 'flex' }}
            onClick={() => {
              if (isSorted === '' || isSorted !== currentViewItem)
                setIsSorted(currentViewItem);
              else if (isSorted === currentViewItem)
                setIsSortedDesc(!isSortedDesc);
            }}
          >
            {currentViewItem.replace('_', ' ')}
            <span>
              {isSorted === currentViewItem ? (
                isSortedDesc ? (
                  <UilAngleDown />
                ) : (
                  <UilAngleUp />
                )
              ) : (
                ''
              )}
            </span>
          </div>
        ),
        Footer: currentViewItem.replace('_', ' '),
        disableSortBy: true,
        accessor: currentViewItem,
        Cell: ({ row, value }) => {
          const { original } = row;
          // return the value as it is unless it's an email
          if (currentViewItem !== 'Email') return value;
          // is email but verification isn't required
          if (!list?.verification_required)
            return (
              <a
                href={`/contact/${original._id}`}
                className="csCustomeLink"
                target="_blank"
              >
                {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
                <span className="csLinkEmpty"></span>
              </a>
            );

          // check accuracy and display things accordingly
          if (!original.accuracy || original.accuracy === '0')
            return (
              <a
                href={`/contact/${original._id}`}
                className="csCustomeLink"
                style={{ color: '#3A3A3A' }}
                target="_blank"
              >
                <UilClock
                  color="#3A3A3A"
                  size={20}
                  data-tooltip-content="This email address is in queue to be verified."
                  data-tooltip-id="email-tooltip"
                />
                {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
                <span className="csLinkEmpty"></span>
              </a>
            );

          if (Number(original.accuracy) >= 99)
            return (
              <a
                href={`/contact/${original._id}`}
                className="csCustomeLink"
                style={{ color: '#3AC569' }}
                target="_blank"
              >
                <UilCheck
                  color="#3AC569"
                  size={20}
                  data-tooltip-content="Email is Valid."
                  data-tooltip-id="email-tooltip"
                />
                {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
                <span className="csLinkEmpty"></span>
              </a>
            );
          else if (
            Number(original.accuracy) >= 60 &&
            Number(original.accuracy) <= 90
          )
            return (
              <a
                href={`/contact/${original._id}`}
                className="csCustomeLink"
                style={{ color: '#F9C00C' }}
                target="_blank"
              >
                <UilCheck
                  color="#F9C00C"
                  size={20}
                  data-tooltip-content="This email address is linked to an accept-all domain. There is no definitive way to determine whether this email is valid or invalid."
                  data-tooltip-id="email-tooltip"
                />
                {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
                <span className="csLinkEmpty"></span>
              </a>
            );
          else if (
            Number(original.accuracy) >= 1 &&
            Number(original.accuracy) <= 50
          )
            return (
              <a
                href={`/contact/${original._id}`}
                className="csCustomeLink"
                style={{ color: '#E53A40' }}
                target="_blank"
              >
                <UilTimes
                  color="#E53A40"
                  size={20}
                  data-tooltip-content="Email seems to be Invalid."
                  data-tooltip-id="email-tooltip"
                />
                {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
                <span className="csLinkEmpty"></span>
              </a>
            );

          return (
            <a
              href={`/contact/${original._id}`}
              className="csCustomeLink"
              style={{ color: '#3A3A3A' }}
              target="_blank"
            >
              <UilClock
                color="#3A3A3A"
                size={20}
                data-tooltip-content="This email address is in queue to be verified."
                data-tooltip-id="email-tooltip"
              />
              {value} <UilExternalLinkAlt size={16} className="csLinkIcon" />{' '}
              <span className="csLinkEmpty"></span>
            </a>
          );
        },
      });
      return;
    });

    cols.push({
      Header: <span className="csCenter">Last Updated</span>,
      Footer: 'Last Updated',
      accessor: 'last_modified',
      disableSortBy: true,
      Cell: ({ row, value }) => {
        if (value) {
          const date = DateTime.fromMillis(value);

          const day = date.day;
          const suffix =
            day % 10 === 1 && day !== 11
              ? 'st'
              : day % 10 === 2 && day !== 12
              ? 'nd'
              : day % 10 === 3 && day !== 13
              ? 'rd'
              : 'th';

          return (
            <div className="csCenter">
              {date.toFormat(`d`) +
                suffix +
                date.toFormat(` MMM yyyy, hh:mm a`)}
            </div>
          );
        }
        return null;
      },
    });

    // actions column
    cols.push({
      Header: 'Actions',
      Footer: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { original } = row;
        let starred = original.starred;
        let label = 'Star';
        if (starred) label = 'Unstar';
        let isArchived = original.archived;
        let archiveLabel = 'Archive';
        if (isArchived) archiveLabel = 'Un-Archive';

        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button
              style={{
                height: 25,
                width: 25,
                outline: '2px solid #F9C00C',
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                setSelectedContact(original);
                setEditContact(true);
              }}
              data-tooltip-content="Quick Edit"
              data-tooltip-show="true"
            >
              <UilEdit color="#F9C00C" size={20} />
            </button>
            <CustomDropdown
              right={true}
              items={[
                {
                  title: 'Activities',
                  icon: (
                    <UilUserExclamation
                      size={18}
                      style={{ marginTop: 2, marginRight: 5 }}
                    />
                  ),
                  action: () => {
                    navigator(`/contact/${original._id}`);
                  },
                },
                {
                  title: label,
                  icon: starred ? (
                    <UilStar
                      size={18}
                      style={{ marginTop: 2, marginRight: 5 }}
                    />
                  ) : (
                    <UisStar
                      size={18}
                      style={{ marginTop: 2, marginRight: 5 }}
                    />
                  ),
                  action: () => {
                    if (starred) starOrUnstar(original.id, 'unstar');
                    else starOrUnstar(original.id, 'star');
                  },
                },
                {
                  title: archiveLabel,
                  icon: isArchived ? (
                    <UilRefresh
                      size={18}
                      style={{ marginTop: 2, marginRight: 5 }}
                    />
                  ) : (
                    <UilArchive
                      size={18}
                      style={{ marginTop: 2, marginRight: 5 }}
                    />
                  ),
                  action: () => {
                    archiveContact(original.id, !isArchived);
                  },
                },
              ]}
              align="left"
            />
          </div>
        );
      },
    });

    setColumns(cols);
  }, [list, selectedView, checkedContacts, isSorted, isSortedDesc]);

  async function updateContact(id, body) {
    try {
      Axios.patch(`/api/list/${list.id}/contacts/${id}`, body);
      setCheckedContacts([]);
    } catch (err) {
      console.log('Error in updating list!');
    }
  }

  async function updateBatchContact(action) {
    if (checkedContacts.length === 0) return;
    try {
      await Axios.put(`/api/list/${list.id}/batch-contacts`, {
        contacts: checkedContacts,
        action,
      });
      let searchParams = new URLSearchParams();
      if (showArchived) searchParams.append('archived', true);
      if (showStarred) searchParams.append('starred', true);
      searchParams.append('limit', perPage);
      searchParams.append('page', 0);
      fetchContacts(searchParams.toString());
    } catch (err) {
      console.log('Error in updating list!');
    }
  }

  const starOrUnstar = (id, value) => {
    if (value === 'star') {
      updateContact(id, {
        starred: true,
      });
    } else if (value === 'unstar') {
      updateContact(id, {
        starred: false,
      });
    }

    setContacts((oldContacts) => {
      let newContacts = oldContacts.map((oldContactsItem) => {
        if (id === oldContactsItem.id)
          oldContactsItem.starred = value === 'star' ? true : false;
        return oldContactsItem;
      });
      if (showStarred) {
        newContacts = newContacts.filter((oldContactsItem) => {
          if (id === oldContactsItem.id) return false;
          return oldContactsItem;
        });
      }
      return newContacts;
    });
  };

  async function fetchList() {
    try {
      let result = await Axios.get(`/api/list/${listID}`);
      setList(result.data.data);
      setListName(result.data.data.name);
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    }
  }

  async function deleteView(viewName) {
    try {
      await Axios.delete(`/api/list/${listID}/views/${viewName}`);
      fetchList();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error in deleting View!',
        showCancelButton: false,
        confirmButtonText: `Ok`,
        confirmButtonColor: '#0066ff',
      });
      console.log('Error in Fetching List! ', err);
    }
  }

  async function fetchContacts(searchQuery) {
    try {
      setLoading(true);

      let url = `/api/list/${listID}/contacts`;
      if (searchQuery?.length > 0) {
        url += '?' + searchQuery;
      }
      let result = await Axios.get(url);
      setContacts(result.data.data);
      setContactsCount(result.data.count);
      setTotalPages(Math.ceil(result.data.count / perPage));
      setCheckedContacts([]);
    } catch (err) {
      console.log('Error in Fetching List! ', err);
    } finally {
      setLoading(false);
    }
  }

  async function updateList(body) {
    try {
      await Axios.patch(`/api/list/${listID}`, body);
      return true;
    } catch (err) {
      console.log('Error in updating list! ', err);
      return false;
    }
  }

  const showFormatedDate = (value) => {
    const date = DateTime.fromISO(value);

    const day = date.day;
    const suffix =
      day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
        ? 'rd'
        : 'th';

    return date.toFormat(`d`) + suffix + date.toFormat(` MMM yyyy`);
  };

  const handleListSync = async () => {
    setLoading(true);
    try {
      await axios.patch('/api/list/update-google-list', list);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Animate type="pop">
      <div className="csRow">
        <Col md={2}>
          <CustomSearch
            value={search}
            onChange={setSearch}
            placeholder={'Search In List'}
          />
          <CustomToggle
            title="Starred"
            icon={<UilStar />}
            onChange={(starred) => {
              if (starred) setShowStarred(true);
              else setShowStarred(false);
            }}
          />
          <CustomSelect
            new={() =>
              setEditingView({
                name: '',
                new: true,
                columns: [],
              })
            }
            icon={<UilEye />}
            onDelete={(itemName) => {
              deleteView(itemName);
              setSelectedView('Complete View');
            }}
            default={{ label: 'All Columns', value: 'Complete View' }}
            edit={(viewName) =>
              setEditingView(list?.views.find((v) => v.name === viewName))
            }
            onChange={(selected) => {
              setSelectedView(selected.value);
              setEditName(false);
            }}
            customClearAction={() => setSelectedView('Complete View')}
            options={list?.views?.map((viewItem) => ({
              label: viewItem.name,
              value: viewItem.name,
            }))}
          />
          <CustomToggle
            title="Archived"
            icon={<UilArchive />}
            onChange={(archived) => {
              if (archived) setShowArchived(true);
              else setShowArchived(false);
            }}
          />
        </Col>
        <Col
          md={10}
          style={{ padding: '0px 0px 0px 2rem', position: 'relative' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: '3px solid #A0A0A0',
                height: 40,
                width: 40,
                borderRadius: 5,
              }}
              className="mr-5 text-white"
              role="button"
              onClick={() => navigator('/outreach/lists')}
            >
              <UilArrowLeft size={25} color="#A0A0A0" />
            </button>

            <div>
              <h3
                style={{
                  fontSize: 20,
                  marginLeft: 11,
                  display: 'flex',
                  alignItems: 'center',
                }}
                className="listNameInput"
              >
                {editName ? (
                  <TextInput
                    style={{ marginLeft: 2, marginBottom: -14 }}
                    value={listName}
                    onChange={(name, value) => {
                      setListName(value);
                    }}
                    placeholder="Enter a List Name"
                  />
                ) : (
                  <p
                    style={{
                      marginLeft: 2,
                      display: 'flex',
                      fontSize: 24,
                      fontWeight: 500,
                      color: '#3a3a3a',
                      justifyItems: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {list?.google_spreadsheet ? 
                      <div id='google-sheet' className='text-sm'>
                        <UilGoogleDriveAlt size={24} className='mr-1' />
                        <Tooltip anchorSelect="#google-sheet">Via Google Sheet</Tooltip>
                      </div>
                      : null}
                    {listName}
                  </p>
                )}

                {selectedView === 'Complete View' && !editName && (
                  <UilPen
                    size={20}
                    id="edit-list-name"
                    style={{
                      marginLeft: 8,
                      cursor: 'pointer',
                      color: '#5D5D5D',
                    }}
                    onClick={() => {
                      if (editName) updateList({ name: listName });
                      setEditName(!editName);
                    }}
                  />
                )}
                {editName && (
                  <UilCheck
                    size={24}
                    style={{
                      marginLeft: 8,
                      cursor: 'pointer',
                      color: '#3AC569',
                    }}
                    onClick={() => {
                      if (editName) updateList({ name: listName });
                      setEditName(!editName);
                    }}
                  />
                )}
                {editName && (
                  <UilTimes
                    size={20}
                    style={{
                      marginLeft: 8,
                      cursor: 'pointer',
                      color: '#E53A40',
                    }}
                    onClick={() => {
                      setEditName(false);
                      setListName(list?.name);
                    }}
                  />
                )}
              </h3>
              <Tooltip anchorSelect="#edit-list-name" place="right">
                Edit List Name
              </Tooltip>
              <span style={{ marginLeft: 15 }}>
                {selectedView !== 'Complete View'
                  ? `(View - ${selectedView}) `
                  : ''}{' '}
                Last Updated: {list && showFormatedDate(list.last_modified)}
              </span>
            </div>

            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              {list?.google_spreadsheet && 
                <div style={{ marginTop: '-5px' }}>
                  <button
                    className={`mt-2 mr-5`}
                    style={{
                      height: 40,
                      width: 40,
                      outline: '3px solid #03A6FF',
                      display: 'grid',
                      placeItems: 'center',
                      borderRadius: 5,
                    }}
                    id="sync-list"
                    onClick={handleListSync}
                  >
                    <UilSync color="#03A6FF" />
                  </button>
                  <Tooltip anchorSelect="#sync-list">
                    Sync leads from Google Sheet
                  </Tooltip>
                </div>
              }
              <div style={{ marginTop: '-5px' }}>
                <button
                  className={`mt-2 mr-5`}
                  style={{
                    height: 40,
                    width: 40,
                    outline: '3px solid #03A6FF',
                    display: 'grid',
                    placeItems: 'center',
                    borderRadius: 5,
                  }}
                  id="list-download"
                  onClick={() => {
                    window.open(
                      `/api/list/${listID}/contacts-download/${selectedView}`,
                      '_blank'
                    );
                  }}
                >
                  <UilDownloadAlt color="#03A6FF" />
                </button>
                <Tooltip anchorSelect="#list-download">
                  Download leads in current view
                </Tooltip>
              </div>
              <div style={{ marginTop: '-5px' }}>
                <CustomButton
                  title="Add Leads"
                  icon={<UilPlusCircle />}
                  className="layout-reverse"
                  variant="primary"
                  onClick={() => {
                    if (list?.google_spreadsheet) {
                      Swal.fire({
                        icon: 'info',
                        reverseButtons: true,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel', 
                        confirmButtonText: 'Open Sheet',
                        confirmButtonColor: '#0066ff',
                        title: 'Import from Google Sheet!',
                        text: `Add leads to your Google Sheet in order to add them to ${whitelabelDetails?.agencyName}.`
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.open(`https://docs.google.com/spreadsheets/d/${list?.google_spreadsheet}`, '_blank');
                        }
                      });
                    }
                    else navigator('/import-list/' + listID);
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          {loading ? (
            <Loader />
          ) : (
            <>
              {contactsCount > 0 ? (
                <Col>
                  <CustomTable
                    hasFooter={false}
                    // filter={search}
                    columns={columns}
                    data={contacts}
                    perPage={perPage}
                    count={contactsCount}
                    onClick={(row) => {}}
                    setPerPage={setPerPage}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                  />
                  <Tooltip
                    place="left"
                    anchorSelect='[data-tooltip-show="true"]'
                  />
                  <Tooltip
                    id="email-tooltip"
                    place="bottom"
                    style={{ maxWidth: 300 }}
                  />
                </Col>
              ) : (
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 'auto',
                      marginTop: 50,
                      marginBottom: 50,
                      textAlign: 'center',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="150"
                      height="150"
                      style={{ margin: 'auto' }}
                      viewBox="0 0 100 100"
                    >
                      <circle cx="52" cy="52" r="44" opacity=".35"></circle>
                      <circle cx="50" cy="50" r="44" fill="#f2f2f2"></circle>
                      <ellipse
                        cx="50.016"
                        cy="50.026"
                        fill="#d9eeff"
                        rx="38.017"
                        ry="38.026"
                      ></ellipse>
                      <path
                        fill="#70bfff"
                        d="M50.189,66.359c-3.776,0-6.66-1.4-8.651-4.203c-1.991-2.802-2.987-6.799-2.987-11.992 c0-5.191,0.996-9.152,2.987-11.881c1.991-2.728,4.875-4.093,8.651-4.093c3.776,0,6.659,1.365,8.651,4.093 c1.991,2.729,2.987,6.69,2.987,11.881c0,5.193-0.995,9.19-2.987,11.992C56.849,64.959,53.965,66.359,50.189,66.359z M50.189,60.784c1.711,0,2.979-0.847,3.806-2.544c0.825-1.696,1.239-4.387,1.239-8.076c0-3.687-0.414-6.342-1.239-7.964 c-0.826-1.622-2.095-2.435-3.806-2.435s-2.98,0.812-3.806,2.435c-0.826,1.622-1.239,4.277-1.239,7.964 c0,3.688,0.413,6.379,1.239,8.076C47.209,59.937,48.478,60.784,50.189,60.784z"
                      ></path>
                      <path
                        fill="#0080FF"
                        d="M50,89c-21.505,0-39-17.495-39-39s17.495-39,39-39s39,17.495,39,39S71.505,89,50,89z M50,14 c-19.851,0-36,16.149-36,36s16.149,36,36,36s36-16.149,36-36S69.851,14,50,14z"
                      ></path>
                    </svg>
                    No leads found
                    <div
                      style={{
                        margin: 'auto',
                        marginTop: '0 !important',
                      }}
                    >
                      <CustomButton
                        variant="primary"
                        onClick={() => navigator('/import-list/' + listID)}
                        title="Add More Contacts"
                        icon={<UilPlusCircle />}
                        className="layout-reverse"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </div>

      {editingView && (
        <CustomDialog>
          <ViewsEditor
            onSave={(update) => {
              let message = 'View is saved.';
              if (update) message = 'View Update Successfull!';
              setSelectedView(update);
              fetchList();
              setEditingView(null);
              Swal.fire({
                icon: 'success',
                title: message,
                showCancelButton: false,
                confirmButtonText: `Check it out`,
                confirmButtonColor: '#0066ff',
              });
            }}
            listID={listID}
            allFields={list?.views[0]?.columns}
            view={editingView}
            onClose={() => setEditingView(null)}
            name={listName}
          />
        </CustomDialog>
      )}

      {editContact && (
        <ContactEdit
          onClose={() => {
            setEditContact(false);
            setSelectedContact(null);
          }}
          onSave={updateContact}
          getList={() => fetchContacts()}
          contact={selectedContact}
          list={list}
        />
      )}
    </Animate>
  );
}

function ContactEdit({ list, contact, getList, onClose: close, onSave: save }) {
  const [contactDetails, setContactDetails] = useState(
    getContactDetails({ list, contact })
  );

  function getContactDetails({ list, contact }) {
    const cols = list.views.find(
      (view) => view.name === 'Complete View'
    ).columns;
    return Object.fromEntries(cols.map((col) => [col, contact[col]]));
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setContactDetails((previousValue) => ({ ...previousValue, [name]: value }));
  }

  const saveList = () => {
    if (!contactDetails.Email || contactDetails.Email === '')
      return Swal.fire('Oops', 'Email is required', 'error');

    // save contact
    save(contact.id, contactDetails);
    setTimeout(() => {
      getList();
      close();
    }, 500);
  };

  return (
    <Animate type="slideleft" timeout={0}>
      <div className="edit-contact-backdrop" onMouseDown={close}>
        <div
          className="edit-contact"
          onMouseDown={(event) => event.stopPropagation()}
        >
          <button className="close-button" onClick={close}>
            <UilTimes size={30} color="currentColor" />
          </button>
          <h2>Quick Edit Lead</h2>
          <div className="inputs">
            {Object.entries(contactDetails).map(([columnName, value]) => {
              return (
                <div key={columnName} className="input">
                  <label>{columnName.replace(/_/g, ' ')}</label>
                  <input
                    name={columnName}
                    value={value}
                    onChange={handleInputChange}
                    placeholder={columnName.replace(/_/g, ' ')}
                  ></input>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: '1.5rem',
            }}
          >
            <CustomButton
              title="Update Lead"
              variant="primary"
              onClick={saveList}
            />
          </div>
        </div>
      </div>
    </Animate>
  );
}
