/***
 *
 *   Dropdown Menu
 *
 *   PROPS
 *   onAction: callback function(action, id){}, actions include ('clicked', 'id') and ('markallread')
 *   items: array containing notification items of type { title: "Notification Title", desc: "Notification Desc", read: false | true, time: '30m' }
 * variant: "<primary | secondary> <red | blue | green | orange>"
 *
 **********/

import React, { useContext, useEffect, useState } from 'react';
import { Animate, AuthContext } from 'components/lib';
import { UilAngleDown, UilBuilding, UilPlus } from '@iconscout/react-unicons';
import './dropdown.scss';

function DropdownItem(props) {
  return <div className="dropdownMenuItem">{props.children}</div>;
}

export function Dropdown(props) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [workspaces, setWorkspaces] = useState([]);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const accounts = authContext?.user?.accounts;
    const filteredWorkspaces = accounts?.filter(account => account.id !== authContext.user.account_id);
    setWorkspaces(filteredWorkspaces);
  }, []);

  return (
    <div
      className={`dropdownBtn`}
      style={{
        margin: 'auto',
        marginRight: 16,
        position: 'relative',
        ...props.style,
      }}
    >
      <button
        style={{ padding: props?.fromUser ? '0 15px' : '', ...props?.fromWorkspace && { maxWidth: '200px', minWidth: '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }}
        onClick={(e) => {
          props.defaultAction(e);
          setOpen(true);
        }}
        className={`dropdown-button ${props.className ? props.className : ''}${props.variant ? props.variant : ''}`}
        disabled={props.disabled}
      >
        <div style={{ paddingLeft: props?.fromUser ? '0' : '', paddingRight: props?.fromUser ? '0' : '' }}>{props.icon}</div>
        {props?.dontShowDropArrow ? null : <>
          <span style={{ ...props.labelStyle, ...props?.fromWorkspace && { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' } }} className={`capitalize`}>{props.label}</span>
          {props.seprator && <div className="seprator"></div>}
          <div
            style={{ paddingLeft: props?.fromUser ? '0' : '', paddingRight: props?.fromUser ? '0' : '', marginLeft: props?.fromWorkspace ? 'auto' : '' }}
            onClick={(event) => {
              if (!props.disabled) {
                event.stopPropagation();
                setOpen(true);
              }
            }}
          >
            <UilAngleDown />
          </div>
        </>}
      </button>

      {open && (
        <Animate type="slidedown">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
            className="menuHideArea"
          ></div>
          <div style={{ ...props?.fromWorkspace && { maxWidth: '200px', minWidth: '200px' } }} className={`dropdownMenu ${props?.leftAligned === true ? 'left-0' : ''}`}>
            <div className={`dropdownMenuBody`}>
              {props?.fromWorkspace ? (
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  {workspaces?.length > 5 && <div className="dropdownSearchBar">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      className='dropdownMenuItem'
                      style={{ cursor: 'auto', maxWidth: '198px', minWidth: '198px' }}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="dropdownSeparator">
                      <hr />
                    </div>
                  </div>}
                  {workspaces
                    .filter(workspace => workspace.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .slice(0, 5)
                    .map((workspace, index) => (
                      <div onClick={() => authContext.switchAccount(workspace.id)} key={index} className="dropdownMenuItem capitalize" style={{ maxWidth: '198px', minWidth: '198px' }}>
                        <UilBuilding style={{ flexShrink: 0 }} /><p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{workspace.name}</p>
                      </div>
                  ))}
                  <div className="dropdownSeparator">
                    <hr />
                  </div>
                  <div className="dropdownMenuItem" onClick={() => props.openDialog(true)}><UilPlus />New Workspace</div>
                </div>
              ) : (
                props?.items?.map((item, index) =>
                  item.type !== 'separator' ? (
                    <div
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen(false);
                        item.action();
                      }}
                    >
                      <DropdownItem>
                        {item?.icon}
                        {item.title}
                      </DropdownItem>
                    </div>
                  ) : (
                    <div key={index} className="dropdownSeparator">
                      <hr />
                    </div>
                  )
                )
              )}
            </div>
            {props?.items?.length === 0 && !props?.fromWorkspace && (
              <div
                style={{ display: 'flex', height: '250px', cursor: 'default' }}
              >
                <span style={{ margin: 'auto' }}>No Notification</span>
              </div>
            )}
          </div>
        </Animate>
      )}
    </div>
  );
}
