import './subsubnav.scss';

export function SubSubNav({ menuitems, onChange, selected }) {
  return (
    <div className="subsubnav">
      {menuitems.map((menuItem) => (
        <div
        key={`${menuItem.icon}-${menuItem.title}`}
          onClick={() => onChange(menuItem)}
          className={`subsubnav-item ${
            menuItem.title === selected ? 'active' : "" 
          }`}
        >
          <div className="icon">
            {menuItem?.icon === 'unicons' ? (
              menuItem?.image
            ) : (
              <></>
            )}
          </div>
          <div className="title">{menuItem?.title}</div>
        </div>
      ))}
    </div>
  );
}
