import React, { useEffect } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import './tables.scss';
import {
  UilAngleLeft,
  UilAngleRight,
  UilAngleDoubleLeft,
  UilAngleDoubleRight,
} from '@iconscout/react-unicons';
import ReactPaginate from 'react-paginate';
import { Col, Row } from 'react-grid-system';

export const CustomTable = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
    },
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    setGlobalFilter(props.filter);
  }, [props.filter]);

  return (
    <div style={{ marginBottom: 150 }}>
      <div style={{ overflowX: 'auto' }}>
        <table {...getTableProps()} className="csTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} style={column.style}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => {
                    if (props.onClick) props.onClick(row);
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {props.hasFooter && (
            <tfoot>
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column) => (
                    <td {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      {props.pagination !== false && props?.count > 10 && (
        <Row className="csTableFooter">
          <Col md={8}>
            <span>
              Page{' '}
              <strong>
                {props.page + 1 || 0} of {props.totalPages || 1}
              </strong>{' '}
            </span>
            <span style={{ marginRight: 5 }}>
              | Total Items : <b> {props?.count}</b> |
            </span>
            {/* <span className="csGoToPage">
              Go to page:{' '}
              <input
                type="number"
                min={1}
                max={props.totalPages}
                value={props.page + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  if (pageNumber >= 0 && pageNumber < props.totalPages)
                    props.setPage(pageNumber);
                }}
                style={{ width: 50 }}
              />
            </span> */}
            Show
            <select
              value={props.perPage}
              onChange={(e) => {
                props.setPerPage(Number(e.target.value));
                props.setPage(0);
              }}
            >
              {[10, 20, 50, 100].map((p) => (
                <option key={p} value={p}>
                  {' '}
                  {p}{' '}
                </option>
              ))}
            </select>
          </Col>
          <Col md={4} style={{ display: 'flex', justifyContent: 'end' }}>
            <div className="csTableFooterButtons">
              <UilAngleDoubleLeft
                className={`csPrevNextNav ${props.page === 0 && 'disabled'}`}
                onClick={() => props.setPage(0)}
              />
              <UilAngleLeft
                className={`csPrevNextNav ${props.page === 0 && 'disabled'}`}
                onClick={() => {
                  if (props.page > 0) props.setPage(props.page - 1);
                }}
              />
              <ReactPaginate
                onPageChange={(e) => props.setPage(e.selected)}
                forcePage={props.page}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={props.totalPages}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="prev"
                nextClassName="next"
                breakLabel="..."
                containerClassName="flex"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
              <UilAngleRight
                className={`csPrevNextNav ${
                  props.page === props.totalPages - 1 && 'disabled'
                }`}
                onClick={() => {
                  if (props.page < props.totalPages - 1)
                    props.setPage(props.page + 1);
                }}
              />
              <UilAngleDoubleRight
                className={`csPrevNextNav ${
                  props.page === props.totalPages - 1 && 'disabled'
                }`}
                onClick={() => props.setPage(props.totalPages - 1)}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
